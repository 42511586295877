<div class="tnc-container">
    <p class="tnc-header">WestJet™ - LOUNGE PORTAL TERMS OF USE – Last updated April 15, 2016</p>
    <p class="tnc-warning">PLEASE READ THE FOLLOWING CAREFULLY.</p>
    <p class="tnc-acceptance">Your use of the WestJet Lounge Portal ("System") constitutes your acceptance of, and is conditional upon your acceptance of and compliance with, the following provisions (the “Agreement”). This Agreement sets out those terms and conditions under which WestJet (“WestJet”, "we", "us", "our") allow you ("you", "your") to use the System and the information contained in the System or collected by you in connection with the System. The terms and conditions of this Agreement may change from time to time and it is your responsibility to check for such updates.</p>
    <div class="tnc-content">
        <p class="tnc-content-header">1. Ownership of Content:</p>
        <p class="tnc-content-desc">All information that may be available through the System or collected by you in connection with the System or provision of the Services, including, but not limited to, client lists, WestJet Rewards member information, personal information, flight information or lounge pass status (collectively, the “Content”) is the property of WestJet. All access or use of the Content by you, or anyone having access to the Content through you, is prohibited, except as expressly permitted by WestJet in this Agreement.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">2. Confidentiality and Use:</p>
        <p class="tnc-content-desc">You may only use the Content for the purpose of administering lounge services (the “Services) on behalf of WestJet. You agree to keep the Content confidential and except as set forth herein, shall not disclose any of the Content in any manner whatsoever. Upon the termination of this Agreement, discontinuation of the Services, or at any time upon WestJet’s request, you will promptly destroy, cause to be destroyed or deliver to WestJet all Content (and all copies thereof) and electronic data, regardless of format or medium, furnished to you by or on behalf of WestJet, and all documents created by you in reliance on documents and information furnished to you by or on behalf of WestJet. You will continue to be bound by your obligations of confidentiality and non-use hereunder notwithstanding the return or destruction of such information.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">3. Privacy:</p>
        <p class="tnc-content-desc">WestJet is governed by the Personal Information Protection and Electronic Documents Act (Canada) (“PIPEDA”) and other applicable privacy legislation. Pursuant to such legislation, WestJet maintains a Privacy Policy (available online at www.westjet.com). WestJet’s Privacy Policy shall govern all personal information collected, used or disclosed by WestJet. As required by PIPEDA and other applicable privacy legislation, personal information shall be deleted or destroyed in accordance with its ordinary life-cycle. To the extent that you obtain any personal information in connection with this Agreement, you shall handle such information in accordance with PIPEDA and shall make no use of such personal information except solely as required and only to the extent required, to perform the Services.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">4. Acceptable Use:</p>
        <p class="tnc-content-desc">You agree not to directly or indirectly (i) copy, record, distribute, transmit, reproduce, upload, publish, modify, or offer for sale any part of the System or Content; (ii) reverse engineer, decompile, disassemble or otherwise manipulate the System or Content; (iii) insert or use any code, virus, or application into or against the System or Content; (iv) engage in data mining, data processing, "screen scraping", or the use or application of any automated script, macro, program, application, software, "bot" or similar technical device to extract, process, copy, distribute, data mine, aggregate, collate, cross-reference or manipulate the Content, or any information or data contained in, displayed on, or accessible or available in any manner from or through the System; (v) defeat or attempt to defeat any digital protection or security measures that may be present in the System or Content; and (vi) take any other action that may be contrary to applicable law or infringe upon a party’s rights under applicable law. WestJet reserves the right to terminate your access to the System for any reason, at its sole discretion.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">5. Account, Password and Security:</p>
        <p class="tnc-content-desc">You are entirely responsible for maintaining the confidentiality of your password and account and for any and all activities that occur under your account. You agree to notify WestJet immediately of any unauthorized use of your account or any other breach of security. WestJet will not be liable for any losses you incur as a result of someone else using your password or account, either with or without your knowledge. However, you could be held liable for losses incurred by WestJet or another party due to someone else using your account or password. You may not use anyone else's account at any time, without the permission of the account holder.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">6. Access to the System:</p>
        <p class="tnc-content-desc">The System is only available via the internet. It is your responsibility to ensure that your device used to access the System is working properly, has up-to-date software and is compatible with the System. WestJet is not responsible for any technical difficulties, including but not limited to compatibility issues that you may encounter.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">7. Termination:</p>
        <p class="tnc-content-desc">WestJet may terminate this Agreement at any time with or without notice to you in its absolute discretion. You may not terminate this Agreement, which shall remain in force.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">8. Withdrawal of Access for Cause:</p>
        <p class="tnc-content-desc">This Agreement grants no rights, only privileges and permission. You agree that WestJet, in its absolute discretion, will be the sole judge of whether you have breached any provision of this Agreement and that any privileges or permission granted to you as an individual under this Agreement may be revoked, suspended or terminated by WestJet at any time without notice.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">9. Waiver:</p>
        <p class="tnc-content-desc">You acknowledge that the internet, computer networks and websites accessed by you are inherently dangerous and subject to computer, system and network failure as well as nasty human activity. While WestJet will take such steps as are reasonable in the circumstances to maintain the security of this System, you agree that you use this System completely at your own risk and that in no event shall WestJet be held liable for any loss or damages, however caused, except as expressly provided in this Agreement, even if caused by the negligence of WestJet.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">10. Disclaimer:</p>
        <p class="tnc-content-desc">WESTJET DOES NOT WARRANT THAT THIS SYSTEM WILL OPERATE ERROR-FREE, BE AVAILABLE, BE COMPATIBLE WITH YOUR DEVICE OR THAT IT AND ITS SERVER OR SERVERS ARE OR WILL BE FREE OF COMPUTER VIRUSES, TROJAN HORSES, OR OTHER HARMFUL CONTENT, DEVICES OR PROGRAMS. THIS SYSTEM AND ITS CONTENT AND MATERIAL, AND ACCESS TO THE SYSTEM, ARE ALL PROVIDED ON AN "AS IS, WHERE IS", AND "AS AND WHEN AVAILABLE" BASIS WITHOUT ANY REPRESENTATIONS, GUARANTEES, CONDITIONS OR WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY THE LAW, WESTJET DISCLAIMS ANY AND ALL CONDITIONS AND WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE CONDITION OR WARRANTY OF MERCHANTABILITY AND THE CONDITION OR WARRANTY OF FITNESS FOR ANY PARTICULAR PURPOSE. WESTJET MAKES NO WARRANTY OR REPRESENTATION OF NON-INFRINGEMENT. WESTJET FURTHER MAKES NO WARRANTIES OR REPRESENTATIONS CONCERNING THE ACCURACY, RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE CONTENT ACCESSED BY USE OF THE SYSTEM. OTHER THAN AS PROVIDED IN THIS AGREEMENT, NO ORAL OR WRITTEN ADVICE OR INFORMATION PROVIDED BY WESTJET SHALL CONSTITUTE A WARRANTY OR REPRESENTATION THAT IS BINDING ON WESTJET.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">11. Further Disclaimer of WestJet:</p>
        <p class="tnc-content-desc">While WestJet will make reasonable commercial efforts to make the System available on a reasonable basis, WestJet accepts no obligation to do so on any pre-agreed basis or at all. WestJet may interrupt access to the System, or terminate access to the System at any time, without notice or liability. WestJet shall be free to modify, change, delete, and generally deal with the Content as it sees fit.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">12. Limitation of Damages:</p>
        <p class="tnc-content-desc">YOU FURTHER AGREE THAT IN NO EVENT WILL WESTJET BE LIABLE FOR INDIRECT, GENERAL, SPECIAL, ECONOMIC, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES RESULTING FROM THE USE OF THIS SYSTEM, WHETHER IN TORT, CONTRACT OR ANY OTHER CAUSE OF ACTION, INCLUDING WITHOUT LIMITATION, FOR LOSS OF DATA, INFORMATION OR PROGRAMS, OR DAMAGE TO COMPUTERS OR NETWORKS, OR FROM BREACH OF PRIVACY AND SIMILAR PERSONAL RIGHTS, OR FROM INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR FROM DEFAMATION, LIBEL OR SLANDER, OR ANY OTHER DAMAGES WHATSOEVER, WHETHER OR NOT WESTJET SHALL HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE TOTAL LIABILITY OF WESTJET SHALL IN ALL CASES BE LIMITED TO A MAXIMUM SUM OF TEN DOLLARS ($10.00) IN CANADIAN CURRENCY.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">13. Injunctive and Similar Relief:</p>
        <p class="tnc-content-desc">You agree that any breach by you of this Agreement will not be fully compensable in damages and you therefore agree that WestJet shall be entitled to immediate equitable, injunctive and other similar relief against you without notice and without the necessity of proving damages or the likelihood of damages.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">14. Indemnity:</p>
        <p class="tnc-content-desc">You agree to defend, indemnify and save harmless WestJet from any costs, expenses, liabilities, obligations, losses and damages, penalties, taxes, proceedings, actions, suits, or claims, including all fees and expenses of counsel on a solicitor and his own client basis paid or incurred by WestJet, asserted by us or by any third party due to or arising out of or otherwise connected to your access, conduct or use of the System or from your breach of any of your warranties and representations or any term or condition of this Agreement.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">15. Non-Assignment by You:</p>
        <p class="tnc-content-desc">You may not assign this Agreement. It is and shall remain personal to you.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">16. Governing Law:</p>
        <p class="tnc-content-desc">This Agreement shall in all respects be governed by the laws of the Province of Alberta, Canada and the laws of Canada as applicable. You agree that any and all disputes will be adjudicated only by the courts of Alberta. You expressly submit to and attorn to the exclusive jurisdiction of such courts, and you undertake and agree to bring no complaint and file no action in any other Court whatsoever. Nothing contained in this section shall prevent WestJet from commencing an action in any court of competent jurisdiction in order to seek damages and/or equitable relief against you for breach of this Agreement.</p>
    </div>
    <div class="tnc-content">
        <p class="tnc-content-header">17. Severance and Enurement:</p>
        <p class="tnc-content-desc">Should any portion or portions of this Agreement be held by a Court of competent jurisdiction to be unenforceable, then such portion or portions shall be severed from this Agreement, and the remaining portions of the Agreement shall be enforced. This Agreement shall enure to the benefit of the heirs, executors, administrators and permitted assigns of you and WestJet, including its subsidiaries and affiliates.</p>
    </div>
</div>