import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TwofaService } from '../../twofa.service';

@Component({
  selector: 'app-qr-scan-section',
  templateUrl: './qr-scan-section.component.html',
  styleUrls: ['./qr-scan-section.component.less']
})
export class QrScanSectionComponent implements OnInit {

  screenNumber:any;
  @Input() qrDetails: any;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<QrScanSectionComponent>,
    private twofaService: TwofaService
  ) { 
    dialogRef.disableClose = true;
    this.twofaService._twofaSetupScreen.subscribe( ele => {
      this.screenNumber = ele;
    });
  }

  ngOnInit(): void {
    console.log('qrDetails',this.qrDetails);
  }

  closePopup() {
    this.screenNumber--;
    this.twofaService.updateScreen(this.screenNumber);
  }

  onSubmit() {
    this.screenNumber++;
    this.twofaService.updateScreen(this.screenNumber);
  }

}
