import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WebService } from './web.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(
    private http: HttpClient, 
    private webService: WebService,
    private authenticationService: AuthenticationService,
    private router: Router
    ) {
    this.checkTokenExpiration();
  }
  private host = environment.host();
  private host3 = environment.host3();
  private gravtyhost = environment.gravtyhost();
  private gisEnd = environment.gisEndpoint;
  private westjetEndPoint = environment.westjetEndPoint;
  myTimeout:any;

  loungeAccess(requestBody: any){
    let requestUrl = this.host + "/bit/";
    return this.webService.postAPI(requestUrl, requestBody, false);
  }
  getAllMember(requestObj:any){
    let requestUrl = this.host + this.gisEnd + "/gis/members/list/" +requestObj+"&membership_stage=Active&page_size=100";
    return this.webService.getAPI(requestUrl, false);
  }

  getGuestEntries(requestParam,tdate,ydate){
    let requestUrl = this.host + "/bits/?date_from="+ydate+"&date_to="+tdate+"&bit_type=LOUNGE_ENTRY"+requestParam;
    return this.webService.getAPIWithResponseHeaders(requestUrl, false);
  }
  getGuestEntriesNextPrev(requestEndpoints){
    let requestUrl = requestEndpoints;
    return this.webService.getAPIWithResponseHeaders(requestUrl, false);
  }

  getSelfService(requestParam,tdate,ydate){
    let requestUrl = this.host + "/bits/?date_from="+ydate+"&date_to="+tdate+"&bit_type=LOUNGE_ENTRY&bit_source=KIOSK"+requestParam;
    return this.webService.getAPIWithResponseHeaders(requestUrl, false);
  }
  getSelfServiceNextPrev(requestEndpoints){
    let requestUrl = requestEndpoints;
    return this.webService.getAPIWithResponseHeaders(requestUrl, false);
  }

  createServiceRequest(requestParam: any,requestBody: any){
    let requestUrl = this.host + "/members/"+requestParam+"/notes/";
    return this.webService.postAPI(requestUrl, requestBody, false);
  }
  getServiceValues(requestParam,tdate,ydate,userLocation,order){
    let requestUrl = this.host + "/notes/?ordering="+order+"&date_from="+ydate+"&date_to="+tdate+"&note_attribute[visit_location]="+userLocation+"&category=Lounge%20Portal%20SR"+requestParam;
    return this.webService.getAPIWithResponseHeaders(requestUrl, false);
  }
  getServiceValuesNextPrev(requestEndpoints){
    let requestUrl = requestEndpoints;
    return this.webService.getAPIWithResponseHeaders(requestUrl, false);
  }
  getAirportData(requestObj){
    let requestUrl = this.host + this.gisEnd + "/gis/airlines/airports/?ordering=code&active=true&code=" +requestObj;
    return this.webService.getAPI(requestUrl, false);
  }

  getmemberPrivileges(memberId){
    let requestUrl = this.host3 + this.gisEnd + "/gis/members/"+memberId+"/privileges/?status=AVAILABLE,PARTIAL_USED&page_size=1000";
    return this.webService.getAPI(requestUrl, false);
  }

  getMemberVoucher(voucherID){
    let requestUrl = this.host3 + "/members/7LQ9GMH/privileges/?ordering=-updated&status=AVAILABLE&search="+voucherID;
    return this.webService.getAPI(requestUrl, false);
  }

  transferPrivilege(requestBody: any){
    let requestUrl = this.host + "/service/bits/";
    return this.webService.postAPI(requestUrl, requestBody, false);
  }

  getV1Priveleges(memberId){
    let requestUrl = this.host + "/members/privileges/"+memberId+"?status=AVAILABLE";
    return this.webService.getAPI(requestUrl, false);
  }

  getServiceBits(memberId){
    let requestUrl = this.host + "/bits/?member_id=" + memberId + "&h_bit_category=SERVICE";
    return this.webService.getAPI(requestUrl, false);
  }

  verifyDlPNRTime(pnr,tdate,ydate){
    let requestUrl = this.host + "/bits/?date_from="+ydate+"&date_to="+tdate+"&h_pnr_number="+pnr;
    return this.webService.getAPI(requestUrl, false);
  }

  getMemberVisitHistory(id,location){
    let requestUrl = this.host + "/bits/?member_id="+id+"&bit_type=LOUNGE_ENTRY&location="+location;
    return this.webService.getAPI(requestUrl, false);
  }

  getQSDashboard(id){
    let requestUrl = this.host + "/qs-dashboard/"+id;
    return this.webService.getAPI(requestUrl, false);
  }

  generateReports(requestBody){
    let requestUrl = this.host + "/wj/bit/export/";
    return this.webService.postAPI(requestUrl, requestBody, false);
  }
  
  getAllReports(locationId,tdate,ydate){
    let requestUrl = this.host + "/wj/bit/export-history?h_location="+locationId+"&date_from="+ydate+"&date_to="+tdate+"&file_type=all";
    return this.webService.getAPIWithResponseHeaders(requestUrl, false);
  }

  getAllReportsNextPrev(requestEndpoints){
    let requestUrl = requestEndpoints;
    return this.webService.getAPIWithResponseHeaders(requestUrl, false);
  }
  sabreAPI(ticket){
    let requestUrl = this.westjetEndPoint + 'bookings/'+ticket;
    return this.webService.getAPI(requestUrl, false);
  }

  lamsApi(requestBody){
    let requestUrl = this.westjetEndPoint + 'systems/skyteam/eligibility/';
    return this.webService.postAPI(requestUrl, requestBody, false);
  }
  lamsOverrideAPI(requestBody){
    let requestUrl = this.westjetEndPoint + 'systems/skyteam/override/';
    return this.webService.postAPI(requestUrl, requestBody, false);
  }

  logOut(){
    let requestUrl = this.host + "/logout/";
    let requestBody = {}
    return this.webService.postAPIWithResponseHeaders(requestUrl, requestBody, false);
  }

  checkTokenExpiration() {
    clearTimeout(this.myTimeout)
    this.myTimeout = setInterval(()=>{
      let currentUser: any = this.authenticationService.currentUserValue;
      let currentTime = new Date().getTime();
      let lastlogged = currentUser.user.last_login;
      let tokenExpire = new Date(lastlogged).getTime();
      console.log('lastlogged',lastlogged);
      console.log('tokenExpire',tokenExpire + 14400000)
      if(tokenExpire + 14400000 < currentTime) {
        this.authenticationService.logout();
        this.router.navigate(['login']);
      }
    }, 40000);
  }

  getTenantDetail() {
    let requestUrl = this.host + '/tenants/basic-info/';
    return this.webService.getAPI(requestUrl, false);
  }

  
}
