import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/services/authentication.service';
import { NonMemberService } from 'src/services/non-member.service';
import { MemberService } from 'src/services/member.service';
import { CommonService } from 'src/services/common.service';
import { BarcodeServiceService } from 'src/services/barcode-service.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone'

@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.less']
})
export class MemberProfileComponent implements OnInit {

  loungeAccessDetailsForm: any = FormGroup;
  loungeAccessRows: any = [];
  familyUnlimitedAccessCount: any = 5;
  guestAccessVouchersCount: any = 6;
  loungeLimitedAccessVoucherCount: any = 6;
  familyAccessCount: any = [];
  guestAccessCount: any = [];
  loungeLimitedVoucher: any = [];
  clickedIndexFamilyAccess: number = 0;
  clickedIndexGuestVoucher: number = 0;
  clickedIndexloungeLimited: number = 0;
  selectedGuestAccessCount: number = 0;
  selectedGuestVoucherCount: number = 0;
  selectedFamilyAccessCount: number = 0;
  previousFamilyAccessCount: number = 0;
  previousGuestAccessCount: number = 0;
  previousGuestVoucherCount: number = 0;
  hideProfileCTA: boolean = false;
  showNumberOfPassengers: boolean = false;
  showVoucherNumber: boolean = false;
  showMemberUnlimitedAccessToggle: boolean = false;
  showMemberLimitedAccessToggle: boolean = false;
  showFamilyUnlimitedAccess: boolean = false;
  showGuestAccessVoucher: boolean = false;
  showLoungeLimitedAccessVoucher: boolean = false;
  numberOfPassengers: any = [];
  numberOfPassengersCount: any = 6;
  nameInitials: any = '';
  tierColor: any = '';
  disableAuthoriseButton: boolean = true;
  travelerTypes: any = [];
  showSuccessPopup = false;
  showErrorPopup = false;
  originAirportText: any;
  designationAirportText: any;
  // barcodeDataGuest = "";
  // myTimeout: any;
  pointRemaining: any;
  showTierToUpdate =  false;
  memberTierCode: any;
  allPrivileges: any;
  guestAccessVoucher:any = [];
  familyUnlimitedAccessVoucher:any = [];
  loungeLimitedAccessVoucher:any = [];
  memberUnlimitedAccessVoucher:any = [];
  showMemberProfileComponent: boolean = false;
  getMemberComplimentaryVoucher:any;
  lineDataForGuests: any = [];
  extraVoucherUsed: any = "";
  visitDate: any = "";
  tierClassCodeID = environment.tierClassCode

  @Input() dataFromMemberDetails: any = {};
  @Input() scanedDataMemberDetails: any = [];
  @Output() dataToMemberDetails: any = new EventEmitter<any>();
  currentUser: any;

  // @HostListener('document:keypress', ['$event'])

  // onKeyPress(event: KeyboardEvent): void {
  //   this.barcodeDataGuest += event.key;
  //   this.resetBarcodeDataAfterDelay();
  // }

  pipe = new DatePipe('en-US');

  constructor(
    private formbuilder: FormBuilder, 
    private authenticationService: AuthenticationService,
    private nonMemberService: NonMemberService,
    private memberService: MemberService,
    private commonService: CommonService,
    private barcodeService: BarcodeServiceService,
    ) { }

  ngOnInit(): void {
    this.commonService.setShowContentLoaderValue(true);
    this.showMemberProfileComponent = false;
    this.currentUser = this.authenticationService.currentUserValue;
    this.loungeAccessDetailsForm = this.formbuilder.group({
      traveler_type: [''],
      flight_type: ['WS'],
      flight_number: [''],
      reservation_code: [''],
      number_of_passengers: [''],
      voucher_number: [''],
      data: this.formbuilder.array([
        this.addFormControls()
      ])
    });
    this.memberService.getmemberPrivileges(this.dataFromMemberDetails.member_id)
      .then(data => {
        this.allPrivileges = data
        for(let i=0; i<this.allPrivileges.length;i++){
          if(this.allPrivileges[i].product.extra_data.lounge_product_type === 'GUEST'){
            this.guestAccessVoucher.push(this.allPrivileges[i])
          }
          else if(this.allPrivileges[i].product.extra_data.lounge_product_type === 'FAMILY'){
            this.familyUnlimitedAccessVoucher.push(this.allPrivileges[i])
          }
          else if(this.allPrivileges[i].product.extra_data.lounge_product_type === 'LIMITED'){
            this.loungeLimitedAccessVoucher.push(this.allPrivileges[i])
          }
          else if(this.allPrivileges[i].product.extra_data.lounge_product_type === 'UNLIMITED'){
            this.memberUnlimitedAccessVoucher.push(this.allPrivileges[i])
          }
        }
        // this.familyUnlimitedAccessCount = this.familyUnlimitedAccessVoucher.length
        this.guestAccessVouchersCount = this.guestAccessVoucher.length
        this.loungeLimitedAccessVoucherCount = this.loungeLimitedAccessVoucher.length
        if(this.familyUnlimitedAccessVoucher.length===0){
          this.familyUnlimitedAccessCount = this.familyUnlimitedAccessVoucher.length
        }
        this.loungeAccessRows = [{ index: 1 }];
        this.familyAccessCount = Array.apply(null, { length: this.familyUnlimitedAccessCount + 1 }).map(Number.call, Number);
        this.guestAccessCount = Array.apply(null, { length: this.guestAccessVouchersCount + 1 }).map(Number.call, Number);
        this.loungeLimitedVoucher = Array.apply(null, { length: this.loungeLimitedAccessVoucherCount + 1 }).map(Number.call, Number);
        this.showMemberProfileComponent = true;
        this.commonService.setShowContentLoaderValue(false);
        this.privilegeAssign()
        this.travellerTypeDropdown();
      },
      (error: any) => {
        this.showMemberProfileComponent = true;
        this.commonService.setShowContentLoaderValue(false);
      })
    this.memberService.getMemberVisitHistory(this.dataFromMemberDetails.member_id,this.currentUser.location_code)
      .then((data: any) => {
        if(data.length>0){
          this.visitDate = moment(data[0].h_bit_date,'YYYY-MM-DDTHH:mm').format('MM/DD/YYYY');
        }
        else{
          this.visitDate = "First Time using Lounge."
        }
      },
      (error: any) => {
        this.visitDate = "First Time using Lounge."
      })

    if(this.dataFromMemberDetails.last_activity_date){
      this.dataFromMemberDetails.last_activity_date = moment(this.dataFromMemberDetails.last_activity_date,'YYYY-MM-DDTHH:mm').format('MM/DD/YYYY');
    }
    this.dataFromMemberDetails.user.last_name != "" ?
      (this.nameInitials = this.dataFromMemberDetails.user.first_name[0] + this.dataFromMemberDetails.user.last_name[0]) :
      (this.nameInitials = this.dataFromMemberDetails.user.first_name[0])

    for(let j=0;j<this.dataFromMemberDetails.tier_data.length;j++){
      if(this.dataFromMemberDetails.tier_data[j].tier_class_code_id === this.tierClassCodeID){
        this.memberTierCode = this.dataFromMemberDetails.tier_data[j].tier_code_id
        if (this.dataFromMemberDetails.tier_data[j].tier_code_id === "Teal") {
          for (let i=0; i<this.dataFromMemberDetails.balances.length;i++){
            if(this.dataFromMemberDetails.balances[i].loyalty_account === "TQS"){
              this.showTierToUpdate = true
              this.pointRemaining = 4000 - this.dataFromMemberDetails.balances[i].balance;
              break;
            }
            else {
              this.showTierToUpdate = false
            }
          }
          if(this.showTierToUpdate === false){
            this.showTierToUpdate = true
            this.pointRemaining = 4000
          }
        }
        else if (this.dataFromMemberDetails.tier_data[j].tier_code_id === "Silver") {
          for (let i=0; i<this.dataFromMemberDetails.balances.length;i++){
            if(this.dataFromMemberDetails.balances[i].loyalty_account === "TQS"){
              this.showTierToUpdate = true
              this.pointRemaining = 6000 - this.dataFromMemberDetails.balances[i].balance;
              break;
            }
            else {
              this.showTierToUpdate = false
            }
          }
          if(this.showTierToUpdate === false){
            this.showTierToUpdate = true
            this.pointRemaining = 6000
          }
        }
        else if (this.dataFromMemberDetails.tier_data[j].tier_code_id === "Gold") {
          for (let i=0; i<this.dataFromMemberDetails.balances.length;i++){
            if(this.dataFromMemberDetails.balances[i].loyalty_account === "TQS"){
              this.showTierToUpdate = true
              this.pointRemaining = 10000 - this.dataFromMemberDetails.balances[i].balance;
              break;
            }
            else {
              this.showTierToUpdate = false
            }
          }
          if(this.showTierToUpdate === false){
            this.showTierToUpdate = true
            this.pointRemaining = 10000
          }
        }

        if (this.dataFromMemberDetails.tier_data[j].tier_code_id === "Gold") {
          this.tierColor = {
            'border': '1.2px solid #9C7E00',
            'background-color': '#E5BA21'
          }
          this.showGuestAccessVoucher = true;
          this.showMemberLimitedAccessToggle = false;
          this.showMemberUnlimitedAccessToggle = true;
          this.showFamilyUnlimitedAccess = true;
          this.showLoungeLimitedAccessVoucher = true;
        }
        else if (this.dataFromMemberDetails.tier_data[j].tier_code_id === "Platinum") {
          this.tierColor = {
            'border': '1.2px solid #3d4b57',
            'background-color': '#53626F'
          }
          this.showGuestAccessVoucher = true;
          this.showMemberLimitedAccessToggle = false;
          this.showMemberUnlimitedAccessToggle = true;
          this.showFamilyUnlimitedAccess = true;
          this.showLoungeLimitedAccessVoucher = true;
        }
        else if (this.dataFromMemberDetails.tier_data[j].tier_code_id === "Silver") {
          this.tierColor = {
            'border': '1.2px solid #99A1A6',
            'background-color': '#BCC6CC'
          }
          this.showGuestAccessVoucher = true;
          this.showMemberLimitedAccessToggle = true;
          this.showMemberUnlimitedAccessToggle = false;
          this.showFamilyUnlimitedAccess = false;
          this.showLoungeLimitedAccessVoucher = true;
        }
        else {
          this.tierColor = {
            'border': '1.2px solid #024E49',
            'background-color': '#26BBB1'
          }
          this.showGuestAccessVoucher = true;
          this.showMemberLimitedAccessToggle = false;
          this.showMemberUnlimitedAccessToggle = false;
          this.showFamilyUnlimitedAccess = false;
          this.showLoungeLimitedAccessVoucher = true;
        }
      }
    }
    this.loungeAccessDetailsForm.get('data')['controls'].length = 0;
    // this.numberOfPassengers = Array.apply(null, { length: this.numberOfPassengersCount + 1 }).map(Number.call, Number);
    this.numberOfPassengers = [1,2,3,4,5,6]
    this.travellerTypeDropdown()
    this.showSuccessPopup = false;
    this.showErrorPopup = false;
  }

  travellerTypeDropdown(){
    if ((this.currentUser.role.code === "LOUNGE_ADMIN" && this.currentUser.location_code === "YYC01") 
    || (this.currentUser.role.code === "LOUNGE_AGENT" && this.currentUser.location_code === "YYC01") 
    || (this.currentUser.role.code === "PROGRAM_MANAGER")) {
      if((this.memberTierCode === "Teal" && this.guestAccessVoucher.length === 0 && this.loungeLimitedAccessVoucher.length === 0)|| (this.memberTierCode === "Silver" && this.guestAccessVoucher.length === 0 && this.loungeLimitedAccessVoucher.length === 0)){
        this.travelerTypes = [ 
          { value: 'business-class', labelName: 'Business Class' },
          { value: 'complimentary-voucher', labelName: 'Complimentary Voucher' }, 
          { value: 'walk-up', labelName: 'Walk-up' }, 
          { value: 'priority-pass', labelName: 'Priority Pass' }, 
          { value: 'dragon-pass', labelName: 'Dragon Pass' }
        ]
      }
      else{
        this.travelerTypes = [
          { value: 'member', labelName: 'Member' }, 
          { value: 'business-class', labelName: 'Business Class' },
          { value: 'complimentary-voucher', labelName: 'Complimentary Voucher' }, 
          { value: 'walk-up', labelName: 'Walk-up' }, 
          { value: 'priority-pass', labelName: 'Priority Pass' }, 
          { value: 'dragon-pass', labelName: 'Dragon Pass' }
        ]
      }
    }
    else {
      if((this.memberTierCode === "Teal" && this.guestAccessVoucher.length === 0 && this.loungeLimitedAccessVoucher.length === 0)|| (this.memberTierCode === "Silver" && this.guestAccessVoucher.length === 0 && this.loungeLimitedAccessVoucher.length === 0)){
        this.travelerTypes = [
          { value: 'business-class', labelName: 'Business Class' }, 
          { value: 'complimentary-voucher', labelName: 'Complimentary voucher' }
        ]
      }
      else{
        this.travelerTypes = [
          { value: 'member', labelName: 'Member' }, 
          { value: 'business-class', labelName: 'Business Class' }, 
          { value: 'complimentary-voucher', labelName: 'Complimentary voucher' }
        ]
      }
    }
  }

  privilegeAssign(){
    if (this.scanedDataMemberDetails.length != 0) {
      this.loungeAccessDetailsForm.controls.flight_number.setValue(this.scanedDataMemberDetails[0].FlightNumber);
      this.loungeAccessDetailsForm.controls.reservation_code.setValue(this.scanedDataMemberDetails[0].OperationCarrierPNRCode);
      this.loungeAccessDetailsForm.controls.flight_type.setValue(this.scanedDataMemberDetails[0].OperationCarrierDesignator);
      if(this.scanedDataMemberDetails[0].CompartmentCode === "J" 
        || this.scanedDataMemberDetails[0].CompartmentCode === "C"
        || this.scanedDataMemberDetails[0].CompartmentCode === "D"
        || this.scanedDataMemberDetails[0].CompartmentCode === "I"
        || this.scanedDataMemberDetails[0].CompartmentCode === "Z"){
          this.loungeAccessDetailsForm.controls.traveler_type.setValue('business-class');
      }
      else {
        if(this.memberTierCode === "Gold" || this.memberTierCode === "Platinum"){
          if(this.memberUnlimitedAccessVoucher.length>0){
            this.loungeAccessDetailsForm.controls.traveler_type.setValue('member');
          }
          // else {
          //   this.loungeAccessDetailsForm.controls.traveler_type.setValue('walk-up');
          // }
        }
        else if(this.memberTierCode === "Teal"){
          if(this.guestAccessVoucher.length>0){
            this.loungeAccessDetailsForm.controls.traveler_type.setValue('member');
            this.travelerTypeChange("member");
          }
          // else {
          //   this.loungeAccessDetailsForm.controls.traveler_type.setValue('walk-up');
          // }
        }
        else if(this.memberTierCode === "Silver"){
          if(this.guestAccessVoucher.length>0 || this.loungeLimitedAccessVoucher.length>0){
            this.loungeAccessDetailsForm.controls.traveler_type.setValue('member');
            this.travelerTypeChange("member");
          }
          // else {
          //   this.loungeAccessDetailsForm.controls.traveler_type.setValue('walk-up');
          // }
        }
      }
      this.keyupEvent('');
      this.memberService.getAirportData(this.scanedDataMemberDetails[0].FromCityAirportCode)
          .then(data => {
            this.originAirportText = data[0].name
          },
            (error: any) => {
            })
        this.memberService.getAirportData(this.scanedDataMemberDetails[0].ToCityAirportCode)
          .then(data => {
            this.designationAirportText = data[0].name
          },
            (error: any) => {
            })
    }
  }

  addFormControls(travelerType = '', flighNumber = '', reservationCode = '', flightType = 'WS'): FormGroup {
    return this.formbuilder.group({
      l_companion_type: [travelerType],
      l_flight_number: [flighNumber],
      l_flight_code: [flightType],
      l_pnr_number: [reservationCode]
    });
  }

  // resetBarcodeDataAfterDelay(): void {
  //   clearTimeout(this.myTimeout)
  //   this.myTimeout = setTimeout(() => {
  //     this.barcodeDataVerify(this.barcodeDataGuest)
  //     this.barcodeDataGuest = '';
  //   }, 2000);
  // }

  // barcodeDataVerify(code) {
  //   if (code.length >= 100) {
  //     console.log(code)
  //     console.log(this.barcodeService.BoardingPassScan(code))
  //   }
  // }
  // "h_flight_num": this.loungeAccessDetailsForm.controls.flight_number.value,
  //     "h_flight_code": this.loungeAccessDetailsForm.controls.flight_type.value,
  //     "h_pnr_number": this.loungeAccessDetailsForm.controls.reservation_code.value,

  familyAccessCountChange(event: any, idx: any) {
    this.clickedIndexFamilyAccess = idx;
    this.selectedFamilyAccessCount = event;
    if (this.selectedFamilyAccessCount > this.previousFamilyAccessCount) {
      for (let i = this.previousFamilyAccessCount; i < this.selectedFamilyAccessCount; i++) {
        (<FormArray>this.loungeAccessDetailsForm.get('data'))
        .push(this.addFormControls('FAMILY_ACCESS', this.loungeAccessDetailsForm.controls.flight_number.value, 
          this.loungeAccessDetailsForm.controls.reservation_code.value, 
          this.loungeAccessDetailsForm.controls.flight_type.value));
      }
    }
    else if (this.selectedFamilyAccessCount < this.previousFamilyAccessCount) {
      for (let i = this.previousFamilyAccessCount; i > this.selectedFamilyAccessCount; i--) {
        let lastIndex = this.loungeAccessDetailsForm.value.data.findLastIndex((items) => items.l_companion_type === 'FAMILY_ACCESS');
        (<FormArray>this.loungeAccessDetailsForm.get('data')).removeAt(lastIndex);
      }
      this.loungeAccessRows = [...this.loungeAccessRows];
    }
    this.previousFamilyAccessCount = this.selectedFamilyAccessCount;
    this.keyupEvent('');
  }

  guestAccessCountChange(event: any, idx: any) {
    this.clickedIndexGuestVoucher = idx;
    this.selectedGuestAccessCount = event;
    if (this.selectedGuestAccessCount > this.previousGuestAccessCount) {
      for (let i = this.previousGuestAccessCount; i < this.selectedGuestAccessCount; i++) {
        (<FormArray>this.loungeAccessDetailsForm.get('data'))
        .push(this.addFormControls('GUEST_ACCESS', this.loungeAccessDetailsForm.controls.flight_number.value, 
          this.loungeAccessDetailsForm.controls.reservation_code.value, 
          this.loungeAccessDetailsForm.controls.flight_type.value));
      }
    }
    else if (this.selectedGuestAccessCount < this.previousGuestAccessCount) {
      for (let i = this.previousGuestAccessCount; i > this.selectedGuestAccessCount; i--) {
        let lastIndex = this.loungeAccessDetailsForm.value.data.findLastIndex((items) => items.l_companion_type === 'GUEST_ACCESS');
        (<FormArray>this.loungeAccessDetailsForm.get('data')).removeAt(lastIndex);
      }
      this.loungeAccessRows = [...this.loungeAccessRows];
    }
    this.previousGuestAccessCount = this.selectedGuestAccessCount;
    this.keyupEvent('');
  }

  loungeLimitedAccessVoucherCountChange(event: any, idx: any) {
    this.clickedIndexloungeLimited = idx;
    this.selectedGuestVoucherCount = event;
    if (this.selectedGuestVoucherCount > this.previousGuestVoucherCount) {
      for (let i = this.previousGuestVoucherCount; i < this.selectedGuestVoucherCount; i++) {
        (<FormArray>this.loungeAccessDetailsForm.get('data'))
        .push(this.addFormControls('GUEST_ACCESS', this.loungeAccessDetailsForm.controls.flight_number.value, 
          this.loungeAccessDetailsForm.controls.reservation_code.value, 
          this.loungeAccessDetailsForm.controls.flight_type.value));
      }
    }
    else if (this.selectedGuestVoucherCount < this.previousGuestVoucherCount) {
      for (let i = this.previousGuestVoucherCount; i > this.selectedGuestVoucherCount; i--) {
        let lastIndex = this.loungeAccessDetailsForm.value.data.findLastIndex((items) => items.l_companion_type === 'GUEST_ACCESS');
        (<FormArray>this.loungeAccessDetailsForm.get('data')).removeAt(lastIndex);
      }
      this.loungeAccessRows = [...this.loungeAccessRows];
    }
    this.previousGuestVoucherCount = this.selectedGuestVoucherCount;
    this.keyupEvent('');
  }

  travelerTypeChange(event: any) {
    if (event === "priority-pass" || event === "dragon-pass") {
      this.showNumberOfPassengers = true;
      this.showVoucherNumber = false;
      this.loungeAccessDetailsForm.controls.number_of_passengers.setValue('');
      this.keyupEvent('');
    }
    else if (event === "complimentary-voucher") {
      this.showNumberOfPassengers = false;
      this.showVoucherNumber = true;
      this.loungeAccessDetailsForm.controls.voucher_number.setValue('');
      this.keyupEvent('');
    }
    else {
      this.showNumberOfPassengers = false;
      this.showVoucherNumber = false;
      this.keyupEvent('');
    }
    if (this.memberTierCode === "Silver" || this.memberTierCode === "Teal"){
      let expiringSoonVoucher:any = [...this.guestAccessVoucher,...this.loungeLimitedAccessVoucher]
      expiringSoonVoucher = expiringSoonVoucher.sort((a,b)=> Date.parse(a.end_date) - Date.parse(b.end_date))
      expiringSoonVoucher = expiringSoonVoucher[0].product.extra_data.lounge_product_type
      if(event === "member"){
        this.extraVoucherUsed = expiringSoonVoucher
        if(expiringSoonVoucher === "GUEST"){
          this.guestAccessCount.splice(0,1);
          this.previousGuestAccessCount = 1;
          this.loungeAccessDetailsForm.get('data')['controls'].length = 0;
          this.guestAccessCountChange(1, 0);
          this.loungeLimitedAccessVoucherCountChange(0,0);
        }
        else if(expiringSoonVoucher === "LIMITED"){
          this.loungeLimitedVoucher.splice(0,1);
          this.previousGuestVoucherCount = 1;
          this.loungeAccessDetailsForm.get('data')['controls'].length = 0;
          this.loungeLimitedAccessVoucherCountChange(1,0);
          this.guestAccessCountChange(0, 0);
        }
        else{
          this.extraVoucherUsed = ""
        }
      }
      else if(event != "member"){
        this.extraVoucherUsed = ""
        if(expiringSoonVoucher === "GUEST"){
          this.guestAccessCount = Array.apply(null, { length: this.guestAccessVouchersCount + 1 }).map(Number.call, Number);
          this.previousGuestAccessCount = 0;
          this.loungeAccessDetailsForm.get('data')['controls'].length = 0;
          this.guestAccessCountChange(0, 0);
          this.loungeLimitedAccessVoucherCountChange(0,0);
        }
        else if(expiringSoonVoucher === "LIMITED"){
          this.loungeLimitedVoucher = Array.apply(null, { length: this.loungeLimitedAccessVoucherCount + 1 }).map(Number.call, Number);
          this.previousGuestVoucherCount = 0;
          this.loungeAccessDetailsForm.get('data')['controls'].length = 0;
          this.loungeLimitedAccessVoucherCountChange(0,0);
          this.guestAccessCountChange(0, 0);
        }
      }
    }
  }

  getAuthoriseAccess() {
    this.lineDataForGuests = []
    for (let i = 0; i < this.loungeAccessDetailsForm.get('data').length; i++) {
      this.lineDataForGuests.push(this.loungeAccessDetailsForm.value.data[i]);
    }
  }

  authoriseAccess(){
    this.commonService.setShowContentLoaderValue(true);

    let traveller_type = this.loungeAccessDetailsForm.controls.traveler_type.value === "member"? "MEMBER":
      (this.loungeAccessDetailsForm.controls.traveler_type.value === "business-class"? "BUSINESS_CLASS":
      (this.loungeAccessDetailsForm.controls.traveler_type.value === "walk-up"? "WALK-UP":
      (this.loungeAccessDetailsForm.controls.traveler_type.value === "priority-pass"? "PRIORITY_PASS":
      (this.loungeAccessDetailsForm.controls.traveler_type.value === "dragon-pass"? "DRAGON_PASS":
      (this.loungeAccessDetailsForm.controls.traveler_type.value === "complimentary-voucher"? "COMPLIMENTARY_VOUCHER":"WALK-UP"
    )))))

    let request: any = {
      "h_sponsor_id_text": "Lounge Portal",
      "h_sponsor_id": environment.sponsorID,
      "h_bit_category": "ACCRUAL",
      "h_bit_source": "MANUAL",
      "h_bit_type": "LOUNGE_ENTRY",
      "h_flight_num": this.loungeAccessDetailsForm.controls.flight_number.value,
      "h_flight_code": this.loungeAccessDetailsForm.controls.flight_type.value,
      "h_pnr_number": this.loungeAccessDetailsForm.controls.reservation_code.value,
      "h_traveller_type": traveller_type,
      "h_program_id": environment.programID,
      "h_bit_date": new Date().toISOString(),
      "h_member_id": this.dataFromMemberDetails.member_id,
      "h_location": this.currentUser.location_code,
      "h_member_name": this.dataFromMemberDetails.user.first_name +" "+ this.dataFromMemberDetails.user?.last_name,
      "member_type": "WS",
      "extra_data":{},
      "h_entry_status":"ACCESS GRANTED",
      "h_privileges":[],
      "voucher_name":[],
      "h_no_of_limitedlounge":0,
      "h_number_of_pax":0,
      "no_of_guests":0,
    }

    if (this.scanedDataMemberDetails.length != 0){
      request.h_bit_source = 'SCANNED'
      request.h_seat_number = this.scanedDataMemberDetails[0].SeatNumber
      request.h_ticket_number = this.scanedDataMemberDetails[0].AirlineNumbericCode + this.scanedDataMemberDetails[0].DocumentForm
      request.h_operating_flight_code = this.scanedDataMemberDetails[0].OperationCarrierDesignator
      request.h_fare_class = this.scanedDataMemberDetails[0].CompartmentCode
      request.flight_date = this.pipe.transform(this.scanedDataMemberDetails[0].DateOfFlightJulianDate ,"yyyy-MM-dd")
      request.h_origin_airport = this.scanedDataMemberDetails[0].FromCityAirportCode
      request.h_origin_airport_code = this.scanedDataMemberDetails[0].FromCityAirportCode
      request.h_origin_airport_text = this.originAirportText
      request.h_destination_airport = this.scanedDataMemberDetails[0].ToCityAirportCode
      request.h_destination_airport_code = this.scanedDataMemberDetails[0].ToCityAirportCode
      request.h_destination_airport_text = this.designationAirportText
    }

    this.getAuthoriseAccess();
    if(this.memberTierCode === "Silver" || this.memberTierCode === "Teal"){
      if(this.extraVoucherUsed==="GUEST"){
        request.h_no_of_limitedlounge = this.clickedIndexloungeLimited
        request.no_of_guests = this.clickedIndexGuestVoucher+1
        if(this.clickedIndexGuestVoucher+1>0){
          let expiringSoonVoucher:any = [...this.guestAccessVoucher].sort((a,b)=>Date.parse(a.end_date) - Date.parse(b.end_date))
          for(let k = 0; k<this.clickedIndexGuestVoucher+1;k++){
            request.h_privileges.push(expiringSoonVoucher[k].privilege_code)
          }
        }
        if(this.clickedIndexloungeLimited>0){
          let expiringSoonVoucher:any = [...this.loungeLimitedAccessVoucher].sort((a,b)=>Date.parse(a.end_date) - Date.parse(b.end_date))
          for(let k = 0; k<this.clickedIndexloungeLimited;k++){
            request.h_privileges.push(expiringSoonVoucher[k].privilege_code)
          }
        }
      }
      else if(this.extraVoucherUsed==="LIMITED"){
        request.h_no_of_limitedlounge = this.clickedIndexloungeLimited+1
        request.no_of_guests = this.clickedIndexGuestVoucher
        if(this.clickedIndexGuestVoucher>0){
          let expiringSoonVoucher:any = [...this.guestAccessVoucher].sort((a,b)=>Date.parse(a.end_date) - Date.parse(b.end_date))
          for(let k = 0; k<this.clickedIndexGuestVoucher;k++){
            request.h_privileges.push(expiringSoonVoucher[k].privilege_code)
          }
        }
        if(this.clickedIndexloungeLimited+1>0){
          let expiringSoonVoucher:any = [...this.loungeLimitedAccessVoucher].sort((a,b)=>Date.parse(a.end_date) - Date.parse(b.end_date))
          for(let k = 0; k<this.clickedIndexloungeLimited+1;k++){
            request.h_privileges.push(expiringSoonVoucher[k].privilege_code)
          }
        }
      }
      else{
        request.h_no_of_limitedlounge = this.clickedIndexloungeLimited
        request.no_of_guests = this.clickedIndexGuestVoucher
        if(this.clickedIndexGuestVoucher>0){
          let expiringSoonVoucher:any = [...this.guestAccessVoucher].sort((a,b)=>Date.parse(a.end_date) - Date.parse(b.end_date))
          for(let k = 0; k<this.clickedIndexGuestVoucher;k++){
            request.h_privileges.push(expiringSoonVoucher[k].privilege_code)
          }
        }
        if(this.clickedIndexloungeLimited>0){
          let expiringSoonVoucher:any = [...this.loungeLimitedAccessVoucher].sort((a,b)=>Date.parse(a.end_date) - Date.parse(b.end_date))
          for(let k = 0; k<this.clickedIndexloungeLimited;k++){
            request.h_privileges.push(expiringSoonVoucher[k].privilege_code)
          }
        }
      }
      if((this.lineDataForGuests.length>0)){
        request.lines = this.lineDataForGuests
      }
    }
    else if((this.memberTierCode === "Gold") || (this.memberTierCode === "Platinum")){
      if(this.loungeAccessDetailsForm.controls.traveler_type.value === 'member'){
        if(this.memberUnlimitedAccessVoucher.length>0){
          request.h_privileges.push(this.memberUnlimitedAccessVoucher[0]?.privilege_code)
          request.h_memberunlimited_used = 1;
        }
      }
      if(this.clickedIndexFamilyAccess>0 && this.familyUnlimitedAccessVoucher.length>0){
        request.h_privileges.push(this.familyUnlimitedAccessVoucher[0]?.privilege_code)
      }
      if(this.clickedIndexGuestVoucher>0){
        let expiringSoonVoucher:any = [...this.guestAccessVoucher].sort((a,b)=>Date.parse(a.end_date) - Date.parse(b.end_date))
        for(let k = 0; k<this.clickedIndexGuestVoucher;k++){
          request.h_privileges.push(expiringSoonVoucher[k]?.privilege_code)
        }
      }
      if(this.clickedIndexloungeLimited>0){
        let expiringSoonVoucher:any = [...this.loungeLimitedAccessVoucher].sort((a,b)=>Date.parse(a.end_date) - Date.parse(b.end_date))
        for(let k = 0; k<this.clickedIndexloungeLimited;k++){
          request.h_privileges.push(expiringSoonVoucher[k].privilege_code)
        }
      }
      if((this.lineDataForGuests.length>0)){
        request.h_no_of_limitedlounge = this.clickedIndexloungeLimited
        request.h_number_of_pax = this.clickedIndexFamilyAccess
        request.no_of_guests = this.clickedIndexGuestVoucher
        request.lines = this.lineDataForGuests
      }
    }
    if(request?.h_number_of_pax === 0){
      delete request.h_number_of_pax;
    }
    else {
      request.voucher_name.push(this.familyUnlimitedAccessVoucher[0].product.name)
    }
    if(request?.no_of_guests === 0){
      delete request.no_of_guests;
    }
    else {
      request.voucher_name.push(this.guestAccessVoucher[0].product.name)
    }
    if(request?.h_no_of_limitedlounge === 0){
      delete request.h_no_of_limitedlounge;
    }
    else {
      request.voucher_name.push(this.loungeLimitedAccessVoucher[0].product.name)
    }
    if(!(this.loungeAccessDetailsForm.controls.traveler_type.value === 'complimentary-voucher')){
      if(request.voucher_name.length===0){
        delete request.voucher_name;
      }
      let apiCallCheck = true;
      if (this.loungeAccessDetailsForm.controls.traveler_type.value === "priority-pass" 
      || this.loungeAccessDetailsForm.controls.traveler_type.value === "dragon-pass") {
        request.h_number_of_passengers = Number(this.loungeAccessDetailsForm.value.number_of_passengers)
      }
      if(request?.h_privileges?.length === 0){
        delete request.h_privileges
      }
      if ((this.scanedDataMemberDetails.length != 0)){
        if(request.h_origin_airport_code === this.currentUser.location_code.substring(0,3)){
          if(traveller_type=='BUSINESS_CLASS'){
            this.memberService.sabreAPI(request.h_ticket_number)
            .then((data: any) => {
              var listOfSegment = data.listOfSegment;
              var listOfSegmentCounterFlag = false;
              for (const values of listOfSegment) {
                if((values.originAirport === request.h_origin_airport_code)&&(values.destAirport === request.h_destination_airport_code)){
                  listOfSegmentCounterFlag = true;
                  if(['J', 'C', 'D', 'I', 'Z'].includes(values.fareBasisClass.substring(0,1))){
                    request.h_coupon_status="CKIN"
                    apiCallCheck = true;
                    this.loungeApiCall(request,apiCallCheck);
                  }
                  else{
                    request.h_entry_status = "ACCESS DENIED"
                    apiCallCheck = false;
                    delete request.h_privileges
                    this.loungeApiCall(request,apiCallCheck);
                  }
                }
              }
              if(listOfSegmentCounterFlag === false){
                request.h_entry_status = "ACCESS DENIED"
                apiCallCheck = false;
                delete request.h_privileges
                this.loungeApiCall(request,apiCallCheck);
              }
            },
              (error: any) => {
                request.h_entry_status = "ACCESS DENIED"
                apiCallCheck = false;
                delete request.h_privileges
                this.loungeApiCall(request,apiCallCheck);
              }
            )
          }
          else {
            request.h_coupon_status="CKIN"
            apiCallCheck = true;
            this.loungeApiCall(request,apiCallCheck);
          }
        }
        else {
          request.h_entry_status = "ACCESS DENIED"
          apiCallCheck = false;
          delete request.h_privileges
          this.loungeApiCall(request,apiCallCheck);
        }
      }
      else {
        this.loungeApiCall(request,apiCallCheck);
      }
    }

    else if(this.loungeAccessDetailsForm.controls.traveler_type.value === 'complimentary-voucher'){
      let voucherNo = "";
      // if((this.loungeAccessDetailsForm.controls.voucher_number.value.length>7) 
      //   && (this.loungeAccessDetailsForm.controls.voucher_number.value[0]==="W") 
      //   && (this.loungeAccessDetailsForm.controls.voucher_number.value[1]==="S")){
      //   voucherNo = this.loungeAccessDetailsForm.controls.voucher_number.value.slice(2).trim()
      // }
      // else {
      //   voucherNo = this.loungeAccessDetailsForm.controls.voucher_number.value
      // }
      if(this.loungeAccessDetailsForm.controls.voucher_number.value.length>7){
        voucherNo = this.loungeAccessDetailsForm.controls.voucher_number.value.slice(3).trim()
      }
      else {
        voucherNo = this.loungeAccessDetailsForm.controls.voucher_number.value
      }
      if(voucherNo.length===7){
        request.h_original_privilege_id = voucherNo
        this.memberService.getMemberVoucher(voucherNo)
        .then(data =>{
          this.getMemberComplimentaryVoucher = data;
          if(this.getMemberComplimentaryVoucher.length===1){
            let reassignPayload: any = {
              "h_bit_category": "SERVICE",
              "h_sponsor_id": environment.sponsorID,
              "h_bit_type": "PRIVILEGE_TRANSFER",
              "h_member_id": this.getMemberComplimentaryVoucher[0].member_id,
              "h_program_id": environment.programID,
              "transfer_privilege_code": this.getMemberComplimentaryVoucher[0].privilege_code,
              "transferee_member_id": request.h_member_id,
              "h_bit_date": new Date().toISOString(),
              "h_location": this.currentUser.location_code
            }
            this.memberService.transferPrivilege(reassignPayload)
            .then(data =>{
              let main_bit_id:any = data
              // setTimeout(() => {
                this.memberService.getV1Priveleges(request.h_member_id)
                .then(data => {
                  let v1Priveleges: any = data
                  v1Priveleges = v1Priveleges.data
                  v1Priveleges.sort((b,a) => {
                    return new Date(a.lastUpdatedOn).getTime() - new Date(b.lastUpdatedOn).getTime();
                  })
                  request.h_privileges.push(v1Priveleges[0].privilegeCode)
                  request.voucher_name.push(v1Priveleges[0].productName)
                  this.memberService.loungeAccess(request)
                  .then(data => {
                    this.commonService.setShowContentLoaderValue(false);
                    this.hideProfile('showSuccess')
                  },
                    (error: any) => {
                      this.commonService.setShowContentLoaderValue(false);
                      this.hideProfile('showVoucherError')
                    }
                  )
                },
                  (error: any) => {
                    this.commonService.setShowContentLoaderValue(false);
                    this.hideProfile('showVoucherError')
                  }
                )
              // }, 2000);
            },
              (error:any)=>{
                this.commonService.setShowContentLoaderValue(false);
                this.hideProfile('showVoucherError')
              }
            )
          }
          else{
            this.commonService.setShowContentLoaderValue(false);
            this.hideProfile('showVoucherError')
          }
        },
          (error: any)=>{
            this.commonService.setShowContentLoaderValue(false);
            this.hideProfile('showVoucherError')
          }
        )
      }
      else{
        this.commonService.setShowContentLoaderValue(false);
        this.hideProfile('showInvalidVoucherError')
      }
      
    }
    else{
      this.commonService.setShowContentLoaderValue(false);
    }
  }

  loungeApiCall(req,callCheck){
    this.memberService.loungeAccess(req)
      .then(data => {
        if(callCheck){
          this.commonService.setShowContentLoaderValue(false);
          this.hideProfile('showSuccess')
        }
        else{
          this.commonService.setShowContentLoaderValue(false);
          this.hideProfile('showError')
        }
      },
        (error: any) => {
          this.commonService.setShowContentLoaderValue(false);
          this.hideProfile('showError')
        }
      )
  }

  hideProfile(data: any) {
    if (data === 'close') {
      this.hideProfileCTA = true;
      this.dataToMemberDetails.emit(this.hideProfileCTA)
    }
    else if (data === 'showSuccess'){
      this.dataToMemberDetails.emit(data)
    }
    else if (data === "showError"){
      this.dataToMemberDetails.emit(data)
    }
    else if (data === "showVoucherError"){
      this.dataToMemberDetails.emit(data)
    }
    else if (data === "showInvalidVoucherError"){
      this.dataToMemberDetails.emit(data)
    }
  }

  numberOfPassengersValue() {
    this.keyupEvent('');
  }

  keyupEvent(event: any) {
    let travelerType = this.loungeAccessDetailsForm.value.traveler_type;
    let flightType = this.loungeAccessDetailsForm.value.flight_type;
    let flightNumber = this.loungeAccessDetailsForm.value.flight_number;
    let reservationCode = this.loungeAccessDetailsForm.value.reservation_code;
    if (travelerType == "" || flightType == "" || flightNumber == "" || reservationCode == "") {
      this.disableAuthoriseButton = true;
    }
    else {
      if (travelerType === "complimentary-voucher") {
        if (this.loungeAccessDetailsForm.value.voucher_number == "") {
          this.disableAuthoriseButton = true;
        }
        else {
          this.disableAuthoriseButton = false;
        }
      }
      else if (travelerType === "priority-pass" || travelerType === "dragon-pass") {
        if (this.loungeAccessDetailsForm.value.number_of_passengers == "") {
          this.disableAuthoriseButton = true;
        }
        else {
          this.disableAuthoriseButton = false;
        }
      }
      else {
        this.disableAuthoriseButton = false;
      }
    }
    for (let i = 0; i < this.loungeAccessDetailsForm.get('data')['controls'].length; i++) {
      if (travelerType == "" || flightType == "" || flightNumber == "" || reservationCode == ""
      || this.loungeAccessDetailsForm.value.data[i].l_flight_number == "" 
      || this.loungeAccessDetailsForm.value.data[i].l_pnr_number == "") {
        this.disableAuthoriseButton = true;
      }
      else {
        if (travelerType === "complimentary-voucher" || travelerType === "priority-pass" || travelerType === "dragon-pass") {
          if (travelerType === "complimentary-voucher") {
            if (this.loungeAccessDetailsForm.value.voucher_number == "") {
              this.disableAuthoriseButton = true;
            }
            else {
              this.disableAuthoriseButton = false;
            }
          }
          else if (travelerType === "priority-pass" || travelerType === "dragon-pass") {
            if (this.loungeAccessDetailsForm.value.number_of_passengers == "") {
              this.disableAuthoriseButton = true;
            }
            else {
              this.disableAuthoriseButton = false;
            }
          }
        }
        else {
          this.disableAuthoriseButton = false;
        }
      }
    }
  }
}
