<div class="twofa-qr-container">
    <div class="right-container">
        <div class="qr-setup-header">
            Scan the QR code using an authentication application on your phone (Google Authenticator, iCloud Keychain, Dashlane, Authy) or enter the following code:
        </div>

        <div class="copy-code-section">
            <span>{{qrDetails?.secret}}</span>
            <img src="../../../../assets/image/copy-icon.png" height="19px" width="19px">
        </div>

        <div class="qr-image">
            <qrcode [qrdata]="qrDetails?.qr_code_url" [width]="280"></qrcode>
        </div>

        <div class="button-container">
            <button mat-raised-button class="cancel-button" (click)="closePopup()">Cancel</button>
            <button mat-raised-button class="continue-button" (click)="onSubmit()">Continue</button>
        </div>
    </div>
</div>
