<div class="twofa-otp-container">
    <div class="right-container">
        <div class="setup-header">
            Enter the 6 figure confirmation code from your authenticator app:
        </div>

        <form [formGroup]="form">
            <div class="otp-inputs" [ngClass]="{'otp-inputs-error': showError}">
                <div *ngFor="let input of formInput;  index as i">
                    <input size="10" #formRow type="text" formControlName="{{input}}" class="form-control" maxlength="1" (keyup)="keyUpEvent($event, i)">
                </div>
            </div>

            <div class="error-msg" *ngIf="showError">
                {{errorMsg}}
            </div>
    
            <div class="misc-divider"></div>
            
            <div class="logout-reason-container" *ngIf="isShowLogoutReason">
                <div class="logout-details">
                    For secruity reasons, you will be logged out from all devices and browsers that have been used to log in to your account recently, and you will have to log in after completing the setup.
                </div>
            </div>

            <div class="button-container">
                <button mat-raised-button class="cancel-button" (click)="closePopup()">Cancel</button>
                <button mat-raised-button class="continue-button" [ngClass]="{'disabled': !form.valid}" [disabled]="!form.valid" (click)="onSubmit()">Continue</button>
            </div>
        </form>
    </div>
</div>
