<div class="self-service-tab" id="self-service-tab">
    <div class="self-service-container" *ngIf="selfServiceScreenLoading">
        <div class="self-service-serach-fields">
            <form [formGroup]="selfServiceForm" class="self-service-form">
                <mat-form-field appearance="fill" class="self-service-form-dropdown">
                    <mat-select formControlName="search_criteria" (selectionChange)="searchCriteriaChange($event)">
                        <mat-option value="pnr"> PNR </mat-option>
                        <mat-option value="traveler_name"> Traveler Name </mat-option>
                    </mat-select>
                    <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                </mat-form-field>

                <mat-form-field appearance="fill" class="self-service-form-input">
                    <mat-label>{{lblName}}</mat-label>
                    <input matInput formControlName="search_text" placeholder="" (keyup)="keyupEvent($event)" />
                </mat-form-field>
                <div class="action-buttons">
                    <button mat-raised-button class="search-button" (click)="selfServiceData('search')" [disabled]="disableSearchButton"
                        [ngStyle]="{'cursor': (disableSearchButton? 'not-allowed':'pointer')}">SEARCH</button>
                </div>
                <div class="refresh-button">
                    <button class="refresh-icon-button" (click)="selfServiceData('rotate')"><img class="refresh-image" [ngClass]="{'refresh-image-rotate': refreshRotation}" src="../../assets/image/refresh.png" alt=""></button>
                </div>
            </form>
        </div>
        <div class="data-label">
            <div class="label-heading reward-id">Rewards ID</div>
            <div class="label-heading traveler-name">Traveler Name</div>
            <div class="label-heading pnr-number">PNR</div>
            <div class="label-heading entry-date">Entry Date</div>
            <div class="label-heading access-status">Access Status</div>
            <div class="label-heading reason">Reason</div>
            <div class="label-heading override">Override</div>
        </div>
        <div class="data-screen" *ngIf="dataAvailable">
            <div class="data-rows" *ngFor="let servicedData of selfServiceForData; let odd = odd" [ngClass]="odd ? 'odd_col' : 'even_col'">
                <div class="data-sub-container">
                    <div class="data-column reward-id" title="{{servicedData.member_id}}">{{ servicedData.member_id }}</div>
                    <div class="data-column traveler-name" title="{{servicedData.header.h_member_name}}">{{ servicedData.header.h_member_name }}</div>
                    <div class="data-column pnr-number" title="{{servicedData.header.h_pnr_number}}">{{ servicedData.header.h_pnr_number }}</div>
                    <div class="data-column entry-date" title="{{servicedData.h_bit_date | date:'MM/dd/YYYY' }} {{ servicedData.h_bit_date | date: 'hh:mm a'}}">{{ servicedData.h_bit_date | date:'MM/dd/YYYY'  }} {{ servicedData.h_bit_date | date: 'hh:mm a'}}</div>
                    <div class="data-column access-status" title="{{servicedData.header.h_entry_status}}" [ngStyle]="{'color': servicedData.header.h_entry_status === 'Access Denied' ? '#B00121' : '#016866', 'font-family':'NotoSansSemiBold'}">{{ servicedData.header.h_entry_status }}</div>
                    <div class="data-column reason" title="{{servicedData.header.h_denial_reason}}">{{ servicedData.header.h_denial_reason }}</div>
                    <div class="data-column override">
                        <button class="lock-button" (click)="popupState('open',servicedData)" *ngIf="servicedData?.header?.h_overrideable_flag==='Yes' && servicedData?.is_cancelled===false">
                            <img class="lock-image" src="../../assets/image/lock.png"alt="">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination-button" *ngIf="paginationActive">
            <button class="previous-button" [ngStyle]="{'opacity': (prevUrl === 'None' ? 0 : 1)}"
                [disabled]="prevUrl === 'None'" (click)="nextPrev('prev')">< Previous</button>
            <button class="next-button" [ngStyle]="{'opacity': (nextUrl === 'None' ? 0 : 1)}"
                [disabled]="nextUrl === 'None'" (click)="nextPrev('next')"> Next ></button>
        </div>
        <div class="error-message-more-records" *ngIf="!dataAvailable">
            No Kiosk Entry Found.
        </div>
    </div>
    <app-footer></app-footer>
</div>

<div class="user-access-tab" *ngIf="showUserAccessTab">
    <div class="user-access-container">
        <div class="container-heading">Use override to grant user access</div>
        <div class="container-body">
            Are you sure you want to use override to grant this user
            lounge access? This action cannot be reversed.
        </div>
        <div class="action-buttos">
            <button mat-raised-button class="submit-button" (click)="grantAccess()">GRANT ACCESS</button>
            <button mat-raised-button class="cancel-button" (click)="popupState('close','')">CANCEL</button>
        </div>
    </div>
</div>