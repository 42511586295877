import { Component, EventEmitter, OnInit, Output, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/services/authentication.service';
import { TwofaService } from '../../twofa.service';

@Component({
  selector: 'app-email-otp-section',
  templateUrl: './email-otp-section.component.html',
  styleUrls: ['./email-otp-section.component.less']
})
export class EmailOtpSectionComponent implements OnInit {

  form: FormGroup;
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  @ViewChildren('formRow') rows: any;
  @Output() qrItemEvent: EventEmitter<any> = new EventEmitter<any>();
  isShowLogoutReason = true;
  screenNumber: any;
  currentUser: any;
  errorMsg: any;
  showError = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EmailOtpSectionComponent>,
    private dialog: MatDialog,
    private twofaService: TwofaService,
    private authenticationService:AuthenticationService
  ) {
    this.form = this.toFormGroup(this.formInput);
    dialogRef.disableClose = true;
    this.twofaService._twofaSetupScreen.subscribe( ele=> {
      this.screenNumber = ele;
    });
  }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  toFormGroup(elements: any) {
    const group: any = {};

    elements.forEach((key: any) => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event: any, index: any) {
    this.showError = false;
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1 ;
    } else {
      pos = index + 1 ;
    }
    if (pos > -1 && pos < this.formInput.length ) {
      this.rows._results[pos].nativeElement.focus();
    }

  }

  resendEmailOTP() {
    this.showError = false;
    this.form.reset();
    this.twofaService.sendVerificationEmail(this.currentUser.id).then((data: any) => {
      localStorage.setItem('for_2fa_only',data["Two-FA-Token"]);
    }).catch((error: any) => { 
      console.log(error);
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.showError = false;
      const otpValue = Object.values(this.form.value).join('');
      var req = {
        otp: otpValue
      }
      this.twofaService.verifyEmailOTP(req,this.currentUser.id).then((data: any) => {
        localStorage.setItem('for_2fa_only',data["Two-FA-Token"]);
        this.generateQR();
      }).catch((error: any) => {
        if(error) {
          this.showError = true;
          this.errorMsg = error.error.message;
        }
      });
    } else {

    }
  }

  generateQR() {
    this.twofaService.generateQR(this.currentUser.id).then((data: any) => {
      this.emitData(data);
      this.screenNumber++;
      this.twofaService.updateScreen(this.screenNumber);
    }).catch((error: any) => { 
      console.log(error);
    });
  }

  closePopup() {
    this.showError = false;
    this.form.reset();
    this.dialogRef.close();
  }
  emitData(data: any){
    this.qrItemEvent.emit(data);
  }
}
