import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, NavigationGuard } from 'src/guards';
import { FaqComponent } from './faq/faq.component';
import { GuestEntriesComponent } from './guest-entries/guest-entries.component';
import { LoginComponent } from './login/login.component';
import { ReportsComponent } from './reports/reports.component';
import { SelfServiceComponent } from './self-service/self-service.component';
import { ServiceRequestComponent } from './service-request/service-request.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { MemberDetailsComponent } from './member-details/member-details.component';
import { VersionComponent } from './version/version.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent , canDeactivate: [NavigationGuard], data:{animation:'fadeInAnimation'}},
  { path: 'faq', component: FaqComponent , data:{animation:'fadeInAnimation'}},
  { path: 'tnc', component: TermsConditionsComponent , data:{animation:'fadeInAnimation'}},
  { path: 'version', component: VersionComponent , canDeactivate: [NavigationGuard], data:{animation:'fadeInAnimation'}},
  { path: 'lounge-access', component: MemberDetailsComponent , canActivate:[AuthGuard], canDeactivate: [NavigationGuard], data:{animation:'fadeInAnimation'}},
  { path: 'service-requests', component: ServiceRequestComponent , canActivate:[AuthGuard], canDeactivate: [NavigationGuard], data:{animation:'fadeInAnimation'}},
  { path: 'self-serve', component: SelfServiceComponent , canActivate:[AuthGuard], canDeactivate: [NavigationGuard], data:{animation:'fadeInAnimation'}},
  { path: 'guest-entries', component: GuestEntriesComponent , canActivate:[AuthGuard], canDeactivate: [NavigationGuard], data:{animation:'fadeInAnimation'}},
  { path: 'reports', component: ReportsComponent , canActivate:[AuthGuard], canDeactivate: [NavigationGuard], data:{animation:'fadeInAnimation'}},
  { path: '**', redirectTo: '/lounge-access' , canActivate:[AuthGuard], canDeactivate: [NavigationGuard], data:{animation:'fadeInAnimation'}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
