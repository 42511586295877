<div class="splash-container">
    <div class="main-container">
        <div class="twofa-logo">
            <img src="../../../assets/image/2fa-secondary-icon.png" alt="" height="159px" width="133px">
        </div>

        <div class="splash-heading">
            Two-Factor Authentication
        </div>

        <div class="splash-desc">
            Two-factor authentication (2FA) adds an extra layer of security to your account by requiring two different forms of identification before you can log in.
            <br><br>
            With 2FA enabled, your account is inaccessible without entering a security code generated by the 2FA app. This makes it much harder for unauthorized users to gain entry, protecting sensitive information and personal data.
            <br><br>
            Two-factor authentification will become mandatory soon so please complete the setup and activate 2FA in order to ensure uninterrupted service.
        </div>

        <!-- <div class="misc-divider"></div> -->

        <div class="button-container">
            <button mat-raised-button *ngIf="twofaOptFlag" class="cancel-button" (click)="closePopup()">Setup Later</button>
            <button mat-raised-button class="continue-button" (click)="nextScreen()">Continue</button>
        </div>
    </div>
</div>
