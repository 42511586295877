import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BarcodeServiceService {

  constructor() { }

  orangeObject:any = {
    PassengerName:"",
    OperationCarrierPNRCode:"",
    FromCityAirportCode:"",
    ToCityAirportCode:"",
    OperationCarrierDesignator:"",
    FlightNumber:"",
    DateOfFlightJulianDate:"",
    CompartmentCode:"",
    SeatNumber:"",
    firstName:"",
    lastName:""
  }

  blueObject:any = {
    FieldSizeFollowingStructureUnique:"",
    AirlineDesignatorofBoardingPassIssuer:""
  }

  greenObject:any = {
    FieldSizeFollowingStructureRepeated:"",
    AirlineNumbericCode:"",
    DocumentForm:"",
    FrequentFlyerAirlineDesignator:"",
    FrequentFlyerNumber:""
  }

  yellowObject:any = {
    WScode:"",
    WSAirlineLounge:""
  }

  memberScannedData:any

  BoardingPassScan (code) { 
    var input = code
    function julianDayToDate(julianDay) {
      const dayOfYear = parseInt(julianDay);
      const currentDate = new Date();
      currentDate.setMonth(0); // January is month 0
      currentDate.setDate(1);
      currentDate.setDate(currentDate.getDate() + dayOfYear - 1);
      return currentDate;
    }
    //orange region
    var orangeString = input.substring(2, input.indexOf('>'))
    this.orangeObject.PassengerName = orangeString.substring(0, 20).replace("/", " ").trim().split(' ').reverse().join(' ');
    orangeString = orangeString.substring(21)
    this.orangeObject.OperationCarrierPNRCode = orangeString.substring(0,7).trim()
    orangeString = orangeString.substring(7)
    this.orangeObject.FromCityAirportCode = orangeString.substring(0,3)
    orangeString = orangeString.substring(3)
    this.orangeObject.ToCityAirportCode = orangeString.substring(0,3)
    orangeString = orangeString.substring(3)
    this.orangeObject.OperationCarrierDesignator = orangeString.substring(0,3).trim()
    orangeString = orangeString.substring(3)
    this.orangeObject.FlightNumber = orangeString.substring(0,5).trim()
    orangeString = orangeString.substring(5)
    this.orangeObject.DateOfFlightJulianDate = orangeString.substring(0,3).trim()
    orangeString = orangeString.substring(3)
    this.orangeObject.CompartmentCode = orangeString.substring(0,1).trim()
    orangeString = orangeString.substring(1)
    this.orangeObject.SeatNumber = orangeString.substring(0,4).trim()
    orangeString = orangeString.substring(4)

    //blue region
    this.blueObject.FieldSizeFollowingStructureUnique = parseInt(input.substring(62,64),16)
    var blueString = input.substring(64,64+this.blueObject.FieldSizeFollowingStructureUnique)
    this.blueObject.AirlineDesignatorofBoardingPassIssuer = blueString.substring(8,10).trim()

    //green region
    var greenString = input.split(blueString)[1]
    this.greenObject.FieldSizeFollowingStructureRepeated = parseInt(greenString.substring(0,2),16)
    greenString = greenString.substring(2,2+this.greenObject.FieldSizeFollowingStructureRepeated)
    this.greenObject.AirlineNumbericCode = greenString.substring(0,3)
    this.greenObject.DocumentForm = greenString.substring(3,13)
    this.greenObject.FrequentFlyerAirlineDesignator = greenString.substring(18,21).trim()
    this.greenObject.FrequentFlyerNumber = greenString.substring(21,37).trim()

    //yellow region
    var yellowString = input.split(greenString)[1]
    this.yellowObject.WScode = yellowString.substring(0,2).trim()
    this.yellowObject.WSAirlineLounge = yellowString.substring(2,3).trimEnd()

    this.memberScannedData = { ...this.orangeObject, ...this.blueObject, ...this.greenObject, ...this.yellowObject }
    this.memberScannedData.DateOfFlightJulianDate = julianDayToDate(this.memberScannedData.DateOfFlightJulianDate)
    if(this.memberScannedData.PassengerName!=""){
      let fullName = this.memberScannedData.PassengerName.split(' ')
      if(fullName.length===3){
        this.memberScannedData.firstName = fullName[1]
        this.memberScannedData.lastName = fullName[2]
      }
      else{
        this.memberScannedData.firstName = fullName[0]
        this.memberScannedData.lastName = fullName[1]
      }
    }
    
    return this.memberScannedData

  }
}
