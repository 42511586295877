import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from 'src/services/common.service';


@Injectable({
  providedIn: 'root'
})
export class NavigationGuard implements CanDeactivate<any> {
  constructor(private commonService: CommonService) {}
  canDeactivate(component: any) {
    // will prevent user from going back
    if (this.commonService.getBackClicked()) {
      this.commonService.setBackClicked(false);
      // push current state again to prevent further attempts.
      history.pushState(null, '', location.href);
      return false;
    }
    return true;
  }
}