<div class="service-request-tab" id="service-request-tab">
  <div class="service-request-container" *ngIf="serviceRequestScreenLoading">
    <div class="service-request-search-box">
      <form class="service-request-search-form" [formGroup]="serviceRequestForm">
          <mat-form-field appearance="fill" class="service-request-form-dropdown">
              <mat-select formControlName="search_criteria" (selectionChange)="searchCriteriaChange($event)">
                  <mat-option value="searchId"> Rewards ID </mat-option>
                  <mat-option value="serialNum"> SR# </mat-option>
                  <mat-option value="creationDate"> Creation Date </mat-option>
              </mat-select>
              <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
          </mat-form-field>
          <mat-form-field appearance="fill" class="search-input" [hideRequiredMarker]="true" *ngIf="!showCreationDateInput">
            <mat-label>{{lblName}}</mat-label>
            <input formControlName="search_text" matInput value=""/>
          </mat-form-field>
          <mat-form-field appearance="fill" class="search-input" *ngIf="showCreationDateInput">
              <mat-label>{{lblName}}</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="creation_date" [min]="yesterdaysDate" [max]="MaxDate" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker" (click)="popupState('creation')">
              </mat-datepicker-toggle>
              <mat-datepicker #picker>
                  <mat-datepicker-actions>
                      <button mat-raised-button matDatepickerApply class="select-button"
                          (click)="popupState('close')">SELECT</button>
                      <button mat-raised-button (click)="onPickerClear(picker)"
                          class="cancel-button">CANCEL</button>
                  </mat-datepicker-actions>
              </mat-datepicker>
          </mat-form-field>
          <div class="action-buttons" appearance="fill">
            <button mat-raised-button class="request-button" (click)="getServiceRequestData('search')"
              [ngStyle]="{'cursor': 'pointer'}">SEARCH</button>
            <div class="sorting-button-container" title="Sort" [ngStyle]="{'cursor': 'pointer'}">
              <button class="sorting-button" [ngStyle]="{'cursor': 'pointer'}" (click)="dropdownShow()">
                <img class="sorting-image" src="../../assets/image/sorting.png" alt="" [ngClass]="{'sorting-image-rotate': isDropdownVisible}">
              </button>
              <div id="sortingDropdown" class="sorting-dropdown" [ngClass]="{'show': isDropdownVisible}">
                <a (click)="selectedDropdown('-created_ts',0); getServiceRequestData('search');" [ngClass]="{'selected': selectedDropdownIndex === 0}">Created Date ↓</a>
                <a (click)="selectedDropdown('created_ts',1); getServiceRequestData('search');" [ngClass]="{'selected': selectedDropdownIndex === 1}">Created Date ↑</a>
                <a (click)="selectedDropdown('-updated_ts',2); getServiceRequestData('search');" [ngClass]="{'selected': selectedDropdownIndex === 2}">Closed Date ↓</a>
                <a (click)="selectedDropdown('updated_ts',3); getServiceRequestData('search');" [ngClass]="{'selected': selectedDropdownIndex === 3}">Closed Date ↑</a>
              </div>
            </div>
            <button mat-raised-button class="request-button" 
              (click)="popupState('open')"> CREATE REQUEST </button>
          </div>
      </form>
    </div>
    <div class="data-label">
      <div class="label-heading request-number">SR #</div>
      <div class="label-heading rewards-id">Rewards ID</div>
      <div class="label-heading creation-date">Creation date</div>
      <div class="label-heading close-date">Close date</div>
      <div class="label-heading description">Description</div>
      <div class="label-heading status">Status</div>
    </div>
    <div class="data-screen" *ngIf="dataAvailable">
      <div class="data-rows" *ngFor="let requestList of serviceRequestList; let odd = odd" [ngClass]="odd ? 'odd_col' : 'even_col'">
        <div class="data-sub-container">
          <div class="data-column request-number" title="{{ requestList.id }}">{{ requestList.id }}</div>
          <div class="data-column rewards-id" title="{{ requestList.member }}">{{ requestList.member }}</div>
          <div class="data-column creation-date" title="{{ requestList.created_ts | date:'MM/dd/YYYY'}}">{{ requestList.created_ts | date:'MM/dd/YYYY'}}</div>
          <div class="data-column close-date" title="{{ requestList.updated_ts==='N/A'?requestList.updated_ts:requestList.updated_ts | date:'MM/dd/YYYY'}}">{{ requestList.updated_ts==='N/A'?requestList.updated_ts:requestList.updated_ts | date:'MM/dd/YYYY'}}</div>
          <div class="data-column description" title="{{ requestList.note_text }}" (click)="popupOpenState(requestList)">{{ requestList.note_text }}</div>
          <div class="data-column status" title="{{ requestList.status }}"
            [ngStyle]="{'color': requestList.status === 'Open' ? '#B00121' : '#1B2025',
                       'font-family': requestList.status === 'Open' ? 'NotoSansSemiBold' : 'NotoSansRegular'}"
            >{{ requestList.status }}</div>
        </div>
      </div>
    </div>
    <div class="pagination-button" *ngIf="paginationActive">
      <button class="previous-button" [ngStyle]="{'opacity': (prevUrl === 'None' ? 0 : 1)}" [disabled]="prevUrl === 'None'" (click)="nextPrev('prev')">< Previous</button>
      <button class="next-button" [ngStyle]="{'opacity': (nextUrl === 'None' ? 0 : 1)}" [disabled]="nextUrl === 'None'" (click)="nextPrev('next')">Next ></button>
    </div>
    <div class="error-message-more-records"  *ngIf="!dataAvailable">
        No Request Found.
    </div>
  </div>
  <app-footer></app-footer>
</div>
<div class="create-service-request" *ngIf="serviceCreateOpenState">
  <div class="create-service-container">
    <div class="request-heading">New service request</div>
    <form [formGroup]="serviceRequestCreateForm">
      <div class="create-service-form">
        <mat-form-field appearance="fill" class="create-service-form-dropdown">
          <mat-label>Urgency</mat-label>
          <mat-select formControlName="priority_list">
            <mat-option value="low"> Low </mat-option>
            <mat-option value="medium"> Medium </mat-option>
            <mat-option value="high"> High </mat-option>
          </mat-select>
          <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo"/>
        </mat-form-field>

        <mat-form-field appearance="fill" class="create-service-form-input">
          <mat-label>WestJet Reward ID (optional)</mat-label>
          <input matInput formControlName="reward_id" placeholder="" value="" (keyup)="keyupEvent($event)"/>
        </mat-form-field>
      </div>
      <div class="create-service-form-description">
        <mat-form-field appearance="fill" class="create-service-form-input">
          <mat-label>Description</mat-label>
          <textarea matInput cdkTextareaAutosize formControlName="description" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="11" cdkAutosizeMaxRows="11" value="" (keyup)="keyupEvent($event)"></textarea>
        </mat-form-field>
      </div>
    </form>
    <div class="action-buttons">
      <button mat-raised-button class="create-button" 
      [disabled]="disableCreateButton"
      [ngStyle]="{'cursor': (disableCreateButton? 'not-allowed':'pointer')}" (click)="createService()">CREATE REQUEST</button>
      <button mat-raised-button class="clear-button" (click)="popupState('close')">
        CANCEL
      </button>
    </div>
  </div>
</div>
<div class="view-service-request" *ngIf="serviceOpenState">
  <div class="view-service-container">
    <div class="view-request-heading">Service Request #{{serviceOpenStateData.id}}</div>
    <div class="request-data-heading-box">
      <div class="request-data-heading rewards-heading">
        Rewards ID:
      </div>
      <div class="request-data-heading">
        Status:
      </div>
      <div class="request-data-heading">
        Urgency:
      </div>
    </div>
    <div class="request-data-box">
      <div class="request-data rewards-heading">
        {{serviceOpenStateData.member}}
      </div>
      <div class="request-data" [ngStyle]="{'color': (serviceOpenStateData.status === 'Open'? '#B00121':'#26BBB1'),
                                            'font-family': serviceOpenStateData.status === 'Open' ?'NotoSansSemiBold':'NotoSansSemiBold'}">
        {{serviceOpenStateData.status}}
      </div>
      <div class="request-data">
        {{serviceOpenStateData.priority}}
      </div>
    </div>
    <div class="request-data-heading-box">
      <div class="request-data-heading rewards-heading">
        Creation date:
      </div>
      <div class="request-data-heading">
        Close Date
      </div>
    </div>
    <div class="request-data-box">
      <div class="request-data rewards-heading">
        {{serviceOpenStateData.created_ts | date:'MM/dd/YYYY'}}
      </div>
      <div class="request-data">
        {{serviceOpenStateData.updated_ts==='N/A'?serviceOpenStateData.updated_ts:serviceOpenStateData.updated_ts | date:'MM/dd/YYYY'}}
      </div>
    </div>
    <div class="request-data-heading-box">
      <div class="request-data-heading">
        Description:
      </div>
    </div>
    <div class="request-data-box description-heading">
      <div class="request-data description">
        {{serviceOpenStateData.note_text}}
      </div>
    </div>
    <div class="action-buttons">
      <button mat-raised-button class="close-button" (click)="popupOpenState('close')">CLOSE</button>
    </div>
  </div>
</div>
