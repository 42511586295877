<div class="splash-container">
    <div class="main-container">
        <div class="twofa-logo">
            <img src="../../../assets/image/2fa-secondary-icon.png" alt="" height="159px" width="133px">
        </div>

        <div class="splash-heading">
            Two-Factor Authentication
        </div>

        <div class="setup-header">
            Enter the 6 figure confirmation code sent to your associated email in order to proceed with setup:
        </div>

        <form [formGroup]="form">
            <div class="otp-inputs" [ngClass]="{'otp-inputs-error': showError}">
                <div *ngFor="let input of formInput;  index as i">
                    <input size="10" #formRow type="text" formControlName="{{input}}" class="form-control" maxlength="1" (keyup)="keyUpEvent($event, i)">
                </div>
            </div>

            <div class="error-msg" *ngIf="showError">
                {{errorMsg}}
            </div>

            <div class="resend-link" (click)="resetAuthenticator()">
                Don’t have access to your authenticator? Reset 2FA via email.
            </div>

            <div class="button-container">
                <button mat-raised-button class="continue-button" [ngClass]="{'disabled': !form.valid}" [disabled]="!form.valid" (click)="onSubmit()">Continue</button>
            </div>
        </form>

    </div>
</div>