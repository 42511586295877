<div class="member-profile-page" *ngIf="showMemberProfileComponent">
    <div>
        <hr class="misc-divider" />
    </div>
    <div class="member-profile-parent-container">
        <div class="member-profile-container">
            <div class="name-details">
                <div class="name-initial" [ngStyle]="tierColor">
                    <a>{{nameInitials}}</a>
                    <div class="mono-logo"><img class="mono-logo-icon" src="../../assets/image/Logomono.png" alt="logo">
                    </div>
                </div>
                <div class="name-label-details">
                    <div class="name-title-label">{{dataFromMemberDetails.salutation}}.</div>
                    <div class="name-label">{{dataFromMemberDetails.user.first_name}} <br />
                        {{dataFromMemberDetails.user.last_name}}</div>
                </div>
            </div>
            <div class="prefered-name-label">Preferred name:</div>
            <div class="value-label">{{dataFromMemberDetails.user.first_name}}</div>
            <div class="heading-label">Tier:</div>
            <div class="value-label">{{memberTierCode}}</div>
            <div class="heading-label">WestJet Rewards ID:</div>
            <div class="value-label">{{dataFromMemberDetails.member_id}}</div>
            <div class="heading-label">Visit History:</div>
            <div class="value-label">{{visitDate}}</div>
            <div class="heading-label" *ngIf="showTierToUpdate">Spend to achieve next tier:</div>
            <div class="value-label" *ngIf="showTierToUpdate">${{pointRemaining}}</div>
            <!-- <div class="member-button">
                <button mat-raised-button class="go-to-member-button">GO TO MEMBER</button>
            </div> -->
        </div>
        <div class="lounge-access-container" [formGroup]="loungeAccessDetailsForm">
            <div class="loungue-access-label">Lounge Access</div>
            <div class="member-access-toggles">
                <div class="member-unlimited" *ngIf="showMemberUnlimitedAccessToggle">
                    <div class="member-unlimited-label">Member Unlimited Access:</div>
                    <div class="member-unlimited-toggle">
                        <mat-slide-toggle class="member-unlimited-toggle-button" color="primary" [disabled]="true"
                            checked></mat-slide-toggle>
                    </div>
                </div>
                <!-- <div class="member-limited" *ngIf="showMemberLimitedAccessToggle">
                    <div class="member-limited-label">Member Limited Access:</div>
                    <div class="member-limited-toggle">
                        <mat-slide-toggle class="member-limited-toggle-button" color="primary"></mat-slide-toggle>
                    </div>
                </div> -->
            </div>
            <div class="family-unlimited-access" *ngIf="showFamilyUnlimitedAccess">
                <div class="family-unlimited-access-label">
                    Family Unlimited Access:
                    <a>(up to {{familyUnlimitedAccessCount}} immediate)</a>
                </div>
                <div class="family-accesss-count">
                    <button [ngClass]="{ 'family-class-option-selected': clickedIndexFamilyAccess === index, 'full-border' : familyAccessCount.length == 1 }"
                        class="family-class-option" id="family-accesss-option-{{count}}"
                        *ngFor="let count of familyAccessCount; let index = index"
                        (click)="familyAccessCountChange(count, index)">
                        {{count}}
                    </button>
                </div>
            </div>
            <div class="family-unlimited-access" *ngIf="showLoungeLimitedAccessVoucher">
                <div class="family-unlimited-access-label-for-guest-voucher">
                    Lounge Limited Access:<br/>
                    <a>({{loungeLimitedAccessVoucherCount}} available)</a>
                </div>
                <div class="family-accesss-count">
                    <button [ngClass]="{ 'family-class-option-selected': clickedIndexloungeLimited === index, 'full-border' : loungeLimitedVoucher.length == 1 }"
                        class="family-class-option" id="family-accesss-option-{{count}}"
                        *ngFor="let count of loungeLimitedVoucher; let index = index"
                        (click)="loungeLimitedAccessVoucherCountChange(count, index)">
                        {{count}}
                    </button>
                </div>
            </div>
            <div class="family-unlimited-access" *ngIf="showGuestAccessVoucher">
                <div class="family-unlimited-access-label">
                    Guest Access Vouchers:
                    <a>({{guestAccessVouchersCount}} available)</a>
                </div>
                <div class="family-accesss-count">
                    <button [ngClass]="{ 'family-class-option-selected': clickedIndexGuestVoucher === index, 'full-border' : guestAccessCount.length == 1 }"
                        class="family-class-option" id="family-accesss-option-{{count}}"
                        *ngFor="let count of guestAccessCount; let index = index"
                        (click)="guestAccessCountChange(count, index)">
                        {{count}}
                    </button>
                </div>
            </div>
            <div class="authorise-access-details">
                <div class="access-details">
                    <div class="serial-number">1.</div>
                    <div class="traveler-drop-down">
                        <mat-form-field appearance="fill" class="traveler-selection">
                            <mat-label>Traveler Type</mat-label>
                            <mat-select formControlName="traveler_type" (selectionChange)="travelerTypeChange($event.value)">
                                <mat-option *ngFor="let travelerTypeValues of travelerTypes" value="{{travelerTypeValues.value}}">{{travelerTypeValues.labelName}}</mat-option>
                            </mat-select>
                            <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                        </mat-form-field>
                    </div>
                    <div class="flight-details">
                        <mat-form-field appearance="fill" class="flight-type-selection">
                            <mat-select formControlName="flight_type">
                                <mat-option value="WS"> WS </mat-option>
                                <mat-option value="DL"> DL </mat-option>
                                <mat-option value="KL"> KL </mat-option>
                            </mat-select>
                            <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                        </mat-form-field>
                        <div class="flight-number">
                            <mat-form-field appearance="fill" class="flight-number-input">
                                <mat-label>Enter flight number</mat-label>
                                <input matInput formControlName="flight_number" placeholder="" (keyup)="keyupEvent($event)" appNumberOnly appNospace/>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="access-details">
                    <mat-form-field appearance="fill" class="reservation-code-input">
                        <mat-label>Reservation Code/PNR</mat-label>
                        <input matInput formControlName="reservation_code" placeholder="" (keyup)="keyupEvent($event)" maxlength="10" appNospace/>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="voucher-number-input" *ngIf="showVoucherNumber">
                        <mat-label>Voucher number</mat-label>
                        <input matInput formControlName="voucher_number" placeholder="" (keyup)="keyupEvent($event)" appNospace/>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="passengers-selection" *ngIf="showNumberOfPassengers">
                        <mat-label>Number of passengers</mat-label>
                        <mat-select formControlName="number_of_passengers" (selectionChange)="numberOfPassengersValue()">
                            <mat-option *ngFor="let passengers of numberOfPassengers" value="{{passengers}}"> {{
                                passengers }} </mat-option>
                        </mat-select>
                        <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                    </mat-form-field>
                </div>
            </div>
            <div class="authorise-access-details" formArrayName="data"
                *ngFor="let i of loungeAccessDetailsForm.get('data')['controls']; let index = index">
                <div [formGroupName]="index">
                    <div class="access-details">
                        <div class="serial-number">{{index+2}}.</div>
                        <div class="traveler-drop-down">
                            <mat-form-field appearance="fill" class="traveler-selection">
                                <mat-label>Traveler Type</mat-label>
                                <mat-select formControlName="l_companion_type" id="{{index}}" [disabled]="true">
                                    <mat-option value="GUEST_ACCESS"> Guest access </mat-option>
                                    <mat-option value="FAMILY_ACCESS"> Family access </mat-option>
                                </mat-select>
                                <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                            </mat-form-field>
                        </div>
                        <div class="flight-details">
                            <mat-form-field appearance="fill" class="flight-type-selection">
                                <mat-select formControlName="l_flight_code" id="{{index}}">
                                    <mat-option value="WS"> WS </mat-option>
                                    <mat-option value="DL"> DL </mat-option>
                                    <mat-option value="KL"> KL </mat-option>
                                </mat-select>
                                <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                            </mat-form-field>
                            <div class="flight-number">
                                <mat-form-field appearance="fill" class="flight-number-input">
                                    <mat-label>Enter flight number</mat-label>
                                    <input matInput formControlName="l_flight_number" id="{{index}}" placeholder="" (keyup)="keyupEvent($event)" appNumberOnly appNospace/>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="access-details">
                        <mat-form-field appearance="fill" class="reservation-code-input">
                            <mat-label>Reservation Code/PNR</mat-label>
                            <input matInput formControlName="l_pnr_number" id="{{index}}" placeholder="" (keyup)="keyupEvent($event)" maxlength="10" appNospace/>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="lounge-access-buttons">
                    <button mat-raised-button class="authorise-access-button" (click)="authoriseAccess()" [disabled]="disableAuthoriseButton"
                    [ngStyle]="{'cursor': (disableAuthoriseButton? 'not-allowed':'pointer')}">AUTHORIZE ACCESS</button>

                    <button mat-raised-button class="cancel-button" (click)="hideProfile('close')">CANCEL</button>
            </div>
        </div>
    </div>
</div>