import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	isUserLoggedIn: any = false;

	constructor(
		private router: Router,
		private authenticationService: AuthenticationService
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const currentUser = this.authenticationService.currentUserValue;
		if (currentUser && currentUser.token) {
			this.isUserLoggedIn = true;
			localStorage.setItem('isUserLoggedIn',this.isUserLoggedIn);
			// logged in so return true
			return true;
		}
		// not logged in so redirect to login page with the return url
		this.router.navigate(['login']);
		return false;
	}
}
