<div class="reports-tab" id="reports-tab">
    <div class="reports-container" *ngIf="reportsScreenLoading">
        <div class="generate-button">
            <div class="action-buttons">
                <button mat-raised-button class="generate-report-button" (click)="popupState('open')">GENERATE REPORT</button>
            </div>
        </div>
        <div class="data-label">
            <div class="label-heading voucher-redemption">Report Name</div>
            <div class="label-heading creation-date">Creation date</div>
            <div class="label-heading owner">Owner</div>
            <div class="label-heading file-type">File type</div>
            <div class="label-heading file-size">File size</div>
            <div class="label-heading status">Status</div>
            <div class="label-heading download-icon">Download</div>
        </div>
        <div class="data-screen" *ngIf="dataAvailable">
            <div class="data-rows" *ngFor="let reportData of generatedReportData; let odd = odd" [ngClass]="odd ? 'odd_col' : 'even_col'">
                <div class="data-sub-container">
                    <div class="data-column voucher-redemption" title="{{ reportData.file_name[0] }}">{{ reportData.file_name[0] }}</div>
                    <div class="data-column creation-date" title="{{ reportData.created_ts | date:'dd/MM/YYYY hh:mm a' }}">{{ reportData.created_ts | date:'dd/MM/YYYY hh:mm a' }}</div>
                    <div class="data-column owner" title="{{ reportData.report_creator }}">{{ reportData.report_creator }}</div>
                    <div class="data-column file-type" title="{{ reportData.file_type }}">{{ reportData.file_type }}</div>
                    <div class="data-column file-size" title="{{ reportData.file_size }} MB">{{ reportData.file_size }}&nbsp;MB</div>
                    <div class="data-column status" title="{{ reportData.status }}">{{ reportData.status }}</div>
                    <div class="data-column download-icon" *ngIf="reportData.file_path!=null"><a href="{{reportData.file_path}}" download><img src="../../assets/image/download.png" alt=""></a></div>
                    <div class="data-column download-icon" *ngIf="reportData.file_path===null"><img src="../../assets/image/download.png" alt=""></div>
                </div>
            </div>
        </div>
        <div class="pagination-button" *ngIf="paginationActive">
            <button class="previous-button" [ngStyle]="{'opacity': (prevUrl === 'None' ? 0.7 : 1)}"
                [disabled]="prevUrl === 'None'" (click)="nextPrev('prev')">< Previous</button>
            <button class="next-button" [ngStyle]="{'opacity': (nextUrl === 'None' ? 0.7 : 1)}" 
                [disabled]="nextUrl === 'None'" (click)="nextPrev('next')"> Next ></button>
        </div>
        <div class="error-message-more-records" *ngIf="!dataAvailable">
            No Reports Found.
        </div>
    </div>
    <app-footer></app-footer>
</div>
<div class="generate-service-report" *ngIf="showGenerateServiceReportModel">
    <div class="generate-service-container">
        <div class="container-heading">Generate service report</div>
        <form [formGroup]="generateServiceReport" class="generate-service-form">
            <mat-form-field appearance="fill" class="service-report-form-input">
                <mat-label>Date range</mat-label>
                <mat-date-range-input [rangePicker]="picker"  [min]="yesterdaysDate" [max]="MaxDate">
                    <input matStartDate matInput formControlName="start_date" placeholder="MM/DD/YYYY">
                    <input matEndDate matInput formControlName="end_date" placeholder="MM/DD/YYYY">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker>
                    <mat-date-range-picker-actions>
                        <button mat-raised-button matDatepickerApply class="select-button">SELECT</button>
                        <button mat-raised-button (click)="onPickerClear(picker)" class="cancel-button">CANCEL</button>
                    </mat-date-range-picker-actions>
                </mat-date-range-picker>
            </mat-form-field>
            <!-- <mat-form-field appearance="fill" class="service-report-form-input">
                <mat-label>Date range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate matInput placeholder="Start Date">
                    <input matEndDate matInput placeholder="End Date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker">
                </mat-datepicker-toggle>
                <mat-datepicker #picker>
                    <mat-datepicker-actions>
                        <button mat-raised-button matDatepickerApply class="select-button">SELECT</button>
                        <button mat-raised-button (click)="onPickerClear(picker)" class="cancel-button">CANCEL</button>
                    </mat-datepicker-actions>
                </mat-datepicker>
            </mat-form-field> -->

            <mat-form-field appearance="fill" class="service-report-form-dropdown">
                <mat-label>Download file type</mat-label>
                <mat-select formControlName="file_type" (selectionChange)="fileTypeChange($event)">
                    <mat-option value="csv"> CSV </mat-option>
                    <mat-option value="pdf"> PDF </mat-option>
                    <mat-option value="xlsx"> XLSX </mat-option>
                </mat-select>
                <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
            </mat-form-field>

            <div class="action-buttos">
                <button mat-raised-button class="submit-button" [disabled]="disableSumitButton"
                    [ngStyle]="{'cursor': (disableSumitButton? 'not-allowed':'pointer')}" (click)="generateReport()">SUBMIT</button>
                <button mat-raised-button class="cancel-button" (click)="popupState('close')">CANCEL</button>
            </div>
        </form>
    </div>
</div>
<div class="success-box" *ngIf="showSuccessPopup">
    <div class="success-container">
        <div class="success-heading">
            <div class="success-heading-banner">Success</div>
            <img src="../../assets/image/crossIcon.png" alt="" class="close-button" (click)="closePopup()">
        </div>
        <div class="success-description">Your report was generated.</div>
    </div>
</div>
<div class="error-box" *ngIf="showErrorPopup">
    <div class="error-container">
        <div class="error-heading">
            <div class="error-heading-banner">Error</div>
            <img src="../../assets/image/crossIcon.png" alt="" class="close-button" (click)="closePopup()">
        </div>
        <div class="error-description">There was an error generating report.</div>
    </div>
</div>