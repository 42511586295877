<div class="guest-entries-tab">
    <div class="guest-entries-container" *ngIf="guestScreenLoading">
        <div class="guest-entries-search-box">
            <form class="guest-entries-search-form" name="guestSearchForm" [formGroup]="guestEntriesForm" >
                <mat-form-field appearance="fill" class="guest-entries-form-dropdown">
                    <mat-select formControlName="Search_Formate_List" (selectionChange)="searchFormateListChange($event)">
                        <mat-option value="pnr"> PNR </mat-option>
                        <mat-option value="name"> Traveler Name </mat-option>
                    </mat-select>
                    <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                </mat-form-field>
                <mat-form-field appearance="fill" class="search-input" [hideRequiredMarker]="true" *ngIf="showPnr">
                    <mat-label>PNR number</mat-label>
                    <input matInput formControlName="searchId" placeholder="" />
                </mat-form-field>
                <mat-form-field appearance="fill" class="search-input" [hideRequiredMarker]="true" *ngIf="showTravelerName">
                    <mat-label>Traveler Name</mat-label>
                    <input matInput formControlName="travelerName" placeholder="" />
                </mat-form-field>
                <div class="action-buttons">
                    <button mat-raised-button class="request-button" (click)="guestEntriesData('search')"
                        [ngStyle]="{'cursor': 'pointer'}">SEARCH</button>
                </div>
                <br>
            </form>
        </div>
        <div class="data-label">
            <div class="label-heading traveler-name">Traveler Name</div>
            <div class="label-heading rewards-id">Rewards ID</div>
            <div class="label-heading entry-date">Entry Date</div>
            <div class="label-heading entry-time">Entry Time</div>
            <div class="label-heading pnr-status">PNR</div>
            <div class="label-heading access-status">Access Status</div>
        </div>
        <div class="data-screen" *ngIf="dataAvailable">
            <div class="data-rows" *ngFor="let entriesList of guestEntriesList let odd = odd" [ngClass]="odd ? 'odd_col' : 'even_col'">
                <div class="data-sub-container">
                    <div class="data-column traveler-name" title="{{ entriesList.header.h_member_name }}">{{ entriesList.header.h_member_name }}</div>
                    <div class="data-column rewards-id" title="{{ entriesList.member_id }}">{{ entriesList.member_id }}</div>
                    <div class="data-column entry-date" title="{{ entriesList.h_bit_date | date:'MM/dd/YYYY' }}">{{ entriesList.h_bit_date | date:'MM/dd/YYYY' }}</div>
                    <div class="data-column entry-time" title="{{ entriesList.h_bit_date | date: 'hh:mm a'}}">{{ entriesList.h_bit_date | date: 'hh:mm a'}}</div>
                    <div class="data-column pnr-status" title="{{ entriesList.header.h_pnr_number }}">{{ entriesList.header.h_pnr_number }}</div>
                    <div class="data-column access-status" title="{{ entriesList.header.h_entry_status }}"
                    [ngStyle]="{'color': entriesList.header.h_entry_status === 'Access Denied' ? '#B00121' : '#016866', 'font-family':'NotoSansSemiBold'}">{{ entriesList.header.h_entry_status }}</div>
                </div>
            </div>
        </div>
        <div class="pagination-button" *ngIf="paginationActive">
            <button class="previous-button" [ngStyle]="{'opacity': (prevUrl === 'None' ? 0 : 1)}" 
                [disabled]="prevUrl === 'None'" (click)="nextPrev('prev')">< Previous</button>
            <button class="next-button" [ngStyle]="{'opacity': (nextUrl === 'None' ? 0 : 1)}" 
                [disabled]="nextUrl === 'None'" (click)="nextPrev('next')"> Next ></button>
        </div>
        <div class="error-message-more-records" *ngIf="!dataAvailable">
            No Guest Entries Found.
        </div>
    </div>
    <app-footer></app-footer>
</div>
