import { Component, OnInit, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/services/authentication.service';
import { TwofaSetupContainerComponent } from '../twofa-setup-container/twofa-setup-container.component';
import { TwofaSplashScreenComponent } from '../twofa-splash-screen/twofa-splash-screen.component';
import { TwofaService } from '../twofa.service';

@Component({
  selector: 'app-twofa-totp-verification',
  templateUrl: './twofa-totp-verification.component.html',
  styleUrls: ['./twofa-totp-verification.component.less']
})
export class TwofaTotpVerificationComponent implements OnInit {

  form: FormGroup;
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  @ViewChildren('formRow') rows: any;
  currentUser: any;
  errorMsg: any;
  showError = false;

  constructor(
    public dialogRef: MatDialogRef<TwofaTotpVerificationComponent>,
    private dialog: MatDialog,
    private twofaservice: TwofaService,
    private router:Router,
    private authenticationService:AuthenticationService,
  ) {
    this.form = this.toFormGroup(this.formInput);
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  toFormGroup(elements: any) {
    const group: any = {};

    elements.forEach((key: any) => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }
  
  keyUpEvent(event: any, index: any) {
    this.showError = false;
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1 ;
    } else {
      pos = index + 1 ;
    }
    if (pos > -1 && pos < this.formInput.length ) {
      this.rows._results[pos].nativeElement.focus();
    }

  }

  onSubmit() {
    if (this.form.valid) {
      this.showError = false;
      const otpValue = Object.values(this.form.value).join('');
      var req = {
        otp: otpValue
      }
      this.twofaservice.verifyLoginTOTP(req,this.currentUser.id).then((data: any) => {
        data.header = true;
        this.authenticationService.updateCurrentUserObs(data);
        this.dialogRef.close();
        this.router.navigate(['lounge-access']);
        location.reload();
      }).catch((error: any) => { 
        this.showError = true;
        this.errorMsg = error.error.message;
      });
    } else {
    }
  }

  resetAuthenticator() {
    this.showError = false;
    this.twofaservice.updateScreen(0);
    this.twofaservice.sendVerificationEmail(this.currentUser.id).then((data: any) => {
      localStorage.setItem('for_2fa_only',data["Two-FA-Token"]);
      const dialogRef = this.dialog.open(TwofaSetupContainerComponent, {
        width: '955px',
        panelClass: 'twofa-dialog-container',
        backdropClass: 'backdropBackground',
        data: {
        },
        position: { top: '160px' }
      });
      this.dialogRef.close();
  
      dialogRef.afterClosed().subscribe(result => {     
      });
    }).catch((error: any) => { 
      console.log(error);
    });
  }

}
