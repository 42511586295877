<!-- <div class="success-container">
    <div class="container-image">
            <img src="../../../assets/image/SuccessIcon.png" alt="logo" class="logo"/>
    </div>
    <div class="container-description">
        <div class="heading">
            Success
        </div>
        <div class="description">
            You have successfully activated two-factor authentification for your account.
        </div>
    </div>
    <div class="button-container">
        <div class="save-cancel-button">
            <button mat-raised-button class="cancel-button" (click)="closePopup();">OK</button>
        </div>
    </div>
</div> -->

<div class="success-box">
    <div class="success-container">
        <div class="success-heading">
            <div class="success-heading-banner">Success</div>
            <img src="../../assets/image/crossIcon.png" alt="" class="close-button" (click)="closePopup()">
        </div>
        <div class="success-description">You have successfully activated two-factor authentification for your account.</div>
    </div>
</div>
