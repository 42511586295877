import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/services/authentication.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonService } from 'src/services/common.service';
import { MemberService } from 'src/services/member.service';
import { TwofaSplashScreenComponent } from '../twofa-setup/twofa-splash-screen/twofa-splash-screen.component';
import { TwofaTotpVerificationComponent } from '../twofa-setup/twofa-totp-verification/twofa-totp-verification.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup
  rememberme: any = false;
  errorMessage:any = false;
  errorDisplay:any = false;
  tenantInfo: any;
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private commonService: CommonService,
    private memberService: MemberService,
    private dialog: MatDialog,
  ) {
  }

  currentUser: any;
  isLogin = false;
  ngOnInit() {
    if (localStorage.getItem('isUserLoggedIn') != undefined && localStorage.getItem('isUserLoggedIn') == 'true') {
      this.isLogin = true;
      this.router.navigate(['lounge-access']);
    } else {
      this.isLogin = false;
    }


    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    if ((sessionStorage.getItem('username') != null) && (sessionStorage.getItem('password') != null)) {
      this.loginForm.get("username").setValue(sessionStorage.getItem('username'));
      this.loginForm.get("password").setValue(sessionStorage.getItem('password'));
    }

  }
  keyupEvent(){
    this.errorDisplay = false;
  }
  login() {
    this.errorDisplay = false;

    this.memberService.getTenantDetail().then((data: any) => {
      localStorage.setItem('tenantInfo',JSON.stringify(data));
      this.tenantInfo = data;
    })
    .catch(error => {
    });
    let request: any;
    request = {
      "username": this.loginForm.value.username,
      "password": this.loginForm.value.password
    }
    this.commonService.setShowContentLoaderValue(true);
    this.authenticationService.login(request)
      .pipe(first())
      .subscribe(
        data => {
          if (this.rememberme) {
            sessionStorage.setItem('username', this.loginForm.value.username);
            sessionStorage.setItem('password', this.loginForm.value.password);
          }
          if (data &&(data.token || data['Two-FA-Token'])) {
            this.router.navigate(['lounge-access']);
            this.commonService.setShowContentLoaderValue(false);

            // if(this.tenantInfo["2fa_status"] == 'MANDATORY' && data.is_2fa_setup == false) {
            //  //MANDATORY
            //   this.setupTwoFaOptional();
            // }
            // if(this.tenantInfo["2fa_status"] == 'OPTIONAL' && data.is_2fa_setup == false) {
            //  //OPTIONAL
            //   this.setupTwoFaOptional();
            //   this.router.navigate(['lounge-access']);
            //   this.commonService.setShowContentLoaderValue(false);
            // }
            // if(this.tenantInfo["2fa_status"] == 'NOT_APPLICABLE' && data.is_2fa_setup == false) {
            //   this.router.navigate(['lounge-access']);
            //   this.commonService.setShowContentLoaderValue(false);
            // }
          }
          // if(data.is_2fa_setup == true) {
          //   this.verifyTOTP();
          // }
        },
        error => {
        this.errorDisplay=true
        const validationErrors = error.error.error.message;
        this.commonService.setShowContentLoaderValue(false);
        const formControl = this.loginForm.get("password");
        formControl.setErrors({
          serverError: validationErrors
        });
        }
      );
  }

  setupTwoFaOptional() {
    const dialogRef = this.dialog.open(TwofaSplashScreenComponent, {
      width: '478px',
      panelClass: 'twofa-dialog-container',
      backdropClass: 'backdropBackground',
      data: {
      },
      position: { top: '160px' }
    });

    dialogRef.afterClosed().subscribe(result => {     
    });
  }

  verifyTOTP() {
    const dialogRef = this.dialog.open(TwofaTotpVerificationComponent, {
      width: '478px',
      panelClass: 'twofa-dialog-container',
      backdropClass: 'backdropBackground',
      data: {
      },
      position: { top: '160px' }
    });

    dialogRef.afterClosed().subscribe(result => {     
    });
  }

}
