import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthenticationService } from 'src/services/authentication.service';
import { MemberService } from 'src/services/member.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  public tabs = [
    {
      label: 'Lounge Access',
      link: ['./lounge-access']
    }, 
    {
      label: 'Service Requests',
      link: ['./service-requests']
    },
    {
      label: 'Guest Entries',
      link: ['./guest-entries']
    }
  ];
  pageRoute: any;
  faqFlag = false;
  tncFlag = false;
  versionFlag = false
  loginFlag = false;
  nameInitials : any;

  constructor(
    private location: Location,
    private authenticationService: AuthenticationService,
    private router: Router,
    private memberService: MemberService,
  ) {
    this.pageRoute = location.path();
    if (this.pageRoute == '/faq') {
      this.faqFlag = true;
    } else if (this.pageRoute == '/tnc') {
      this.tncFlag = true;
    }
    else if (this.pageRoute == '/version') {
      this.versionFlag = true
    }
  }

  currentUser: any;
  userdata: any;
  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.authenticationService.currentUser.subscribe(data => {
      this.currentUser = data;
      // if (this.currentUser && (this.currentUser.token || this.currentUser['Two-FA-Token']) && localStorage.getItem('isUserLoggedIn') == 'true') {
        if (this.currentUser && this.currentUser.token) {
        this.loginFlag = true;
        this.userdata = localStorage.getItem('currentUser');
        this.currentUser.user.last_name != "" ? 
        (this.nameInitials = this.currentUser.user.first_name[0] + this.currentUser.user.last_name[0] ):
        (this.nameInitials = this.currentUser.user.first_name[0])
        if(this.currentUser.role.code==="PROGRAM_MANAGER"){
          this.tabs = [
            {
              label: 'Lounge Access',
              link: ['./lounge-access']
            }, 
            {
              label: 'Service Requests',
              link: ['./service-requests']
            },
            {
              label: 'Guest Entries',
              link: ['./guest-entries']
            },
            {
              label: 'Self-Serve',
              link: ['./self-serve']
            }, 
            {
              label: 'Reports',
              link: ['./reports']
            }
          ];
        }
        else if(this.currentUser.role.code==="LOUNGE_ADMIN" && this.currentUser.location_code==="YYC01"){
          this.tabs = [
            {
              label: 'Lounge Access',
              link: ['./lounge-access']
            }, 
            {
              label: 'Service Requests',
              link: ['./service-requests']
            },
            {
              label: 'Guest Entries',
              link: ['./guest-entries']
            },
            {
              label: 'Self-Serve',
              link: ['./self-serve']
            }, 
            {
              label: 'Reports',
              link: ['./reports']
            }
          ];
        }
        else if(this.currentUser.role.code==="LOUNGE_AGENT" && this.currentUser.location_code==="YYC01"){
          this.tabs = [
            {
              label: 'Lounge Access',
              link: ['./lounge-access']
            }, 
            {
              label: 'Service Requests',
              link: ['./service-requests']
            },
            {
              label: 'Guest Entries',
              link: ['./guest-entries']
            },
            {
              label: 'Self-Serve',
              link: ['./self-serve']
            }
          ];
        }
        else if(this.currentUser.role.code==="LOUNGE_ADMIN" && this.currentUser.location_code!="YYC01"){
          this.tabs = [
            {
              label: 'Lounge Access',
              link: ['./lounge-access']
            }, 
            {
              label: 'Service Requests',
              link: ['./service-requests']
            },
            {
              label: 'Guest Entries',
              link: ['./guest-entries']
            },
            {
              label: 'Reports',
              link: ['./reports']
            }
          ];
        }
        else if(this.currentUser.role.code==="LOUNGE_AGENT" && this.currentUser.location_code!="YYC01"){
          this.tabs = [
            {
              label: 'Lounge Access',
              link: ['./lounge-access']
            }, 
            {
              label: 'Service Requests',
              link: ['./service-requests']
            },
            {
              label: 'Guest Entries',
              link: ['./guest-entries']
            }
          ];
        }
        else{
          this.tabs = [
            {
              label: 'Lounge Access',
              link: ['./lounge-access']
            }, 
            {
              label: 'Service Requests',
              link: ['./service-requests']
            },
            {
              label: 'Guest Entries',
              link: ['./guest-entries']
            }
          ];
        }
        for(let i = 0; i < this.tabs.length; i++){
          let value = this.tabs[i].link[0];
          let routeValue = value.slice(1);
          if(routeValue == this.pageRoute){
            this.router.navigate([this.tabs[i].link[0]]);
            break;
          }
          else if(this.pageRoute === '/faq' || this.pageRoute === '/tnc' || this.pageRoute === '/version'){
            break
          }
          else{
            this.router.navigate(['./lounge-access']);
          }
        }
      } else {
        this.loginFlag = false;
      }
    });
  }

  logout() {
    this.memberService.logOut();
    this.authenticationService.logout();
    this.router.navigate(['login']);
  }

}
