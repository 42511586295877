import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { MaterialModule } from './modules/material.module';
import { FaqComponent } from './faq/faq.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReportsComponent } from './reports/reports.component';
import { SelfServiceComponent } from './self-service/self-service.component';
import { GuestEntriesComponent } from './guest-entries/guest-entries.component';
import { AuthGuard } from 'src/guards';
import { MemberDetailsComponent } from './member-details/member-details.component';
import { FooterComponent } from './footer/footer.component';
import { ServiceRequestComponent } from './service-request/service-request.component';
import { JwtInterceptor } from 'src/services/jwt.interceptor';
import { MemberProfileComponent } from './member-profile/member-profile.component';
import { VersionComponent } from './version/version.component';
import { NospaceDirective } from './nospace.directive';
import { NumberOnlyDirective } from './number-only.directive';
import { EmailOtpSectionComponent } from './twofa-setup/twofa-setup-container/email-otp-section/email-otp-section.component';
import { QrScanSectionComponent } from './twofa-setup/twofa-setup-container/qr-scan-section/qr-scan-section.component';
import { TwofaSetupContainerComponent } from './twofa-setup/twofa-setup-container/twofa-setup-container.component';
import { TwofaTotpSectionComponent } from './twofa-setup/twofa-setup-container/twofa-totp-section/twofa-totp-section.component';
import { TwofaSetupComponent } from './twofa-setup/twofa-setup.component';
import { TwofaSplashScreenComponent } from './twofa-setup/twofa-splash-screen/twofa-splash-screen.component';
import { TwofaSuccessPopupComponent } from './twofa-setup/twofa-success-popup/twofa-success-popup.component';
import { TwofaTotpVerificationComponent } from './twofa-setup/twofa-totp-verification/twofa-totp-verification.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FaqComponent,
    TermsConditionsComponent,
    ServiceRequestComponent,
    ReportsComponent,
    SelfServiceComponent,
    GuestEntriesComponent,
    MemberDetailsComponent,
    FooterComponent,
    ServiceRequestComponent,
    MemberProfileComponent,
    VersionComponent,
    NospaceDirective,
    NumberOnlyDirective,
    TwofaSetupComponent,
    EmailOtpSectionComponent,
    TwofaSplashScreenComponent,
    TwofaSuccessPopupComponent,
    QrScanSectionComponent,
    TwofaTotpSectionComponent,
    TwofaSetupContainerComponent,
    TwofaTotpVerificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    QRCodeModule,
    NoopAnimationsModule
  ],
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
