import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.less']
})
export class VersionComponent implements OnInit {

  currentVersion:string="";
  buildDate:any={};
  constructor() { }

  ngOnInit(): void {
    this.currentVersion = "WestJet-Lounge-Portal-2.0.1";
    this.buildDate = new Date('2024-09-05');
  }
}
