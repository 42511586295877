import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/services/common.service';
import { MemberService } from 'src/services/member.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-self-service',
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.less']
})
export class SelfServiceComponent implements OnInit {

  selfServiceForm: FormGroup;
  selfServiceForData: any = [];
  lblName: any = '';
  disableSearchButton: boolean = true;
  showUserAccessTab: boolean = false;
  selfServiceScreenLoading:boolean = false;
  todaysDate:any = '';
  yesterdaysDate:any = '';
  paginationActive = false;
  dataAvailable = false;
  refreshRotation = false;

  nextUrl:any = 'None';
  prevUrl:any = 'None';
  grantAccessData:any = {};

  pipe = new DatePipe('en-US');

  constructor( 
    private formBuilder:FormBuilder,
    private memberService: MemberService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.selfServiceForm = this.formBuilder.group({
      search_criteria: ['pnr'],
      search_text: ['']
    });
    this.lblName = "PNR number";
    this.disableSearchButton = true;
    this.showUserAccessTab = false;
    this.todaysDate = new Date();
    this.todaysDate = this.pipe.transform(this.todaysDate,"yyyy-MM-dd")
    this.yesterdaysDate = new Date();
    this.yesterdaysDate = this.yesterdaysDate.setDate(this.yesterdaysDate.getDate()-1)
    this.yesterdaysDate = this.pipe.transform(this.yesterdaysDate ,"yyyy-MM-dd")
    this.selfServiceData("")
    this.refreshRotation = false
  }

  searchCriteriaChange(event: any){
    this.refreshRotation = false
    let searchType = this.selfServiceForm.controls.search_criteria.value;
    if(searchType === "traveler_name"){
      this.lblName = "Traveler name";
    }
    else if(searchType === "pnr"){
      this.lblName = "PNR number";
    }this.disableSearchButton = true;
    this.selfServiceForm.controls.search_text.setValue('');
  }

  keyupEvent(event: any) {
    let searchValue = this.selfServiceForm.controls.search_text.value;
    if(searchValue === ""){
      this.disableSearchButton = true;
    }
    else{
      this.disableSearchButton = false;
    }
  }

  popupState(currentState: any,data: any){
    this.refreshRotation = false
    const blurEffect = document.getElementById('self-service-tab');
    if(currentState === 'open'){
      this.showUserAccessTab = true;
      blurEffect.classList.add('blur-effect-background');
      this.grantAccessData = data;
    }
    else if(currentState === 'close'){
      blurEffect.classList.remove('blur-effect-background');
      this.showUserAccessTab = false;
    }
  }

  grantAccess(){
    this.refreshRotation = false
    let request:any = this.grantAccessData.header
    request.h_entry_status = "OVERRIDE GRANTED";
    request.h_bit_date = new Date().toISOString();
    request.h_access_status = "000";
    request.h_bit_source = "KIOSK"
    delete request.h_overrideable_flag;
    delete request.h_denial_reason;
    request.h_original_bit = this.grantAccessData.bit_reference;
    request.h_privileges = []
    if(request?.voucher_number){
      request.h_privileges.push(request.voucher_number)
      delete request.voucher_number;
    }
    else{
      delete request.h_privileges;
    }
    let cancelRequest:any = {
      "h_bit_date": new Date().toISOString(),
      "h_sponsor_id_text": "Lounge Portal",
      "h_sponsor_id": environment.sponsorID,
      "h_bit_category": "CANCELLATION",
      "h_bit_type": "REVERSAL",
      "cancel_bit_id": this.grantAccessData.bit_reference,
      "h_program_id": environment.programID,
      "h_member_id": this.grantAccessData.header.h_member_id
    }
    this.memberService.loungeAccess(request)
      .then(data => {
        this.memberService.loungeAccess(cancelRequest)
          .then(data => {
            this.commonService.setShowContentLoaderValue(true);
            setTimeout(() => {
              this.popupState('close','')
              this.selfServiceData('rotate')
            },1500)
          },
          (error: any) => {
            
          }
        )
      },
      (error: any) => {
        
      }
    )
  }

  selfServiceData(param){
    this.commonService.setShowContentLoaderValue(true);
    if(param==="" || param==="rotate"){
      if(param==="rotate"){
        this.refreshRotation = true
      }
      this.memberService.getSelfService("",this.todaysDate,this.yesterdaysDate).then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.refreshRotation = false
        this.selfServiceForData = data.body
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.selfServiceScreenLoading = true;
        if(this.selfServiceForData.length===0){
          this.dataAvailable = false
          this.paginationActive = false
        }
        else if(this.selfServiceForData.length<10){
          this.dataAvailable = true
          this.paginationActive = false
        }
        else {
          this.dataAvailable = true
          this.paginationActive = true
        }
      },
      (error: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.dataAvailable = false
        this.paginationActive = false
      });
      this.selfServiceForm.controls.search_text.setValue('');
    }
    else if(param==="search"){
      let searchCriteria = ''
      if(this.selfServiceForm.controls.search_criteria.value === 'pnr'){
        searchCriteria="&h_pnr_number="+this.selfServiceForm.controls.search_text.value
      }
      else{
        searchCriteria="&h_member_name="+this.selfServiceForm.controls.search_text.value
      }
      this.memberService.getSelfService(searchCriteria,this.todaysDate,this.yesterdaysDate).then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.selfServiceForData = data.body
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.selfServiceScreenLoading = true;
        if(this.selfServiceForData.length===0){
          this.dataAvailable = false
          this.paginationActive = false
        }
        else if(this.selfServiceForData.length<10){
          this.dataAvailable = true
          this.paginationActive = false
        }
        else {
          this.dataAvailable = true
          this.paginationActive = true
        }
      },
      (error: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.dataAvailable = false
        this.paginationActive = false
      });
    }
  }
  nextPrev(screen:any){
    this.refreshRotation = false
    this.commonService.setShowContentLoaderValue(true);
    if(screen==='next'){
      this.memberService.getSelfServiceNextPrev(this.nextUrl).then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.selfServiceForData = data.body
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.selfServiceScreenLoading = true;
        if(this.selfServiceForData.length===0){
          this.dataAvailable = false
        }
        else{
          this.dataAvailable = true
        }
      },
        (error: any) => {
          this.commonService.setShowContentLoaderValue(false);
          this.dataAvailable = false
          this.paginationActive = false
        });
    }
    else if(screen==='prev'){
      this.memberService.getSelfServiceNextPrev(this.prevUrl).then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.selfServiceForData = data.body
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.selfServiceScreenLoading = true;
        if(this.selfServiceForData.length===0){
          this.dataAvailable = false
        }
        else{
          this.dataAvailable = true
        }
      },
        (error: any) => {
          this.commonService.setShowContentLoaderValue(false);
          this.dataAvailable = false
          this.paginationActive = false
        });
      }
  }

}
