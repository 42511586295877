import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MemberService } from 'src/services/member.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/services/common.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.less']
})
export class ReportsComponent implements OnInit {

  generateServiceReport: any = FormGroup;
  guestEntriesList:any;
  showGenerateServiceReportModel: boolean = false;
  nextUrl:any = 'None';
  prevUrl:any = 'None';
  generatedReportData: any = [];
  disableSumitButton: boolean = true;
  currentUser: any;
  showSuccessPopup = false;
  showErrorPopup = false;
  todaysDate:any = '';
  yesterdaysDate:any = '';
  paginationActive = false;
  dataAvailable = false;
  MaxDate = new Date();
  reportsScreenLoading:boolean = false;

  pipe = new DatePipe('en-US');

  constructor(
    private formbuilder: FormBuilder,
    private memberService: MemberService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.generateServiceReport = this.formbuilder.group({
      file_type: [''],
      start_date: [''],
      end_date: [''],
    });
    this.todaysDate = new Date();
    this.todaysDate = this.pipe.transform(this.todaysDate,"yyyy-MM-dd")
    this.yesterdaysDate = new Date();
    this.yesterdaysDate = this.yesterdaysDate.setDate(this.yesterdaysDate.getDate()-90)
    this.yesterdaysDate = this.pipe.transform(this.yesterdaysDate ,"yyyy-MM-dd")
    this.disableSumitButton = true;
    this.currentUser = this.authenticationService.currentUserValue;
    this.getReportsData();
  }

  popupState(currentState: any){
    const blurEffect = document.getElementById('reports-tab');
    if(currentState === 'open'){
      this.showGenerateServiceReportModel = true;
      blurEffect.classList.add('blur-effect-background');
    }
    else if(currentState === 'close'){
      blurEffect.classList.remove('blur-effect-background');
      this.showGenerateServiceReportModel = false;
    }
  }

  fileTypeChange(event: any){
    this.disableSumitButton = false;
  }

  keyupEventForDateRange(event: any){
    this.disableSumitButton = false;
  }
  onPickerClear(datepicker: MatDatepicker<any>) {
    datepicker.select(null);
    datepicker.close();
  }

  generateReport(){
    let request: any = {
      "h_location": this.currentUser.location_code,
      "bit_type": "LOUNGE_ENTRY",
      "date_from": new Date(this.generateServiceReport.controls.start_date.value).toISOString().split('T')[0],
      "date_to": new Date(this.generateServiceReport.controls.end_date.value).toISOString().split('T')[0],
      "file_type": this.generateServiceReport.controls.file_type.value,
      "timezone":Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    this.memberService.generateReports(request)
      .then((data:any) => {
        this.showGenerateServiceReportModel = false;
        const blurEffect = document.getElementById('reports-tab');
        blurEffect.classList.add('blur-effect-background');
        if(data.no_of_bits===0){
          this.showErrorPopup = true;
        }
        else {
          this.showSuccessPopup = true;
        }
        this.generateServiceReport.controls.file_type.setValue('');
        this.generateServiceReport.controls.start_date.setValue('');
        this.generateServiceReport.controls.end_date.setValue('');
      },
        (error: any) => {
          this.showGenerateServiceReportModel = false;
          const blurEffect = document.getElementById('reports-tab');
          blurEffect.classList.add('blur-effect-background');
          this.showErrorPopup = true;
          this.generateServiceReport.controls.file_type.setValue('');
          this.generateServiceReport.controls.start_date.setValue('');
          this.generateServiceReport.controls.end_date.setValue('');
        })
  }
  getReportsData(){
    this.commonService.setShowContentLoaderValue(true);
    this.disableSumitButton = true;
    this.memberService.getAllReports(this.currentUser.location_code,this.todaysDate,this.yesterdaysDate)
      .then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.generatedReportData = data.body;
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.reportsScreenLoading = true;
        for(let i=0;i<this.generatedReportData.length;i++){
          this.generatedReportData[i].file_name = this.generatedReportData[i].file_name.split("_");
          if(this.generatedReportData[i].file_size==null || this.generatedReportData[i].file_size==''){
            this.generatedReportData[i].file_size = 0;
          }
        }
        if(this.generatedReportData.length===0){
          this.dataAvailable = false
          this.paginationActive = false
        }
        else if(this.generatedReportData.length<10){
          this.dataAvailable = true
          this.paginationActive = false
        }
        else {
          this.dataAvailable = true
          this.paginationActive = true
        }
      },
        (error: any) => {
          this.commonService.setShowContentLoaderValue(false);
          this.dataAvailable = false
          this.paginationActive = false
        })
  }
  nextPrev(screen:any){
    this.commonService.setShowContentLoaderValue(true);
    if(screen==='next'){
      this.memberService.getAllReportsNextPrev(this.nextUrl).then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.generatedReportData = data.body
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.reportsScreenLoading = true;
        if(this.generatedReportData.length===0){
          this.dataAvailable = false
        }
        else{
          for(let i=0;i<this.generatedReportData.length;i++){
            this.generatedReportData[i].file_name = this.generatedReportData[i].file_name.split("_");
            if(this.generatedReportData[i].file_size==null || this.generatedReportData[i].file_size==''){
              this.generatedReportData[i].file_size = 0;
            }
          }
          this.dataAvailable = true
        }
      },
        (error: any) => {
          this.commonService.setShowContentLoaderValue(false);
          this.dataAvailable = false
          this.paginationActive = false
        });
    }
    else if(screen==='prev'){
      this.memberService.getAllReportsNextPrev(this.prevUrl).then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.generatedReportData = data.body
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.reportsScreenLoading = true;
        if(this.generatedReportData.length===0){
          this.dataAvailable = false
        }
        else{
          for(let i=0;i<this.generatedReportData.length;i++){
            this.generatedReportData[i].file_name = this.generatedReportData[i].file_name.split("_");
            if(this.generatedReportData[i].file_size==null || this.generatedReportData[i].file_size==''){
              this.generatedReportData[i].file_size = 0;
            }
          }
          this.dataAvailable = true
        }
      },
        (error: any) => {
          this.commonService.setShowContentLoaderValue(false);
          this.dataAvailable = false
          this.paginationActive = false
        });
      }
  }
  closePopup() {
    const blurEffect = document.getElementById('reports-tab');
    blurEffect.classList.remove('blur-effect-background');
    this.showSuccessPopup = false;
    this.showErrorPopup = false;
    this.getReportsData()
  }

}