<div class="version-container">
    <div class="version">
        <div class="heading">WestJet Lounge Portal</div>
        <hr>
        
        <div class="details">
            <p>Build version: {{currentVersion}}</p>
            <p>Build date: {{buildDate | date : 'dd-MM-YYYY'}}</p>
        </div>
    </div>
</div>