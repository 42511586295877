import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NonMemberService {

  constructor() { }
  locationSpecific = [
    {
        "h_location":"YYC01",
        "location_code":"WestJet Elevation Lounge",
        "h_member_id":"634LWVG",
        "h_member_name":"Lounge YYC01"
    },
    {
        "h_location":"YYZ01",
        "location_code":"Plaza Premium Lounge  -Domestic Departures  - Toronto",
        "h_member_id":"FLERO7J",
        "h_member_name":"Lounge YYZ01"
    },
    {
        "h_location":"YEG01",
        "location_code":"Plaza Premium Lounge - Non-US Departures",
        "h_member_id":"8O929HB",
        "h_member_name":"Lounge YEG01"
    },
    {
        "h_location":"YEG02",
        "location_code":"Plaza Premium Lounge Edmonton  -US Departures",
        "h_member_id":"UU7TUBT",
        "h_member_name":"Lounge YEG02"
    },
    {
        "h_location":"YUL01",
        "location_code":"Air France Lounge operated by Plaza Premium Group",
        "h_member_id":"C967719",
        "h_member_name":"Lounge YUL01"
    },
    {
        "h_location":"YOW01",
        "location_code":"Aspire Salon Lounge",
        "h_member_id":"CTUJDD5",
        "h_member_name":"Lounge YOW01"
    },
    {
        "h_location":"YYZ02",
        "location_code":"Plaza Premium Lounge  -US Departures  - Toronto",
        "h_member_id":"RPHKVRK",
        "h_member_name":"Lounge YYZ02"
    },
    {
        "h_location":"YYZ03",
        "location_code":"Plaza Premium Lounge  -International Departures  - Toronto",
        "h_member_id":"I9P9K2Z",
        "h_member_name":"Lounge YYZ03"
    },
    {
        "h_location":"YVR01",
        "location_code":"Plaza Premium Lounge -Domestic Departures, Pier B - Vancouver",
        "h_member_id":"Z5RUZW1",
        "h_member_name":"Lounge YVR01"
    },
    {
        "h_location":"YVR02",
        "location_code":"Plaza Premium Lounge - Domestic Departures, Pier C - Vancouver",
        "h_member_id":"2RCMXM3",
        "h_member_name":"Lounge YVR02"
    },
    {
        "h_location":"YVR03",
        "location_code":"Plaza Premium Lounge - U.S. Departures, Pier E",
        "h_member_id":"UMZBMG2",
        "h_member_name":"Lounge YVR03"
    },
    {
        "h_location":"YVR04",
        "location_code":"Plaza Premium Lounge - International Departures, Pier D",
        "h_member_id":"I8YK7NR",
        "h_member_name":"Lounge YVR04"
    },
    {
        "h_location":"YWG01",
        "location_code":"Plaza Premium Lounge - Domestic Departures - Winnipeg",
        "h_member_id":"TZ13HJM",
        "h_member_name":"Lounge YWG01"
    },
    {
        "h_location":"MBJ01",
        "location_code":"Club MoBay - Jamaica",
        "h_member_id":"JDP3AVI",
        "h_member_name":"Lounge MBJ01"
    },
    {
        "h_location":"NRT01",
        "location_code":"United Airlines United Club - Terminal 1, Central Building - Japan",
        "h_member_id":"5J52ENN",
        "h_member_name":"Lounge NRT01"
    },
    {
        "h_location":"FCO01",
        "location_code":"Plaza Premium Lounge - Schengen Departures Terminal 3 - Italy",
        "h_member_id":"73N3VC5",
        "h_member_name":"Lounge FCO01"
    },
    {
        "h_location":"CDG01",
        "location_code":"Air France Lounge - Hall K - Paris",
        "h_member_id":"RWHNDR7",
        "h_member_name":"Lounge CDG01"
    },
    {
        "h_location":"CDG02",
        "location_code":"Air France Lounge - Hall L - Paris",
        "h_member_id":"HED9NHQ",
        "h_member_name":"Lounge CDG02"
    },
    {
        "h_location":"LGW01",
        "location_code":"No1 Lounge at Gatwick North Terminal",
        "h_member_id":"RMYWSPH",
        "h_member_name":"Lounge LGW01"
    },
    {
        "h_location":"LGW02",
        "location_code":"Clubrooms - Gadwick",
        "h_member_id":"C7FJDF2",
        "h_member_name":"Lounge LGW02"
    },
    {
        "h_location":"LHR01",
        "location_code":"plaza premium - Heathrow - terminal 4",
        "h_member_id":"Z6ZP5MU",
        "h_member_name":"Lounge LHR01"
    },
    {
        "h_location":"YYC02",
        "location_code":"Aspire Lounge Transborder - Concourse E-U.S. Departures terminal",
        "h_member_id":"47NCNHT",
        "h_member_name":"Lounge YYC02"
    },
    {
        "h_location":"YYC03",
        "location_code":"Aspire Lounge International - Concourse D-International Departures terminal",
        "h_member_id":"KTK88B7",
        "h_member_name":"Lounge YYC03"
    }
  ]

  locationMemberPayload(data){
    for(var i=0;i<this.locationSpecific.length;i++){
      if(data===this.locationSpecific[i].h_location){
        return this.locationSpecific[i];
      }
    }
  }

}
