let uatAPIKeyfirst :string  = 'pP9AMzqcwC7scpUzA5';
let uatAPIKeysecond :string  = 'KuG6i8jxgTrNeq3vLY9R0I';
export const environment = {
	production: true,
	version: '0.0.0',
	x_api_key: uatAPIKeyfirst + uatAPIKeysecond,
	ocp_pim_subscription_key: 'f2ba4dce0cb04f1b9b9766254a6502f2',
	programID: 68,
	sponsorID: 106,
	category: 4,
	tierClassCode:'General',
	gisEndpoint:'/wj',
	serviceRequestPriorityCode: 6,
	westjetEndPoint:'https://dev-apigw.westjet.com/loyalty/v1/lounges/westjet/',
	apiendpoint:{
		url:'https://api.cac20.gravty.io',
		version1:'/v1',
		version2:'/v2',
		version3:'/v3'
	},
	gravtyendpoint:{
		url:'https://westjettestnew.cac20.gravty.io',
		version1:'/v1',
		version2:'/v2',
		endpoint:'/bolapi'
	},
	gravtyhost: function(){
		return this.apiendpoint.url + this.gravtyendpoint.version1;
	},
	host : function () {
		return this.apiendpoint.url + this.apiendpoint.version1;
	},
	host2 : function () {
		return this.apiendpoint.url + this.apiendpoint.version2;
	},
	host3 : function () {
		return this.apiendpoint.url + this.apiendpoint.version3;
	},
	versionCheckURL : '/auto-reload/version.json',
};
