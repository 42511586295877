<div class="login-page">
  <div class="login-container">
    <div class="login-banner">
      Welcome to Lounge Portal
    </div>
    <form class="login-form" name="loginForm" [formGroup]="loginForm" (keyup.enter)="login()">
      <mat-form-field class="login-form-input" [hideRequiredMarker]="true"
      [ngStyle]="{'height': (errorDisplay === true ? '80px' : 'auto')}" >
        <mat-label>User ID</mat-label>
        <input formControlName="username" matInput value="">
        <mat-error *ngIf="loginForm.get('username').errors?.required">
          User ID Required
        </mat-error>
      </mat-form-field>
      <mat-form-field class="login-form-input" [hideRequiredMarker]="true" (keyup) = "keyupEvent()"
      [ngStyle]="{'height': (errorDisplay === true ? '80px' : 'auto')}" >
        <mat-label class="login-form-input-label">Password</mat-label>
        <input formControlName="password" matInput type="password" value="">
        <mat-error *ngIf="loginForm.get('password').errors?.required">
          Password Required
        </mat-error>
        <mat-error *ngIf="loginForm.get('password').errors?.serverError">
          The user ID or password that you entered is incorrect.
          Please check and try again.
        </mat-error>
      </mat-form-field>
    </form>
    <div class="submit-form">
      <mat-checkbox class="remember-me" [checked]="rememberme" (change)="rememberme = !rememberme">Remember me</mat-checkbox>
      <button mat-raised-button class="login-button" (click)="login();" [disabled]="loginForm.invalid" [ngStyle]="{'cursor': (loginForm.invalid? 'not-allowed':'pointer')}" (key.enter)="login()">SIGN IN</button>
    </div>
  </div>
  <div class="login-tnc">
    WestJet©. All rights reserved.
    <a href="/tnc">Terms of Use</a>.
  </div>
</div>
