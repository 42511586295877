import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "./../environments/environment";
import { User } from '../models';
import { Router, ActivatedRoute } from '@angular/router';
import { VersionCheckService } from '../services/version-check.service';
import { CookieService } from 'ngx-cookie-service';
import { WebService } from './web.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    currentLang: string = 'es-MX';
    allslugs: any;
    allHeaderSlugName: any;

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: BehaviorSubject<User>;
    constructor(private http: HttpClient, private router: Router,
        private route: ActivatedRoute,
        private versionCheckService: VersionCheckService,
        private cookieService: CookieService,
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
        this.currentUser = this.currentUserSubject;

    }

    private host = environment.gravtyhost();

    public get currentUserValue(): User {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
        return this.currentUserSubject.value;
    }

    login(request: any) {
        return this.http.post<any>(this.host + "/login/", request)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && (user.token || user['Two-FA-Token'])) {
                    user.login_time = new Date().getTime();
                    user.tokenExpireTime = user.login_time + (Number(user.expires_in) - 600) * 1000;
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    user.header = true;
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('for_2fa_only', user["Two-FA-Token"]);
                    this.currentUserSubject.next(user);
                    this.currentUser.next(user);

                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        this.cookieService.deleteAll();
        localStorage.removeItem('currentUser');
        localStorage.clear();

        this.currentUserSubject.next(null || {});
        this.currentUser.next(null || {});
        this.versionCheckService.initVersionCheck(environment.versionCheckURL);

    }
    updateCurrentUserObs(user: any) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }

}
