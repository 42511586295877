<div class="twofa-otp-container">
    <div class="left-container">
        <div class="twofa-logo">
            <img src="../../../assets/image/2fa-icon.png" alt="" height="159px" width="133px">
        </div>
        <div class="twofa-header">
            Two-Factor Authentication
        </div>
        <div class="twofa-desc">
            Two-factor authentication (2FA) adds an extra layer of security to your account by requiring two different forms of identification before you can log in.
            <br><br>
            With 2FA enabled, your account is inaccessible without entering a security code generated by the 2FA app. This makes it much harder for unauthorized users to gain entry, protecting sensitive information and personal data.
            <br><br>
            Two-factor authentification will become mandatory soon so please complete the setup and activate 2FA in order to ensure uninterrupted service.
        </div>
    </div>
    <div class="right-container">
        <app-email-otp-section *ngIf="screenNumber=== 0" (qrItemEvent)="getQRdata($event)"></app-email-otp-section>
        <app-qr-scan-section *ngIf="screenNumber=== 1" [qrDetails]="qrDetails"></app-qr-scan-section>
        <app-twofa-totp-section *ngIf="screenNumber=== 2"></app-twofa-totp-section>
    </div>
</div>
