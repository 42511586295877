import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/services/common.service';
import { MemberService } from 'src/services/member.service';

@Component({
  selector: 'app-guest-entries',
  templateUrl: './guest-entries.component.html',
  styleUrls: ['./guest-entries.component.less']
})
export class GuestEntriesComponent implements OnInit {
  guestEntriesForm: any = FormGroup;
  guestEntriesList:any;
  guestScreenLoading:boolean = false;
  paginationActive = false;
  dataAvailable = false;
  showPnr: boolean = true;
  showTravelerName: boolean = false;
  // disableSaveButton: boolean = true;
  todaysDate:any = '';
  yesterdaysDate:any = '';

  nextUrl:any = 'None';
  prevUrl:any = 'None';

  pipe = new DatePipe('en-US');

  constructor(
    private formbuilder: FormBuilder,
    private memberService: MemberService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.guestEntriesForm = this.formbuilder.group({
      Search_Formate_List: ['pnr'],
      searchId: [''],
      travelerName: ['']
    })
    this.todaysDate = new Date();
    this.todaysDate = this.pipe.transform(this.todaysDate,"yyyy-MM-dd")
    this.yesterdaysDate = new Date();
    this.yesterdaysDate = this.yesterdaysDate.setDate(this.yesterdaysDate.getDate()-1)
    this.yesterdaysDate = this.pipe.transform(this.yesterdaysDate ,"yyyy-MM-dd")
    this.guestEntriesData("")
  }

  // keyupEvent(event: any) {
  //   let pnrNumber = this.guestEntriesForm.controls.searchId.value;
  //   let travelerName = this.guestEntriesForm.controls.travelerName.value;
  //   if (this.guestEntriesForm.controls.Search_Formate_List.value === 'pnr') {
  //     if (pnrNumber === "") {
  //       this.disableSaveButton = true;
  //     }
  //     else{
  //       this.disableSaveButton = false;
  //     }
  //   }
  //   else if(this.guestEntriesForm.controls.Search_Formate_List.value === 'name'){
  //     if (travelerName === "") {
  //       this.disableSaveButton = true;
  //     }
  //     else{
  //       this.disableSaveButton = false;
  //     }
  //   }
  // }

  guestEntriesData(param){
    this.commonService.setShowContentLoaderValue(true);
    if(param===""){
      this.memberService.getGuestEntries("",this.todaysDate,this.yesterdaysDate).then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.guestEntriesList = data.body
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.guestScreenLoading = true;
        if(this.guestEntriesList.length===0){
          this.dataAvailable = false
          this.paginationActive = false
        }
        else if(this.guestEntriesList.length<10){
          this.dataAvailable = true
          this.paginationActive = false
        }
        else {
          this.dataAvailable = true
          this.paginationActive = true
        }
      },
      (error: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.dataAvailable = false
        this.paginationActive = false
      });
    }
    else if(param==="search"){
      let searchCriteria = ''
      if(this.guestEntriesForm.controls.Search_Formate_List.value === 'pnr'){
        searchCriteria="&h_pnr_number="+this.guestEntriesForm.controls.searchId.value
      }
      else{
        searchCriteria="&h_member_name="+this.guestEntriesForm.controls.travelerName.value
      }
      this.memberService.getGuestEntries(searchCriteria,this.todaysDate,this.yesterdaysDate).then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.guestEntriesList = data.body
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.guestScreenLoading = true;
        if(this.guestEntriesList.length===0){
          this.dataAvailable = false
          this.paginationActive = false
        }
        else if(this.guestEntriesList.length<10){
          this.dataAvailable = true
          this.paginationActive = false
        }
        else {
          this.dataAvailable = true
          this.paginationActive = true
        }
      },
      (error: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.dataAvailable = false
        this.paginationActive = false
      });
    }
  }
  nextPrev(screen:any){
    this.commonService.setShowContentLoaderValue(true);
    if(screen==='next'){
      this.memberService.getGuestEntriesNextPrev(this.nextUrl).then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.guestEntriesList = data.body
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.guestScreenLoading = true;
        if(this.guestEntriesList.length===0){
          this.dataAvailable = false
        }
        else{
          this.dataAvailable = true
        }
      },
        (error: any) => {
          this.commonService.setShowContentLoaderValue(false);
          this.dataAvailable = false
          this.paginationActive = false
        });
    }
    else if(screen==='prev'){
      this.memberService.getGuestEntriesNextPrev(this.prevUrl).then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.guestEntriesList = data.body
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.guestScreenLoading = true;
        if(this.guestEntriesList.length===0){
          this.dataAvailable = false
        }
        else{
          this.dataAvailable = true
        }
      },
        (error: any) => {
          this.commonService.setShowContentLoaderValue(false);
          this.dataAvailable = false
          this.paginationActive = false
        });
      }
  }

  searchFormateListChange(event: any) {
    let value = this.guestEntriesForm.controls.Search_Formate_List.value;
    if (value === 'pnr') {
      this.showPnr = true;
      this.showTravelerName = false;
      // this.disableSaveButton = true;
      this.guestEntriesForm.controls.travelerName.setValue('');
    }
    else if (value === 'name') {
      this.showPnr = false;
      this.showTravelerName = true;
      // this.disableSaveButton = true;
      this.guestEntriesForm.controls.searchId.setValue('');
    }
  }

}
