import { Component, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { QrScanSectionComponent } from '../qr-scan-section/qr-scan-section.component';
import { TwofaSuccessPopupComponent } from '../../twofa-success-popup/twofa-success-popup.component';
import { TwofaSetupContainerComponent } from '../twofa-setup-container.component';
import { TwofaService } from '../../twofa.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/services/authentication.service';
import { MemberService } from 'src/services/member.service';

@Component({
  selector: 'app-twofa-totp-section',
  templateUrl: './twofa-totp-section.component.html',
  styleUrls: ['./twofa-totp-section.component.less']
})
export class TwofaTotpSectionComponent implements OnInit {

  form: FormGroup;
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  @ViewChildren('formRow') rows: any;
  isShowLogoutReason = false;
  screenNumber:any;
  currentUser: any;
  tenantDetails: any;
  errorMsg: any;
  showError = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TwofaSetupContainerComponent>,
    private dialog: MatDialog,
    private twofaService: TwofaService,
    private authenticationService:AuthenticationService,
    private router:Router,
    private memberService: MemberService,
  ) {
    this.form = this.toFormGroup(this.formInput);
    dialogRef.disableClose = true;
    this.twofaService._twofaSetupScreen.subscribe( ele=>{
      this.screenNumber =ele;
   })
  }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;
    this.tenantDetails = localStorage.getItem('tenantInfo');
    this.tenantDetails = JSON.parse(this.tenantDetails);
    if(this.tenantDetails['2fa_status'] == 'OPTIONAL' || this.tenantDetails['2fa_status'] == 'MANDATORY') {
      this.isShowLogoutReason = true;
    } else {
      this.isShowLogoutReason = false;
    }
  }

  toFormGroup(elements: any) {
    const group: any = {};

    elements.forEach((key: any) => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event: any, index: any) {
    this.showError = false;
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1 ;
    } else {
      pos = index + 1 ;
    }
    if (pos > -1 && pos < this.formInput.length ) {
      this.rows._results[pos].nativeElement.focus();
    }

  }

  onSubmit() {
    if (this.form.valid) {
      this.showError = false;
      const otpValue = Object.values(this.form.value).join('');
      var req = {
        otp: otpValue
      }
      this.twofaService.verifyFinalTOTP(req,this.currentUser.id).then((data: any) => {
        localStorage.setItem('for_2fa_only',data["Two-FA-Token"]);

        const dialogRef = this.dialog.open(TwofaSuccessPopupComponent, {
          width: '340px',
          panelClass: 'custom-dialog-container',
          backdropClass: 'backdropBackground',
          position: { top: '200px' }
        });
    
        dialogRef.afterClosed().subscribe(result => {     
        });

        this.dialogRef.close();
        // if(this.tenantDetails['2fa_status'] == 'OPTIONAL' || this.tenantDetails['2fa_status'] == 'MANDATORY') {
        //   this.authenticationService.logout();
        // } else {
        //   this.memberService.logOut();
        //   this.authenticationService.logout();
        //   this.router.navigate(['login']);
        // }
      }).catch((error: any) => { 
        this.showError = true;
        this.errorMsg = error.error.message;
      });
    } else {

    }
  }

  closePopup() {
    this.showError = false;
    this.form.reset();
    this.screenNumber--;
    this.twofaService.updateScreen(this.screenNumber);
  }

}
