import { Component, OnInit } from '@angular/core';
import { TwofaService } from '../twofa.service';

@Component({
  selector: 'app-twofa-setup-container',
  templateUrl: './twofa-setup-container.component.html',
  styleUrls: ['./twofa-setup-container.component.less']
})
export class TwofaSetupContainerComponent implements OnInit {

  screenNumber: any ;
  qrDetails: any;

  constructor(
    private twofaService: TwofaService
    ) {
    this.twofaService._twofaSetupScreen.subscribe( ele=> {
      this.screenNumber = ele;
    })
   }

  ngOnInit(): void {

  }

  getQRdata(event: any) {
    this.qrDetails = event;
    console.log(" this.qrDetails", this.qrDetails);
    }

}
