import { Directive, HostListener, ElementRef  } from '@angular/core';

@Directive({
  selector: '[appNospace]'
})
export class NospaceDirective {

  constructor(private el: ElementRef) { }
  @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
    const inputText = this.el.nativeElement.value;
    this.el.nativeElement.value = this.removeSpaces(inputText);
  }

  private removeSpaces(input: string): string {
    // Replace spaces with empty string
    return input.replace(/\s/g, '');
  }

}
