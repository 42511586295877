<div class="header-container">
  <div class="nav-bar">
    <div class="logo-container">
      <a href="/login">
        <img class="header-logo" src="../../assets/image/WestJet_logo.png" alt="logo">
      </a>
    </div>
    <div class="tnc-faq-heading" *ngIf="tncFlag || faqFlag || versionFlag">
      <div class="tnc-nav" *ngIf="tncFlag">
        <p class="tnc-text">Terms of Use</p>
      </div>
      <div class="faq-nav" *ngIf="faqFlag">
        <p class="faq-text">Frequently Asked Questions</p>
      </div>
    </div>
    <div class="close-button-container" *ngIf="tncFlag || faqFlag">
      <a href="/login">
        <img class="close-icon" src="../../assets/image/close@3x.png" alt="logo">
      </a>
    </div>
    <div class="nav-bar-menu" *ngIf="loginFlag && !(tncFlag || faqFlag || versionFlag)">
      <!-- <mat-tab-group>
        <mat-tab label ="Lounge Access"></mat-tab>
        <mat-tab label ="Service Request"></mat-tab>
        <mat-tab label ="Self-Service"></mat-tab>
        <mat-tab label ="Guest Entries"></mat-tab>
        <mat-tab label ="Reports"></mat-tab>
      </mat-tab-group> -->
      <nav mat-tab-nav-bar>
        <a *ngFor="let tab of tabs" mat-tab-link [routerLink]="tab.link" routerLinkActive #rla="routerLinkActive"
          [active]="rla.isActive">
          {{ tab.label }}
        </a>
      </nav>
    </div>

    <div class="nav-bar-button-container" *ngIf="loginFlag && !(tncFlag || faqFlag)">
      <a href="/faq">
        <img class="header-sub-icons" src="../../assets/image/help_button.png" alt="logo">
      </a>
      <div class="name-initial">
        <a>{{this.nameInitials}}</a>
      </div>
      <a (click)="logout();">
        <img class="header-sub-icons" src="../../assets/image/logout_button.png" alt="logo">
      </a>
    </div>
  </div>
</div>