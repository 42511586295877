import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/services/common.service';
import { MemberService } from 'src/services/member.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { AuthenticationService } from 'src/services/authentication.service';
import { BarcodeServiceService } from 'src/services/barcode-service.service';
import { NonMemberService } from 'src/services/non-member.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone'

@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.less']
})
export class MemberDetailsComponent implements OnInit {

  memberDetailsForm: any = FormGroup;
  nonMemberDetailsForm: any = FormGroup;
  showRewardIDField: boolean = true;
  showClearButton: boolean = false;
  disableSaveButton: boolean = true;
  showDataTable: boolean = false;
  showMoreRecordsErrorMessage: boolean = false;
  showNoResultsErrorMessage: boolean = false;
  showLessThanDigits: boolean = false;
  showTableHeaders: boolean = false;
  totalMemberList: any;
  screenDisable: boolean = false;
  showMemberScreen: boolean = true;
  showNonMemberScreen: boolean = false;
  showNumberOfPassengers: boolean = false;
  showVoucherNumber: boolean = false;
  // showEmptyText: boolean = false;
  showDlMemberFields: boolean = false;
  showNonMemberFields: boolean = true;
  showMemberDetails: boolean = false;
  disableSubmitButton: boolean = true;
  disableNonMemberSubmitButton: boolean = true;
  request: any = {}
  dataToMemberProfile: any = {}
  scannedDataToMemberProfile: any = {};
  scannedDataToMemberProfileArray: any = [];
  isDisabled: boolean = true;
  numberOfPassengers: any = [];
  numberOfPassengersCount: any = 6;
  currentUser: any;
  memberTypes: any = [];
  travelerTypes: any = [];
  flightTypes: any = [];
  barcodeData = "";
  myTimeout: any;
  retrivedUserData: any = '';
  tabSelectedValue: number = 0;
  showSuccessPopup = false;
  showErrorPopup = false;
  hideDlMemberFields: boolean = false;
  originAirportText: any;
  designationAirportText: any;
  getMemberComplimentaryVoucher: any;
  errorMessageToBeDisplayed: any = "This access request was denied."
  todaysDate:any = '';
  yesterdaysDate:any = '';
  dlOverride = 1;
  tierClassCodeID = environment.tierClassCode

  pipe = new DatePipe('en-US');

  constructor(
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private memberService: MemberService,
    private authenticationService: AuthenticationService,
    private barcodeService: BarcodeServiceService,
    private nonMemberService: NonMemberService
  ) {

  }

  @HostListener('document:keypress', ['$event'])

  onKeyPress(event: KeyboardEvent): void {
    this.barcodeData += event.key;
    this.resetBarcodeDataAfterDelay();
  }

  ngOnInit(): void {
    this.barcodeData = "";
    this.scannedDataToMemberProfile = {};
    this.commonService.scrollTop();
    this.memberDetailsForm = this.formbuilder.group({
      Search_Formate_List: ['RID'],
      First_Name: [''],
      Last_Name: [''],
      Phone_Number: [''],
      reward_ID: ['']
    });

    this.nonMemberDetailsForm = this.formbuilder.group({
      member_type: ['Non-Member'],
      traveler_type: [''],
      flight_number: [''],
      flight_type: ['WS'],
      pnr_number: [''],
      voucher_number: [''],
      number_of_passengers: [''],
      ticket_number: [''],
      first_name: [''],
      last_name: [''],
      origin: [''],
      destination: [''],
      operating_carrrier: [''],
      fqtv_number: [''],
      travel_date: [''],
      number_of_guests: ['0'],
      cabin_class: [''],
      denial_message: [''],
      override_reason: ['at-valid-outbound-airport'],
      traveler_dl_type: ['dlmember']
    });
    this.numberOfPassengers = Array.apply(null, { length: this.numberOfPassengersCount + 1 }).map(Number.call, Number);
    this.numberOfPassengers = [1,2,3,4,5,6]
    this.currentUser = this.authenticationService.currentUserValue;
    if ((this.currentUser.role.code === "LOUNGE_ADMIN" && this.currentUser.location_code === "YYC01") 
    || (this.currentUser.role.code === "LOUNGE_AGENT" && this.currentUser.location_code === "YYC01") 
    || (this.currentUser.role.code === "PROGRAM_MANAGER")) {
      this.memberTypes = [
        { value: 'DL', labelName: 'DL' }, 
        { value: 'Non-Member', labelName: 'Non-Member' }
      ]
      this.travelerTypes = [
        { value: 'business-class', labelName: 'Business Class' }, 
        { value: 'complimentary-voucher', labelName: 'Complimentary Voucher' },
        { value: 'walk-up', labelName: 'Walk-up' }, 
        { value: 'priority-pass', labelName: 'Priority Pass' }, 
        { value: 'dragon-pass', labelName: 'Dragon Pass' }
      ]
      this.flightTypes = [
        { value: 'WS', labelName: 'WS'},
        { value: 'DL', labelName: 'DL'},
        { value: 'KL', labelName: 'KL'},
        { value: 'OTHER', labelName: 'Other'}
      ]
    }
    else {
      this.memberTypes = [
        { value: 'Non-Member', labelName: 'Non-Member' }
      ]
      this.travelerTypes = [
        { value: 'business-class', labelName: 'Business Class' }, 
        { value: 'complimentary-voucher', labelName: 'Complimentary Voucher' }
      ]
      this.flightTypes = [
        { value: 'WS', labelName: 'WS'},
        { value: 'DL', labelName: 'DL'},
        { value: 'KL', labelName: 'KL'}
      ]
    }
    this.tabSelectedValue = 0;
    this.showSuccessPopup = false;
    this.showErrorPopup = false;
    // this.barcodeDataVerify("M1BONK/JONATHAN       ESHFOWD YYCCUNWS 2310 080J002A0003 14A>50B1WW4079BWS 2A838218346427901WS DL 2270346725          YENWSY|SHFOWD001^460MEYCIQDbgOSarJb6TJcC7/k/oBefX8E2VL8ZHcACSzzBhdgGUAIhAIM/1fz9oa+Tswn2sNLPDebpUO1N+kq/gxip9mfDDVJj")

  }

  selectedTabValueChange(event: any) {
    let selectedTabValue = event.tab.textLabel;
    if (selectedTabValue === "Members") {
      this.tabSelectedValue = 0;
      this.showMemberScreen = true;
      this.showNonMemberScreen = false;
      this.clearMemberDetails('');
    }
    else if (selectedTabValue === "Non-Members") {
      this.tabSelectedValue = 1;
      this.showNonMemberScreen = true;
      this.showMemberScreen = false;
      this.memberTypeChange('Non-Member');
      this.showNumberOfPassengers = false;
      this.showVoucherNumber = false;
      // this.showEmptyText = true;
    }
  }

  memberTypeChange(event: any) {
    let memberType = this.nonMemberDetailsForm.controls.member_type.value;
    if (memberType === "DL") {
      this.showDlMemberFields = true;
      this.showNonMemberFields = false;
      this.nonMemberDetailsForm.reset();
      this.nonMemberDetailsForm.controls.member_type.setValue('DL');
      this.nonMemberDetailsForm.controls.number_of_guests.setValue('0');
      this.nonMemberDetailsForm.controls.flight_type.setValue('WS');
      this.nonMemberDetailsForm.controls.traveler_dl_type.setValue('dlmember');
      this.disableSubmitButton = true;
    }
    else if (memberType === "Non-Member" || event === "Non-Member") {
      this.showNonMemberFields = true;
      this.showDlMemberFields = false;
      this.nonMemberDetailsForm.reset();
      this.nonMemberDetailsForm.controls.member_type.setValue('Non-Member');
      this.nonMemberDetailsForm.controls.flight_type.setValue('WS');
      this.disableNonMemberSubmitButton = true;
    }
  }

  travelerTypeChange() {
    let travelerType = this.nonMemberDetailsForm.controls.traveler_type.value;
    if (travelerType === "dragon-pass" || travelerType === "priority-pass") {
      this.showNumberOfPassengers = true;
      this.showVoucherNumber = false;
      // this.showEmptyText = false;
      this.nonMemberDetailsForm.controls.number_of_passengers.setValue('');
    }
    else if (travelerType === "complimentary-voucher") {
      this.showVoucherNumber = true;
      this.showNumberOfPassengers = false;
      // this.showEmptyText = false;
      this.nonMemberDetailsForm.controls.voucher_number.setValue('');
    }
    else {
      this.showNumberOfPassengers = false;
      this.showVoucherNumber = false;
      // this.showEmptyText = true;
    }
    this.keyupEventForNonMember('');
  }

  searchFormateListChange(event: any) {
    this.showClearButton = false;
    this.showDataTable = false;
    this.showMoreRecordsErrorMessage = false;
    this.showNoResultsErrorMessage = false;
    this.showLessThanDigits = false;
    this.showTableHeaders = false;
    this.scannedDataToMemberProfile = {};
    let value = this.memberDetailsForm.controls.Search_Formate_List.value;
    if (value == 'GN') {
      this.showMemberDetails = false;
      this.showRewardIDField = false;
      this.memberDetailsForm.controls.reward_ID.setValue('');
      this.disableSaveButton = true;
    }
    else if (value == 'RID') {
      this.showMemberDetails = false;
      this.showRewardIDField = true;
      this.memberDetailsForm.controls.First_Name.setValue('');
      this.memberDetailsForm.controls.Last_Name.setValue('');
      this.memberDetailsForm.controls.Phone_Number.setValue('');
      this.disableSaveButton = true;
    }
  }

  keyupEvent(event: any) {
    let firstName = this.memberDetailsForm.controls.First_Name.value;
    let lastName = this.memberDetailsForm.controls.Last_Name.value;
    let phoneNumber = this.memberDetailsForm.controls.Phone_Number.value;
    let rewardId = this.memberDetailsForm.controls.reward_ID.value;
    if (this.memberDetailsForm.controls.Search_Formate_List.value == 'GN') {
      if (firstName == "" && lastName == "" && (phoneNumber == null || phoneNumber == '')) {
        this.showClearButton = false;
        this.disableSaveButton = true;
        this.showDataTable = false;
        this.showMoreRecordsErrorMessage = false;
        this.showNoResultsErrorMessage = false;
        this.showLessThanDigits = false;
        this.showTableHeaders = false;
      }
      else {
        this.showClearButton = true;
        if (firstName == "" || lastName == "") {
          this.disableSaveButton = true;
        }
        else {
          this.disableSaveButton = false;
        }

      }
    }
    else {
      if (rewardId == "") {
        this.showClearButton = false;
        this.disableSaveButton = true;
        this.showDataTable = false;
        this.showMoreRecordsErrorMessage = false;
        this.showNoResultsErrorMessage = false;
        this.showLessThanDigits = false;
        this.showTableHeaders = false;
      }
      else {
        this.showClearButton = true;
        this.disableSaveButton = false;
      }
    }
  }

  keyupEventForNonMember(event: any) {
    let memberType = this.nonMemberDetailsForm.controls.member_type.value;
    let travelerType = this.nonMemberDetailsForm.controls.traveler_type.value;
    let flightType = this.nonMemberDetailsForm.controls.flight_type.value;
    let flightNumber = this.nonMemberDetailsForm.controls.flight_number.value;
    let pnrNumber = this.nonMemberDetailsForm.controls.pnr_number.value;
    let voucherNumber = this.nonMemberDetailsForm.controls.voucher_number.value;
    let numberOfPassengers = this.nonMemberDetailsForm.controls.number_of_passengers.value;
    if (travelerType === "business-class" || travelerType === "walk-up") {
      if (memberType == "" || flightType == "" || (flightNumber == "" || flightNumber === null) 
      || (pnrNumber == "" || pnrNumber == null)) {
        this.disableNonMemberSubmitButton = true;
      }
      else {
        this.disableNonMemberSubmitButton = false;
      }
    }
    else if (travelerType === "complimentary-voucher") {
      if (memberType == "" || flightType == "" || (flightNumber == "" || flightNumber === null) 
      || (pnrNumber == "" || pnrNumber == null) || (voucherNumber == "" || voucherNumber == null)) {
        this.disableNonMemberSubmitButton = true;
      }
      else {
        this.disableNonMemberSubmitButton = false;
      }
    }
    else if (travelerType === "priority-pass" || travelerType === "dragon-pass") {
      if (memberType == "" || flightType == "" || (flightNumber == "" || flightNumber === null) 
      || (pnrNumber == "" || pnrNumber == null) || numberOfPassengers == "") {
        this.disableNonMemberSubmitButton = true;
      }
      else {
        this.disableNonMemberSubmitButton = false;
      }
    }
  }

  keyupEventForDLMember(event: any) {
    this.dlOverride = 1;
    let flightType = this.nonMemberDetailsForm.controls.flight_type.value;
    let flightNumber = this.nonMemberDetailsForm.controls.flight_number.value;
    let pnrNumber = this.nonMemberDetailsForm.controls.pnr_number.value;
    let ticketNumber = this.nonMemberDetailsForm.controls.ticket_number.value;
    let firstName = this.nonMemberDetailsForm.controls.first_name.value;
    let lastName = this.nonMemberDetailsForm.controls.last_name.value;
    let origin = this.nonMemberDetailsForm.controls.origin.value;
    let destination = this.nonMemberDetailsForm.controls.destination.value;
    let operatingCarrier = this.nonMemberDetailsForm.controls.operating_carrrier.value;
    let fqtvNumber = this.nonMemberDetailsForm.controls.fqtv_number.value;
    let travelDate = this.nonMemberDetailsForm.controls.travel_date.value;
    let numberOfGuests = this.nonMemberDetailsForm.controls.number_of_guests.value;
    let cabinClass = this.nonMemberDetailsForm.controls.cabin_class.value;
    let travelerDlType = this.nonMemberDetailsForm.controls.traveler_dl_type.value;
    let denialMessage = this.nonMemberDetailsForm.controls.denial_message.value;
    let overrideReason = this.nonMemberDetailsForm.controls.override_reason.value;
    if (flightType == "" || (flightNumber == "" || flightNumber == null) 
    || (pnrNumber == "" || pnrNumber == null) || (ticketNumber == "" || ticketNumber == null) 
    || (firstName == "" || firstName == null) || (lastName == "" || lastName == null) 
    || (origin == "" || origin == null) || (destination == "" || destination == null) 
    || (fqtvNumber == "" || fqtvNumber == null) || travelDate == "" || numberOfGuests == "" || travelerDlType == "" || cabinClass == "") {
      this.disableSubmitButton = true;
    }
    else {
      this.disableSubmitButton = false;
    }
  }

  numberOfPassengersChange(event: any) {
    this.keyupEventForNonMember('');
  }

  searchMember(memberDetailsForm: any,type: any) {
    this.commonService.setShowContentLoaderValue(true);
    if(memberDetailsForm.value.Search_Formate_List == "RID" && 
    (memberDetailsForm.value.reward_ID.length > 9 
    || memberDetailsForm.value.reward_ID.length < 7)){
      this.commonService.setShowContentLoaderValue(false);
      this.showLessThanDigits = true;
      this.showMoreRecordsErrorMessage = false;
      this.showNoResultsErrorMessage = false;
      this.showTableHeaders = true;
      this.showDataTable = false;
      this.showMemberDetails = false;
    }
    else{
      if (memberDetailsForm.value.Search_Formate_List == "GN" && memberDetailsForm.value.First_Name != "" 
      && memberDetailsForm.value.Last_Name != "" && memberDetailsForm.value.Phone_Number == "") {
        this.request = "?first_name=" + memberDetailsForm.value.First_Name + "&last_name=" + memberDetailsForm.value.Last_Name
      } else if (memberDetailsForm.value.Search_Formate_List == "GN" && memberDetailsForm.value.First_Name != "" 
      && memberDetailsForm.value.Last_Name != "" && memberDetailsForm.value.Phone_Number != "") {
        this.request = "?first_name=" + memberDetailsForm.value.First_Name + "&last_name=" + memberDetailsForm.value.Last_Name + "&mobile=" + encodeURIComponent(memberDetailsForm.value.Phone_Number)
      } else if ((memberDetailsForm.value.Search_Formate_List == "RID" || type === "scanned") && memberDetailsForm.value.reward_ID != "") {
        this.request = "?member_id=" + memberDetailsForm.value.reward_ID
      }
      else {
        this.request = ''
      }
      if (this.request.length != 0 && this.request.length != undefined) {
        this.showMemberDetails = false;
        this.memberService.getAllMember(this.request).then((data: any) => {
          this.commonService.setShowContentLoaderValue(false);
          this.totalMemberList = data;
          if (this.totalMemberList.length > 50) {
            this.showTableHeaders = true;
            this.showMoreRecordsErrorMessage = true;
            this.showDataTable = false;
            this.showNoResultsErrorMessage = false;
            this.showLessThanDigits = false;
            this.showMemberDetails = false;
          }
          else if (this.totalMemberList.length === 0) {
            this.showTableHeaders = true;
            this.showNoResultsErrorMessage = true;
            this.showDataTable = false;
            this.showMoreRecordsErrorMessage = false;
            this.showLessThanDigits = false;
            this.showMemberDetails = false;
          }
          else if (this.totalMemberList.length === 1) {
            this.memberProfileOpenState(this.totalMemberList[0],type)
          }
          else {
            this.showMemberDetails = false;
            this.showTableHeaders = true;
            this.showDataTable = true;
            this.showMoreRecordsErrorMessage = false;
            this.showNoResultsErrorMessage = false;
            this.showLessThanDigits = false;
          }
        },
          (error: any) => {
            this.commonService.setShowContentLoaderValue(false);
            this.showLessThanDigits = true;
            this.showMoreRecordsErrorMessage = false;
            this.showNoResultsErrorMessage = false;
            this.showTableHeaders = true;
            this.showDataTable = false;
            this.showMemberDetails = false;
          });
      }
    }
  }

  clearMemberDetails(memberDetailsForm: any) {
    this.memberDetailsForm.controls.First_Name.setValue('');
    this.memberDetailsForm.controls.Last_Name.setValue('');
    this.memberDetailsForm.controls.Phone_Number.setValue('');
    this.memberDetailsForm.controls.reward_ID.setValue('');
    this.showClearButton = false;
    this.disableSaveButton = true;
    this.showDataTable = false;
    this.showMoreRecordsErrorMessage = false;
    this.showNoResultsErrorMessage = false;
    this.showLessThanDigits = false;
    this.showTableHeaders = false;
    this.showMemberDetails = false;
    this.scannedDataToMemberProfile = {};
  }

  memberProfileOpenState(details: any, type: any) {
    if (details === true) {
      this.showMemberDetails = false;
      this.scannedDataToMemberProfile = {};
    }
    else if(details==="showSuccess"){
      this.showMemberDetails = false;
      this.scannedDataToMemberProfile = {};
      const blurEffect = document.getElementById('member-details-page');
      blurEffect.classList.add('blur-effect-background');
      this.clearMemberDetails('')
      this.showSuccessPopup = true;
      this.scannedDataToMemberProfileArray = [];
    }
    else if(details==="showError"){
      this.errorMessageToBeDisplayed = "This access request was denied."
      this.showMemberDetails = false;
      this.scannedDataToMemberProfile = {};
      const blurEffect = document.getElementById('member-details-page');
      blurEffect.classList.add('blur-effect-background');
      this.clearMemberDetails('')
      this.showErrorPopup = true;
      this.scannedDataToMemberProfileArray = [];
    }
    else if(details==="showVoucherError"){
      this.errorMessageToBeDisplayed = "This voucher has already been used or has expired."
      this.showMemberDetails = false;
      this.scannedDataToMemberProfile = {};
      const blurEffect = document.getElementById('member-details-page');
      blurEffect.classList.add('blur-effect-background');
      this.clearMemberDetails('')
      this.showErrorPopup = true;
      this.scannedDataToMemberProfileArray = [];
    }
    else if(details==="showInvalidVoucherError"){
      this.errorMessageToBeDisplayed = "Please check the Complimentary Voucher Number and try again."
      this.showMemberDetails = false;
      this.scannedDataToMemberProfile = {};
      const blurEffect = document.getElementById('member-details-page');
      blurEffect.classList.add('blur-effect-background');
      this.clearMemberDetails('')
      this.showErrorPopup = true;
      this.scannedDataToMemberProfileArray = [];
    }
    else {
      if(type==='nonScanned'){
        this.scannedDataToMemberProfile = {};
      }
      this.showMemberDetails = true;
      this.dataToMemberProfile = details;
    }
  }

  popupState(currentState: any) {
    const blurEffect = document.getElementById('member-details-page');
    if (currentState === 'open') {
      blurEffect.classList.add('blur-effect-background');
    }
    else if (currentState === 'close') {
      blurEffect.classList.remove('blur-effect-background');
    }
  }

  onPickerClear(datepicker: MatDatepicker<any>) {
    datepicker.select(null);
    datepicker.close();
    const blurEffect = document.getElementById('member-details-page');
    blurEffect.classList.remove('blur-effect-background');
  }

  resetBarcodeDataAfterDelay(): void {
    clearTimeout(this.myTimeout)
    this.myTimeout = setTimeout(() => {
      this.barcodeDataVerify(this.barcodeData)
      this.barcodeData = '';
    }, 2000);
  }

  barcodeDataVerify(code) {
    if (code.length >= 100) {
    // if (code.length >= 100 && this.showMemberDetails===false) {
      this.dlOverride = 1;
      this.retrivedUserData = '';
      this.scannedDataToMemberProfileArray = [];
      this.retrivedUserData = this.barcodeService.BoardingPassScan(code)
      if ((this.retrivedUserData.FrequentFlyerNumber === "")) {
        this.tabSelectedValue = 1;
        this.showMemberScreen = false;
        this.showNonMemberScreen = true;
        setTimeout(() => {
          this.nonMemberDetailsForm.controls.member_type.setValue('Non-Member');
          this.nonMemberDetailsForm.controls.flight_number.setValue(this.retrivedUserData.FlightNumber);
          this.nonMemberDetailsForm.controls.pnr_number.setValue(this.retrivedUserData.OperationCarrierPNRCode);
          (this.retrivedUserData.OperationCarrierDesignator==='WS'||this.retrivedUserData.OperationCarrierDesignator==='DL'||this.retrivedUserData.OperationCarrierDesignator==='KL')?
          this.nonMemberDetailsForm.controls.flight_type.setValue(this.retrivedUserData.OperationCarrierDesignator):
          this.nonMemberDetailsForm.controls.flight_type.setValue('OTHER')
          if (this.retrivedUserData.CompartmentCode === "J" 
          || this.retrivedUserData.CompartmentCode === "C"
          || this.retrivedUserData.CompartmentCode === "D"
          || this.retrivedUserData.CompartmentCode === "I"
          || this.retrivedUserData.CompartmentCode === "Z") {
            this.nonMemberDetailsForm.controls.traveler_type.setValue('business-class');
          }
          // else{
          //   this.nonMemberDetailsForm.controls.traveler_type.setValue('walk-up')
          // }
          this.memberService.getAirportData(this.retrivedUserData.FromCityAirportCode)
            .then(data => {
              this.originAirportText = data[0].name
            },
              (error: any) => {
              })
          this.memberService.getAirportData(this.retrivedUserData.ToCityAirportCode)
            .then(data => {
              this.designationAirportText = data[0].name
            },
              (error: any) => {
              })
          this.showNonMemberFields = true;
          this.showDlMemberFields = false;
          this.keyupEventForNonMember('');
        }, 500)
      }
      else if(this.retrivedUserData.FrequentFlyerAirlineDesignator === "DL"){
        this.tabSelectedValue = 1;
        this.showMemberScreen = false;
        this.showNonMemberScreen = true;
        this.hideDlMemberFields = false;
        setTimeout(() => {
          this.nonMemberDetailsForm.controls.member_type.setValue('DL');
          this.nonMemberDetailsForm.controls.flight_number.setValue(this.retrivedUserData.FlightNumber);
          this.nonMemberDetailsForm.controls.pnr_number.setValue(this.retrivedUserData.OperationCarrierPNRCode);
          this.nonMemberDetailsForm.controls.flight_type.setValue(this.retrivedUserData.OperationCarrierDesignator);
          this.nonMemberDetailsForm.controls.ticket_number.setValue(this.retrivedUserData.AirlineNumbericCode + this.retrivedUserData.DocumentForm);
          this.nonMemberDetailsForm.controls.operating_carrrier.setValue(this.retrivedUserData.OperationCarrierDesignator);
          this.nonMemberDetailsForm.controls.first_name.setValue(this.retrivedUserData.firstName);
          this.nonMemberDetailsForm.controls.last_name.setValue(this.retrivedUserData.lastName);
          this.nonMemberDetailsForm.controls.origin.setValue(this.retrivedUserData.FromCityAirportCode);
          this.nonMemberDetailsForm.controls.destination.setValue(this.retrivedUserData.ToCityAirportCode);
          this.nonMemberDetailsForm.controls.fqtv_number.setValue(this.retrivedUserData.FrequentFlyerNumber);
          this.nonMemberDetailsForm.controls.travel_date.setValue(this.retrivedUserData.DateOfFlightJulianDate);
          this.nonMemberDetailsForm.controls.number_of_guests.setValue('0');
          this.showDlMemberFields = true;
          this.showNonMemberFields = false;
          if(this.retrivedUserData.CompartmentCode === "J" 
          || this.retrivedUserData.CompartmentCode === "C"
          || this.retrivedUserData.CompartmentCode === "D"
          || this.retrivedUserData.CompartmentCode === "I"
          || this.retrivedUserData.CompartmentCode === "Z"){
            this.nonMemberDetailsForm.controls.cabin_class.setValue('business-class');
            this.nonMemberDetailsForm.controls.traveler_dl_type.setValue('business-class');
          }
          else if(this.retrivedUserData.CompartmentCode === "W" 
          || this.retrivedUserData.CompartmentCode === "O"
          || this.retrivedUserData.CompartmentCode === "R"){
            this.nonMemberDetailsForm.controls.cabin_class.setValue('premium-economy');
            this.nonMemberDetailsForm.controls.traveler_dl_type.setValue('dlmember');
          }
          else{
            this.nonMemberDetailsForm.controls.cabin_class.setValue('economy');
            this.nonMemberDetailsForm.controls.traveler_dl_type.setValue('dlmember');
          }
          this.keyupEventForDLMember('');
        }, 500)
        this.keyupEventForDLMember("")
      }
      
      else {
        this.tabSelectedValue = 0;
        this.showMemberScreen = true;
        this.showNonMemberScreen = false;
        setTimeout(() => {
          this.memberDetailsForm.controls.reward_ID.setValue(this.retrivedUserData.FrequentFlyerNumber);
          this.scannedDataToMemberProfile = this.retrivedUserData;
          this.scannedDataToMemberProfileArray.push(this.scannedDataToMemberProfile);
          this.searchMember(this.memberDetailsForm,'scanned');
        }, 500)
      }
    }
  }

  givingLoungeAccess() {
    if (this.nonMemberDetailsForm.controls.member_type.value === "Non-Member"){
      if(!(this.nonMemberDetailsForm.controls.traveler_type.value === 'complimentary-voucher')) {
        let apiCallCheck = true;
        this.commonService.setShowContentLoaderValue(true);
        let traveller_type = this.nonMemberDetailsForm.controls.traveler_type.value === "member"? "MEMBER":
          (this.nonMemberDetailsForm.controls.traveler_type.value === "business-class"? "BUSINESS_CLASS":
          (this.nonMemberDetailsForm.controls.traveler_type.value === "walk-up"? "WALK-UP":
          (this.nonMemberDetailsForm.controls.traveler_type.value === "priority-pass"? "PRIORITY_PASS":
          (this.nonMemberDetailsForm.controls.traveler_type.value === "dragon-pass"? "DRAGON_PASS": "WALK-UP"
          ))))
        let request: any = {
          "h_sponsor_id_text": "Lounge Portal",
          "h_sponsor_id": environment.sponsorID,
          "h_bit_category": "ACCRUAL",
          "h_bit_source": "MANUAL",
          "h_bit_type": "LOUNGE_ENTRY",
          "h_flight_num": this.nonMemberDetailsForm.controls.flight_number.value,
          "h_flight_code": this.nonMemberDetailsForm.controls.flight_type.value,
          "h_pnr_number": this.nonMemberDetailsForm.controls.pnr_number.value,
          "member_type": this.nonMemberDetailsForm.controls.member_type.value,
          "h_traveller_type": traveller_type,
          "h_program_id": environment.programID,
          "h_bit_date": new Date().toISOString(),
          "h_member_id":"634LWVG",
          "extra_data":{},
          "h_entry_status":"ACCESS GRANTED"
        }
        let requestLocationPayload = this.nonMemberService.locationMemberPayload(this.currentUser.location_code)
        request = {...request,...requestLocationPayload}
        delete request.location_code
        if (this.retrivedUserData != ''){
          request.h_bit_source = 'SCANNED'
          request.h_seat_number = this.retrivedUserData.SeatNumber
          request.h_ticket_number = this.retrivedUserData.AirlineNumbericCode + this.retrivedUserData.DocumentForm
          request.h_operating_flight_code = this.retrivedUserData.OperationCarrierDesignator
          request.h_fare_class = this.retrivedUserData.CompartmentCode
          request.flight_date = this.pipe.transform(this.retrivedUserData.DateOfFlightJulianDate ,"yyyy-MM-dd")
          request.h_origin_airport = this.retrivedUserData.FromCityAirportCode
          request.h_origin_airport_code = this.retrivedUserData.FromCityAirportCode
          request.h_origin_airport_text = this.originAirportText
          request.h_destination_airport = this.retrivedUserData.ToCityAirportCode
          request.h_destination_airport_code = this.retrivedUserData.ToCityAirportCode
          request.h_destination_airport_text = this.designationAirportText
          request.h_member_name = this.retrivedUserData.PassengerName
        }
        if (this.nonMemberDetailsForm.controls.traveler_type.value === "priority-pass" 
        || this.nonMemberDetailsForm.controls.traveler_type.value === "dragon-pass") {
          request.h_number_of_passengers = Number(this.nonMemberDetailsForm.value.number_of_passengers)
        }
        if ((this.retrivedUserData != '')){
          if(request.h_origin_airport_code === this.currentUser.location_code.substring(0,3)){
            if(request.h_ticket_number.substring(0,3)=='838'){
              if(traveller_type=='BUSINESS_CLASS'){
                this.memberService.sabreAPI(request.h_ticket_number)
                .then((data: any) => {
                  var listOfSegment = data.listOfSegment;
                  var listOfSegmentCounterFlag = false;
                  for (const values of listOfSegment) {
                    if((values.originAirport === request.h_origin_airport_code)&&(values.destAirport === request.h_destination_airport_code)){
                      listOfSegmentCounterFlag = true;
                      if(['J', 'C', 'D', 'I', 'Z'].includes(values.fareBasisClass.substring(0,1))){
                        request.h_coupon_status="CKIN"
                        apiCallCheck = true;
                        this.loungNonmemberCall(request,apiCallCheck)
                      }
                      else{
                        request.h_entry_status = "ACCESS DENIED"
                        apiCallCheck = false;
                        this.loungNonmemberCall(request,apiCallCheck)
                      }
                    }
                  }
                  if(listOfSegmentCounterFlag === false){
                    request.h_entry_status = "ACCESS DENIED"
                    apiCallCheck = false;
                    this.loungNonmemberCall(request,apiCallCheck)
                  }
                },
                  (error: any) => {
                    request.h_entry_status = "ACCESS DENIED"
                    apiCallCheck = false;
                    this.loungNonmemberCall(request,apiCallCheck)
                  }
                )
              }
              else {
                request.h_coupon_status = "CKIN"
                apiCallCheck = true;
                this.loungNonmemberCall(request,apiCallCheck)
              }
            }
            else if(request.h_ticket_number.substring(0,3)=='006'){
              const randomString = this.generateRandomString(34);
              if(traveller_type=='BUSINESS_CLASS'){
                let reqPayload: any = {
                  "LoungeAccessEligibilitySkyTeamRequest": {
                      "TrackID": randomString,
                      "LocationCode": this.currentUser.location_code.substring(0,3),
                      "OperatorID": "WS",
                      "BenefitRequester": this.currentUser.location_code,
                      "AgentID": this.currentUser.user.username,
                      "ETKTDetails": {
                          "OperatingAirlineCode": request.h_operating_flight_code,
                          "ETKTNumber": request.h_ticket_number
                      },
                      "PassengerFirstName": this.retrivedUserData.firstName,
                      "PassengerLastName": this.retrivedUserData.lastName,
                      // "FFPDetails": {
                      //     "FFPNumber": this.retrivedUserData.FrequentFlyerNumber,
                      //     "FFPAirlineCode": ""
                      // },
                      "NumberOfGuests": "0",
                      "FFPCardDetails": {
                          "FFPCardName": "CARD NOT SELECTED"
                      }
                  }
                }
                this.memberService.lamsApi(reqPayload)
                .then((data: any) => {
                  var lamsNMData = data;
                  if((lamsNMData.CabinClassEligibility == "Y") && (lamsNMData.ErrorOrAccessDenialCode == "000")){
                    request.h_entry_status = "ACCESS GRANTED";
                    request.h_access_status = lamsNMData.ErrorOrAccessDenialCode;
                    request.h_denial_reason = lamsNMData.ErrorOrAccessDenialMessage;
                    apiCallCheck = true;
                    this.loungNonmemberCall(request,apiCallCheck)
                  }
                  else {
                    //Display Access Denied pop-up
                    request.h_entry_status = "ACCESS DENIED";
                    request.h_access_status = lamsNMData.ErrorOrAccessDenialCode;
                    request.h_denial_reason = lamsNMData.ErrorOrAccessDenialMessage;
                    apiCallCheck = false;
                    this.loungNonmemberCall(request,apiCallCheck)
                  }
                },
                  (error: any) => {
                    request.h_entry_status = "ACCESS DENIED";
                    request.h_access_status = "001";
                    request.h_denial_reason = "LAMS_FAILED"
                    apiCallCheck = false;
                    this.loungNonmemberCall(request,apiCallCheck)
                  }
                )
              }
              else {
                request.h_coupon_status = "CKIN"
                apiCallCheck = true;
                this.loungNonmemberCall(request,apiCallCheck)
              }
            }
            else {
              request.h_entry_status = "ACCESS DENIED"
              apiCallCheck = false;
              this.loungNonmemberCall(request,apiCallCheck)
            } 
            // request.h_coupon_status = "CKIN"
            // apiCallCheck = true;
          }
          else {
            request.h_entry_status = "ACCESS DENIED"
            apiCallCheck = false;
            this.loungNonmemberCall(request,apiCallCheck)
          }
        }
        else {
          this.loungNonmemberCall(request,apiCallCheck)
        }
      }
      else if(this.nonMemberDetailsForm.controls.traveler_type.value === 'complimentary-voucher'){
        let voucherNo = "";
        // if((this.nonMemberDetailsForm.controls.voucher_number.value.length>7) 
        //   && (this.nonMemberDetailsForm.controls.voucher_number.value[0]==="W") 
        //   && (this.nonMemberDetailsForm.controls.voucher_number.value[1]==="S")){
        //   voucherNo = this.nonMemberDetailsForm.controls.voucher_number.value.slice(2).trim()
        // }
        // else {
        //   voucherNo = this.nonMemberDetailsForm.controls.voucher_number.value
        // }
        if(this.nonMemberDetailsForm.controls.voucher_number.value.length>7){
          voucherNo = this.nonMemberDetailsForm.controls.voucher_number.value.slice(3).trim()
        }
        else {
          voucherNo = this.nonMemberDetailsForm.controls.voucher_number.value
        }
        if(voucherNo.length===7){
          this.commonService.setShowContentLoaderValue(true);
          let traveller_type = "COMPLIMENTARY_VOUCHER"
          let request: any = {
            "h_sponsor_id_text": "Lounge Portal",
            "h_sponsor_id": environment.sponsorID,
            "h_bit_category": "ACCRUAL",
            "h_bit_source": "MANUAL",
            "h_bit_type": "LOUNGE_ENTRY",
            "h_flight_num": this.nonMemberDetailsForm.controls.flight_number.value,
            "h_flight_code": this.nonMemberDetailsForm.controls.flight_type.value,
            "h_pnr_number": this.nonMemberDetailsForm.controls.pnr_number.value,
            "member_type": this.nonMemberDetailsForm.controls.member_type.value,
            "h_traveller_type": traveller_type,
            "h_program_id": environment.programID,
            "h_bit_date": new Date().toISOString(),
            "h_member_id":"634LWVG",
            "extra_data":{},
            "h_entry_status":"ACCESS GRANTED",
            "h_privileges": [],
            "voucher_name": [],
            "h_original_privilege_id": voucherNo
          }
          let requestLocationPayload = this.nonMemberService.locationMemberPayload(this.currentUser.location_code)
          request = {...request,...requestLocationPayload}
          delete request.location_code
          if (this.retrivedUserData != ''){
            request.h_bit_source = 'SCANNED'
            request.h_seat_number = this.retrivedUserData.SeatNumber
            request.h_ticket_number = this.retrivedUserData.AirlineNumbericCode + this.retrivedUserData.DocumentForm
            request.h_operating_flight_code = this.retrivedUserData.OperationCarrierDesignator
            request.h_fare_class = this.retrivedUserData.CompartmentCode
            request.flight_date = this.pipe.transform(this.retrivedUserData.DateOfFlightJulianDate ,"yyyy-MM-dd")
            request.h_origin_airport = this.retrivedUserData.FromCityAirportCode
            request.h_origin_airport_code = this.retrivedUserData.FromCityAirportCode
            request.h_origin_airport_text = this.originAirportText
            request.h_destination_airport = this.retrivedUserData.ToCityAirportCode
            request.h_destination_airport_code = this.retrivedUserData.ToCityAirportCode
            request.h_destination_airport_text = this.designationAirportText
            request.h_member_name = this.retrivedUserData.PassengerName
          }
          this.memberService.getMemberVoucher(voucherNo)
          .then(data =>{
            this.getMemberComplimentaryVoucher = data;
            if(this.getMemberComplimentaryVoucher.length===1){
              let reassignPayload: any = {
                "h_bit_category": "SERVICE",
                "h_sponsor_id": environment.sponsorID,
                "h_bit_type": "PRIVILEGE_TRANSFER",
                "h_location": request.h_location,
                "h_member_id": this.getMemberComplimentaryVoucher[0].member_id,
                "h_program_id": environment.programID,
                "transfer_privilege_code": this.getMemberComplimentaryVoucher[0].privilege_code,
                "transferee_member_id": request.h_member_id,
                "h_bit_date": new Date().toISOString()
              }
              // console.log(reassignPayload)
              this.memberService.transferPrivilege(reassignPayload)
              .then(data =>{
                let main_bit_id:any = data
                // setTimeout(() => {
                  this.memberService.getV1Priveleges(request.h_member_id)
                  .then(data => {
                    let v1Priveleges: any = data
                    v1Priveleges = v1Priveleges.data
                    v1Priveleges.sort((b,a) => {
                      return new Date(a.lastUpdatedOn).getTime() - new Date(b.lastUpdatedOn).getTime();
                    })
                    request.h_privileges.push(v1Priveleges[0].privilegeCode)
                    request.voucher_name.push(v1Priveleges[0].productName)
                    this.memberService.loungeAccess(request)
                    .then(data => {
                      this.commonService.setShowContentLoaderValue(false);
                      const blurEffect = document.getElementById('member-details-page');
                      blurEffect.classList.add('blur-effect-background');
                      this.showSuccessPopup = true;
                      this.disableNonMemberSubmitButton = true;
                      this.retrivedUserData = '';
                    },
                      (error: any) => {
                        this.errorMessageToBeDisplayed = "This voucher has already been used or has expired."
                        this.commonService.setShowContentLoaderValue(false);
                        const blurEffect = document.getElementById('member-details-page');
                        blurEffect.classList.add('blur-effect-background');
                        this.showErrorPopup = true;
                        this.disableNonMemberSubmitButton = true;
                        this.retrivedUserData = '';
                      }
                    )
                  },
                    (error: any) => {
                      this.errorMessageToBeDisplayed = "This voucher has already been used or has expired."
                      this.commonService.setShowContentLoaderValue(false);
                      const blurEffect = document.getElementById('member-details-page');
                      blurEffect.classList.add('blur-effect-background');
                      this.showErrorPopup = true;
                      this.disableNonMemberSubmitButton = true;
                      this.retrivedUserData = '';
                    }
                  )
                // }, 2000);
              },
                (error:any)=>{
                  this.errorMessageToBeDisplayed = "This voucher has already been used or has expired."
                  this.commonService.setShowContentLoaderValue(false);
                  const blurEffect = document.getElementById('member-details-page');
                  blurEffect.classList.add('blur-effect-background');
                  this.showErrorPopup = true;
                  this.disableNonMemberSubmitButton = true;
                  this.retrivedUserData = '';
                }
              )
            }
            else{
              this.errorMessageToBeDisplayed = "This voucher has already been used or has expired."
              this.commonService.setShowContentLoaderValue(false);
              const blurEffect = document.getElementById('member-details-page');
              blurEffect.classList.add('blur-effect-background');
              this.showErrorPopup = true;
              this.disableNonMemberSubmitButton = true;
              this.retrivedUserData = '';
            }
          },
            (error: any)=>{
              this.errorMessageToBeDisplayed = "This voucher has already been used or has expired."
              this.commonService.setShowContentLoaderValue(false);
              const blurEffect = document.getElementById('member-details-page');
              blurEffect.classList.add('blur-effect-background');
              this.showErrorPopup = true;
              this.disableNonMemberSubmitButton = true;
              this.retrivedUserData = '';
            }
          )
        }
        else{
          this.errorMessageToBeDisplayed = "Please check the Complimentary Voucher Number and try again."
          this.commonService.setShowContentLoaderValue(false);
          const blurEffect = document.getElementById('member-details-page');
          blurEffect.classList.add('blur-effect-background');
          this.showErrorPopup = true;
          this.disableNonMemberSubmitButton = true;
          this.retrivedUserData = '';
        }
        
      }
    }
  }

  loungNonmemberCall(req,callCheck) {
    this.memberService.loungeAccess(req)
    .then(data => {
      if(callCheck){
        this.commonService.setShowContentLoaderValue(false);
        const blurEffect = document.getElementById('member-details-page');
        blurEffect.classList.add('blur-effect-background');
        this.showSuccessPopup = true;
        this.disableNonMemberSubmitButton = true;
        this.retrivedUserData = '';
      }
      else{
        this.errorMessageToBeDisplayed = "This access request was denied."
        this.commonService.setShowContentLoaderValue(false);
        const blurEffect = document.getElementById('member-details-page');
        blurEffect.classList.add('blur-effect-background');
        this.showErrorPopup = true;
        this.disableNonMemberSubmitButton = true;
        this.retrivedUserData = '';
      }
    },
      (error: any) => {
        this.errorMessageToBeDisplayed = "This access request was denied."
        this.commonService.setShowContentLoaderValue(false);
        const blurEffect = document.getElementById('member-details-page');
        blurEffect.classList.add('blur-effect-background');
        this.showErrorPopup = true;
        this.disableNonMemberSubmitButton = true;
        this.retrivedUserData = '';
      }
    )
  }

  dlLocationCheck(){
    this.memberService.getAirportData(this.nonMemberDetailsForm.value.origin)
      .then(data => {
        this.originAirportText = data[0].name
        this.memberService.getAirportData(this.nonMemberDetailsForm.value.destination)
          .then(data => {
            this.designationAirportText = data[0].name
            this.dlmemberDetailsDataSubmit()
          },
          (error: any) => {
            this.dlmemberDetailsDataSubmit()
          })
      },
      (error: any) => {
        this.dlmemberDetailsDataSubmit()
      })
  }

  async dlmemberDetailsDataSubmit() {
    this.commonService.setShowContentLoaderValue(true);
    let request: any = {
      h_sponsor_id_text: "Lounge Portal",
      h_sponsor_id: environment.sponsorID,
      h_bit_category: "ACCRUAL",
      h_bit_source: "MANUAL",
      h_bit_type: "LOUNGE_ENTRY",
      h_flight_num: this.nonMemberDetailsForm.value.flight_number,
      h_flight_code: this.nonMemberDetailsForm.value.flight_type,
      h_pnr_number: this.nonMemberDetailsForm.value.pnr_number,
      h_traveller_type: this.nonMemberDetailsForm.value.traveler_dl_type,
      h_program_id: environment.programID,
      h_bit_date: new Date().toISOString(),
      h_member_id: "634LWVG",
      h_frequent_traveler_number: this.nonMemberDetailsForm.value.fqtv_number,
      h_location: "YYC01",
      h_first_name: this.nonMemberDetailsForm.value.first_name,
      no_of_guests: this.nonMemberDetailsForm.value.number_of_guests,
      h_last_name: this.nonMemberDetailsForm.value.last_name,
      h_member_name: this.nonMemberDetailsForm.value.first_name+" "+this.nonMemberDetailsForm.value.last_name,
      member_type: this.nonMemberDetailsForm.value.member_type,
      extra_data: {},
      h_entry_status: "ACCESS DENIED",
      h_seat_number: this.retrivedUserData.SeatNumber,
      h_ticket_number: this.nonMemberDetailsForm.value.ticket_number,
      h_operating_flight_code: this.nonMemberDetailsForm.value.operating_carrrier,
      h_fare_class: this.retrivedUserData.CompartmentCode,
      flight_date: this.pipe.transform(this.nonMemberDetailsForm.value.travel_date ,"yyyy-MM-dd"),
      h_origin_airport : this.nonMemberDetailsForm.value.origin,
      h_origin_airport_code : this.nonMemberDetailsForm.value.origin,
      h_origin_airport_text : this.originAirportText,
      h_destination_airport : this.nonMemberDetailsForm.value.destination,
      h_destination_airport_code : this.nonMemberDetailsForm.value.destination,
      h_destination_airport_text : this.designationAirportText,
      h_access_status: "001",
      h_denial_reason: "Incorrect Lounge Location",
      cabin_class_code: this.nonMemberDetailsForm.value.cabin_class
    }
    let requestLocationPayload = this.nonMemberService.locationMemberPayload(this.currentUser.location_code)
    request = {...request,...requestLocationPayload}
    delete request.location_code
    request.h_member_name = this.nonMemberDetailsForm.value.first_name+" "+this.nonMemberDetailsForm.value.last_name
    this.nonMemberDetailsForm.value.traveler_dl_type === "business-class"?
    (request.h_traveller_type = 'BUSINESS_CLASS'):(request.h_traveller_type = 'MEMBER')
    this.nonMemberDetailsForm.value.cabin_class === "business-class"?
    (request.cabin_class_code = 'Business Class'):(this.nonMemberDetailsForm.value.cabin_class === "premium-economy"?
    (request.cabin_class_code = 'Premium Economy'):(request.cabin_class_code = 'Economy'))
    if (this.retrivedUserData != ''){
      request.h_bit_source = 'SCANNED'
    }
    if(this.dlOverride===2){
      const overrideData = localStorage.getItem('overrideData');
      request = JSON.parse(overrideData);
      localStorage.removeItem('overrideData');
      const ovrideValue = request.h_access_status=='317'?'valid-same-day':(
        request.h_access_status=='211'?'skyteam-elite-or-partner-elite-member':(
          request.h_access_status=='316'?'within-24-hour-travel':(
            request.h_access_status=='314'?'name-is-valid':(
              request.h_access_status=='315'?'at-valid-outbound-airport':'ticket-is-valid'
            )
          )
        )
      )
      const OverrideCode = request.h_access_status=='317'?'OV001':(
        request.h_access_status=='211'?'OV002':(
          request.h_access_status=='316'?'OV004':(
            request.h_access_status=='314'?'OV006':(
              request.h_access_status=='315'?'OV008':'OV007'
            )
          )
        )
      )
      const OverrideCondition = request.h_access_status=='317'?'PAX_HAS_A_VALID_SAMEDAY_SKYTEAM_OPERATED_INTERNATIONAL_FLIGHT':(
        request.h_access_status=='211'?'PAX_IS_A_SKYTEAM_ELITE+_FREQUENT_FLYER_CUSTOMER':(
          request.h_access_status=='316'?'PAX_IS_WITHIN_THE_24_HOUR_WINDOW_OF_ACCESS':(
            request.h_access_status=='314'?'PAX_NAME_VALID':(
              request.h_access_status=='315'?'PAX_IS_AT_A_VALID_OUTBOUND_AIRPORT':'PAX_HAS_A_VALID_ETKT'
            )
          )
        )
      )
      request.h_override_code = OverrideCode;
      request.h_override_reason = OverrideCondition;
      let OVCabinClass = request.h_fare_class
      if (OVCabinClass == undefined){
        this.nonMemberDetailsForm.value.cabin_class == 'business-class'?(OVCabinClass='J'):(
          this.nonMemberDetailsForm.value.cabin_class == 'premium-economy'?(OVCabinClass='W'):(OVCabinClass='Y')
        )
      }
      if(ovrideValue == this.nonMemberDetailsForm.controls.override_reason.value){
        const randomString = this.generateRandomString(34);
        let reqPayload: any = {
          "LoungeAccessOverrideSkyTeamRequest": {
              "TrackID": randomString,
              "LocationCode": this.currentUser.location_code.substring(0,3),
              "OperatorID": "WS",
              "BenefitRequester": this.currentUser.location_code,
              "AgentID": this.currentUser.user.username,
              "ETKTDetails": {
                  "OperatingAirlineCode": request.h_operating_flight_code,
                  "ETKTNumber": request.h_ticket_number
              },
              "PassengerFirstName": this.nonMemberDetailsForm.value.first_name,
              "PassengerLastName": this.nonMemberDetailsForm.value.last_name,
              "FFPDetails": {
                  "FFPNumber": this.nonMemberDetailsForm.value.fqtv_number,
                  "FFPAirlineCode": "DL"
              },
              "NumberOfGuests": request.no_of_guests,
              "OverrideCode": OverrideCode,
              "OverrideCondition": OverrideCondition,
              "OverrideTravellingFlightDetails": {
                "OVFlightNumber": request.h_flight_num,
                "OVCabinClass": OVCabinClass,
                "OVDepartureDate": request.flight_date,
                "OVFlightOrigin": request.h_origin_airport_code,
                "OVFlightDestination": request.h_destination_airport_code
              }
          }
        }
        await this.memberService.lamsOverrideAPI(reqPayload)
        .then((data: any) => {
          var lamsOverrideData = data;
          if((lamsOverrideData.CabinClassEligibility == "Y") && (lamsOverrideData.ErrorOrAccessDenialCode == "000")){
            request.h_entry_status = "ACCESS GRANTED";
            request.h_access_status = lamsOverrideData.ErrorOrAccessDenialCode;
            request.h_denial_reason = lamsOverrideData.ErrorOrAccessDenialMessage;
            request.h_traveller_type = 'BUSINESS_CLASS'
          }
          else if (lamsOverrideData.CabinClassEligibility == 'N' && lamsOverrideData.ErrorOrAccessDenialCode == "000") {
            request.h_entry_status = "ACCESS GRANTED";
            request.h_access_status = lamsOverrideData.ErrorOrAccessDenialCode;
            request.h_denial_reason = lamsOverrideData.ErrorOrAccessDenialMessage;
            request.h_traveller_type = 'MEMBER'
          }
          else{
            request.h_entry_status = "ACCESS DENIED";
            request.h_access_status = lamsOverrideData.ErrorOrAccessDenialCode;
            request.h_denial_reason = lamsOverrideData.ErrorOrAccessDenialMessage;
          }
          request.h_track_id = randomString;
          this.memberService.loungeAccess(request)
          .then(data => {
            this.commonService.setShowContentLoaderValue(false);
            const blurEffect = document.getElementById('member-details-page');
            blurEffect.classList.add('blur-effect-background');
            this.showSuccessPopup = true;
            this.disableSubmitButton = true;
            this.retrivedUserData = '';
          },
          (error: any) => {
            this.errorMessageToBeDisplayed = "This access request was denied."
            this.commonService.setShowContentLoaderValue(false);
            const blurEffect = document.getElementById('member-details-page');
            blurEffect.classList.add('blur-effect-background');
            this.showErrorPopup = true;
            this.disableSubmitButton = true;
            this.retrivedUserData = '';
          }
          )
        },
          (error: any) => {
            request.h_denial_reason = "LAMS OVERRIDE API Fail";
            request.h_entry_status = "INELIGIBLE"
            request.h_track_id = randomString;
            this.memberService.loungeAccess(request)
            .then(data => {
              this.errorMessageToBeDisplayed = "This access request was denied."
              this.commonService.setShowContentLoaderValue(false);
              const blurEffect = document.getElementById('member-details-page');
              blurEffect.classList.add('blur-effect-background');
              this.showErrorPopup = true;
              this.disableSubmitButton = true;
              this.retrivedUserData = '';
            },
            (error: any) => {
              this.errorMessageToBeDisplayed = "This access request was denied."
              this.commonService.setShowContentLoaderValue(false);
              const blurEffect = document.getElementById('member-details-page');
              blurEffect.classList.add('blur-effect-background');
              this.showErrorPopup = true;
              this.disableSubmitButton = true;
              this.retrivedUserData = '';
            }
            )
          }
        )
      }
      else {
        request.h_denial_reason = "Incorrect Override Reason"
        this.memberService.loungeAccess(request)
        .then(data => {
          this.errorMessageToBeDisplayed = "This access request was denied."
          this.commonService.setShowContentLoaderValue(false);
          const blurEffect = document.getElementById('member-details-page');
          blurEffect.classList.add('blur-effect-background');
          this.showErrorPopup = true;
          this.disableSubmitButton = true;
          this.retrivedUserData = '';
        },
        (error: any) => {
          this.errorMessageToBeDisplayed = "This access request was denied."
          this.commonService.setShowContentLoaderValue(false);
          const blurEffect = document.getElementById('member-details-page');
          blurEffect.classList.add('blur-effect-background');
          this.showErrorPopup = true;
          this.disableSubmitButton = true;
          this.retrivedUserData = '';
        }
        )
      }
    }
    else{
      const randomString = this.generateRandomString(34);
      const errorCodes = ["317","211","316","314","312","109","315"];
      let reqPayload: any = {
        "LoungeAccessEligibilitySkyTeamRequest": {
            "TrackID": randomString,
            "LocationCode": this.currentUser.location_code.substring(0,3),
            "OperatorID": "WS",
            "BenefitRequester": this.currentUser.location_code,
            "AgentID": this.currentUser.user.username,
            "ETKTDetails": {
                "OperatingAirlineCode": request.h_operating_flight_code,
                "ETKTNumber": request.h_ticket_number
            },
            "PassengerFirstName": this.nonMemberDetailsForm.value.first_name,
            "PassengerLastName": this.nonMemberDetailsForm.value.last_name,
            "FFPDetails": {
                "FFPNumber": this.nonMemberDetailsForm.value.fqtv_number,
                "FFPAirlineCode": "DL"
            },
            "NumberOfGuests": request.no_of_guests,
            "FFPCardDetails": {
                "FFPCardName": "CARD NOT SELECTED"
            }
        }
      }
      await this.memberService.lamsApi(reqPayload)
      .then((data: any) => {
        var lamsNMData = data;
        if((lamsNMData.CabinClassEligibility == "Y") && (lamsNMData.ErrorOrAccessDenialCode == "000")){
          request.h_entry_status = "ACCESS GRANTED";
          request.h_access_status = lamsNMData.ErrorOrAccessDenialCode;
          request.h_denial_reason = lamsNMData.ErrorOrAccessDenialMessage;
          request.h_traveller_type = 'BUSINESS_CLASS'
        }
        else if (lamsNMData.CabinClassEligibility == 'N' && lamsNMData.ErrorOrAccessDenialCode == "000") {
          //Display Access Denied pop-up
          request.h_entry_status = "ACCESS GRANTED";
          request.h_access_status = lamsNMData.ErrorOrAccessDenialCode;
          request.h_denial_reason = lamsNMData.ErrorOrAccessDenialMessage;
          request.h_traveller_type = 'MEMBER'
        } 
        else if (errorCodes.includes(lamsNMData.ErrorOrAccessDenialCode)) {
          //Display Access Denied pop-up
          request.h_entry_status = "ACCESS DENIED";
          request.h_access_status = lamsNMData.ErrorOrAccessDenialCode;
          request.h_denial_reason = lamsNMData.ErrorOrAccessDenialMessage;
        }
        else{
          //Display Access Denied pop-up
          request.h_entry_status = "ACCESS DENIED";
          request.h_access_status = lamsNMData.ErrorOrAccessDenialCode;
          request.h_denial_reason = lamsNMData.ErrorOrAccessDenialMessage;
        }
      },
        (error: any) => {
          request.h_denial_reason = "LAMS API Fail";
          request.h_access_status = "001";
          request.h_entry_status = "INELIGIBLE"
        }
      )
      if(request.h_access_status=='000'){
        this.hideDlMemberFields = false;
        request.h_track_id = randomString;
        this.memberService.loungeAccess(request)
        .then(data => {
          this.commonService.setShowContentLoaderValue(false);
          const blurEffect = document.getElementById('member-details-page');
          blurEffect.classList.add('blur-effect-background');
          this.showSuccessPopup = true;
          this.disableSubmitButton = true;
          this.retrivedUserData = '';
        },
        (error: any) => {
          this.errorMessageToBeDisplayed = "This access request was denied."
          this.commonService.setShowContentLoaderValue(false);
          const blurEffect = document.getElementById('member-details-page');
          blurEffect.classList.add('blur-effect-background');
          this.showErrorPopup = true;
          this.disableSubmitButton = true;
          this.retrivedUserData = '';
        }
        )
      }
      else if(errorCodes.includes(request.h_access_status)){
        this.nonMemberDetailsForm.controls.denial_message.setValue(
          request.h_access_status=='317'?'Same Day SkyTeam INTL flight not found':(
            request.h_access_status=='211'?'Not an Elite Plus Customer':(
              request.h_access_status=='316'?'Access outside of predefined window':(
                request.h_access_status=='314'?'Ticket Name does not match':(
                  request.h_access_status=='315'?'Invalid Airport':'Invalid Ticket #'
                )
              )
            )
          )
        )
        this.nonMemberDetailsForm.controls.override_reason.setValue(
          request.h_access_status=='317'?'valid-same-day':(
            request.h_access_status=='211'?'skyteam-elite-or-partner-elite-member':(
              request.h_access_status=='316'?'within-24-hour-travel':(
                request.h_access_status=='314'?'name-is-valid':(
                  request.h_access_status=='315'?'at-valid-outbound-airport':'ticket-is-valid'
                )
              )
            )
          )
        )
        localStorage.setItem('overrideData',JSON.stringify(request));
        this.dlOverride = 2;
        this.commonService.setShowContentLoaderValue(false);
        this.hideDlMemberFields = true;
      }
      else {
        this.hideDlMemberFields = false;
        request.h_track_id = randomString;
        this.memberService.loungeAccess(request)
        .then(data => {
          this.errorMessageToBeDisplayed = "This access request was denied."
          this.commonService.setShowContentLoaderValue(false);
          const blurEffect = document.getElementById('member-details-page');
          blurEffect.classList.add('blur-effect-background');
          this.showErrorPopup = true;
          this.disableSubmitButton = true;
          this.retrivedUserData = '';
        },
        (error: any) => {
          this.errorMessageToBeDisplayed = "This access request was denied."
          this.commonService.setShowContentLoaderValue(false);
          const blurEffect = document.getElementById('member-details-page');
          blurEffect.classList.add('blur-effect-background');
          this.showErrorPopup = true;
          this.disableSubmitButton = true;
          this.retrivedUserData = '';
        }
        )
      }
    }
  }

  closePopup() {
    this.hideDlMemberFields = false;
    const blurEffect = document.getElementById('member-details-page');
    blurEffect.classList.remove('blur-effect-background');
    this.showSuccessPopup = false;
    this.showErrorPopup = false;
    this.showNumberOfPassengers = false;
    this.showVoucherNumber = false;
    this.nonMemberDetailsForm.reset();
    this.memberTypeChange('Non-Member');
    this.nonMemberDetailsForm.controls.flight_type.setValue('WS');
  }

  generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
