import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MemberService } from 'src/services/member.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { NonMemberService } from 'src/services/non-member.service';
import { CommonService } from 'src/services/common.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone'

@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.less']
})

export class ServiceRequestComponent implements OnInit {
  serviceRequestCreateForm : any = FormGroup;
  serviceRequestForm: FormGroup;
  serviceRequestList: any;
  serviceCreateOpenState = false;
  serviceOpenState = false;
  serviceOpenStateData = {};
  // disableSearchButton: boolean = true;
  disableCreateButton: boolean = true;
  lblName: string;
  showCreationDateInput = false;
  serviceRequestScreenLoading:boolean = false;
  currentUser: any;
  MaxDate:any = '';
  todaysDate:any = '';
  yesterdaysDate:any = '';
  paginationActive = false;
  dataAvailable = false;
  ordering = '-created_ts';
  selectedDropdownIndex = 0
  isDropdownVisible = false;

  nextUrl:any = 'None';
  prevUrl:any = 'None';

  pipe = new DatePipe('en-US');

  constructor(
    private formbuilder: FormBuilder,
    private memberService: MemberService,
    private authenticationService: AuthenticationService,
    private nonMemberService: NonMemberService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.serviceRequestForm = this.formbuilder.group({
      search_criteria: ['searchId'],
      search_text: [''],
      creation_date:['']
    })
    this.serviceRequestCreateForm = this.formbuilder.group({
      priority_list: ['low'],
      reward_id: [''],
      description: ['']
    })
    this.lblName = "WestJet Rewards ID";
    // this.disableSearchButton = true;
    this.disableCreateButton = true;
    this.currentUser = this.authenticationService.currentUserValue;
    this.MaxDate = new Date();
    this.todaysDate = new Date();
    this.todaysDate = this.pipe.transform(this.todaysDate,"yyyy-MM-dd")
    this.yesterdaysDate = new Date();
    this.yesterdaysDate = this.yesterdaysDate.setDate(this.yesterdaysDate.getDate()-90)
    this.yesterdaysDate = this.pipe.transform(this.yesterdaysDate ,"yyyy-MM-dd")
    this.getServiceRequestData("")
    this.isDropdownVisible = false
  }


  keyupEvent(event: any) {
    let searchValue = this.serviceRequestForm.controls.search_text.value;
    // if(searchValue === ""){
    //   this.disableSearchButton = true;
    // }
    // else{
    //   this.disableSearchButton = false;
    // }
    if(this.serviceRequestCreateForm.controls.description.value===""){
      this.disableCreateButton = true;
    }
    else{
      this.disableCreateButton = false;
    }
  }

  searchCriteriaChange(event: any){
    this.isDropdownVisible = false
    let searchType = this.serviceRequestForm.controls.search_criteria.value;
    if(searchType === "searchId"){
      this.lblName = "WestJet Rewards ID";
      this.showCreationDateInput = false;
    }
    else if(searchType === "serialNum"){
      this.lblName = "Serial Number";
      this.showCreationDateInput = false;
    }
    else if(searchType === "creationDate"){
      this.lblName = "Creation Date";
      this.showCreationDateInput = true;
    }
    // this.disableSearchButton = true;
    this.serviceRequestForm.controls.search_text.setValue('');
    this.serviceRequestForm.controls.creation_date.setValue('')
  }



  popupState(currentState:any){
    this.isDropdownVisible = false
    const blurEffect = document.getElementById('service-request-tab');
    if(currentState === 'open'){
      this.serviceCreateOpenState = true;
      blurEffect.classList.add('blur-effect-background');
    }
    else if(currentState === 'close'){
      // this.disableSearchButton = false;
      blurEffect.classList.remove('blur-effect-background');
      this.serviceCreateOpenState = false;
      this.serviceRequestCreateForm.controls.reward_id.setValue('');
      this.serviceRequestCreateForm.controls.description.setValue('');
    }
    else if(currentState === 'creation'){
      // this.disableSearchButton = true;
      blurEffect.classList.add('blur-effect-background');
    }
  }
  popupOpenState(currentState:any){
    this.isDropdownVisible = false
    const blurEffect = document.getElementById('service-request-tab');
    if(currentState === 'close'){
      blurEffect.classList.remove('blur-effect-background');
      this.serviceOpenState = false;
    }
    else{
      blurEffect.classList.add('blur-effect-background');
      this.serviceOpenState = true;
      this.serviceOpenStateData = currentState;
    }
  }
  onPickerClear(datepicker: MatDatepicker<any>) {
    datepicker.select(null);
    datepicker.close();
    const blurEffect = document.getElementById('service-request-tab');
    blurEffect.classList.remove('blur-effect-background');
  }

  createService(){
    this.isDropdownVisible = false
    let memberId:any = this.serviceRequestCreateForm.controls.reward_id.value;
    if(this.serviceRequestCreateForm.controls.reward_id.value===""){
      memberId = this.nonMemberService.locationMemberPayload(this.currentUser.location_code).h_member_id
    }
    let request:any = {
      "category": environment.category,
      "title": "Service Request",
      "note_text": this.serviceRequestCreateForm.controls.description.value,
      "attributes_data": {
        "visit_location": this.currentUser.location_code,
        "visit_date": moment(new Date(), 'DD/MM/YYYY h:mm A').tz('Canada/Mountain').format()
      },
      "priority": this.serviceRequestCreateForm.controls.priority_list.value.toUpperCase(),
      "status": "UNRESOLVED",
      "member": memberId
    }
    this.memberService.createServiceRequest(memberId,request)
    .then(data => {
      this.popupState('close')
      this.serviceRequestScreenLoading = false;
      this.getServiceRequestData("")
    },
    (error: any) => {
    })
  }

  dropdownShow(){
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  selectedDropdown(param,index){
    this.ordering = param;
    this.selectedDropdownIndex = index;
  }

  getServiceRequestData(param){
    this.isDropdownVisible = false
    this.commonService.setShowContentLoaderValue(true);
    if(param===""){
      let statusOrdering = ""
      if (this.ordering === 'updated_ts' || this.ordering === '-updated_ts'){
        statusOrdering = "&status=CLOSED"
      }
      this.memberService.getServiceValues(statusOrdering,this.todaysDate,this.yesterdaysDate,this.currentUser.location_code,this.ordering).then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.serviceRequestList = data.body
        for(let i=0;i<this.serviceRequestList.length;i++){
          for(let j=0;j<3;j++){
            if(this.serviceRequestList[i]?.attributes_data[j]?.attribute === 6){
              let str:any = this.serviceRequestList[i].attributes_data[2].value
              this.serviceRequestList[i].attributes_data[2].value = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
              this.serviceRequestList[i].ticketpriority = this.serviceRequestList[i].attributes_data[j].value
            }
          } 
          if(this.serviceRequestList[i].status === "CLOSED"){
            this.serviceRequestList[i].status = "Closed"
          }
          else{
            this.serviceRequestList[i].status = "Open"
          }
          this.serviceRequestList[i].status === "Open"?this.serviceRequestList[i].updated_ts="N/A":this.serviceRequestList[i].updated_ts
        }
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.serviceRequestScreenLoading = true;
        if(this.serviceRequestList.length===0){
          this.dataAvailable = false
          this.paginationActive = false
        }
        else if(this.serviceRequestList.length<10){
          this.dataAvailable = true
          this.paginationActive = false
        }
        else {
          this.dataAvailable = true
          this.paginationActive = true
        }
      },
      (error: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.dataAvailable = false
        this.paginationActive = false
      });
      this.serviceRequestForm.controls.search_text.setValue('');
      this.serviceRequestForm.controls.creation_date.setValue('');
    }
    else if(param==="search"){
      let toDate = this.todaysDate
      let fromDate = this.yesterdaysDate
      let searchCriteria = ''
      if(this.serviceRequestForm.controls.search_criteria.value === 'searchId' && this.serviceRequestForm.controls.search_text.value != ""){
        searchCriteria = "&member_id=" + this.serviceRequestForm.controls.search_text.value;
      }
      else if(this.serviceRequestForm.controls.search_criteria.value === 'serialNum' && this.serviceRequestForm.controls.search_text.value != ""){
        searchCriteria = "&id=" + this.serviceRequestForm.controls.search_text.value;
      }
      else{
        searchCriteria="";
        if(this.serviceRequestForm.controls.creation_date.value != "" && this.serviceRequestForm.controls.creation_date.value != null && this.serviceRequestForm.controls.creation_date.value != undefined ){
          toDate = this.pipe.transform(this.serviceRequestForm.controls.creation_date.value,"yyyy-MM-dd");
          fromDate = this.pipe.transform(this.serviceRequestForm.controls.creation_date.value,"yyyy-MM-dd");
        }
      }
      if (this.ordering === 'updated_ts' || this.ordering === '-updated_ts'){
        searchCriteria = searchCriteria.concat("&status=CLOSED")
      }
      this.memberService.getServiceValues(searchCriteria,toDate,fromDate,this.currentUser.location_code,this.ordering).then((data: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.serviceRequestList = data.body
        for(let i=0;i<this.serviceRequestList.length;i++){
          for(let j=0;j<3;j++){
            if(this.serviceRequestList[i]?.attributes_data[j]?.attribute === 6){
              let str:any = this.serviceRequestList[i].attributes_data[2].value
              this.serviceRequestList[i].attributes_data[2].value = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
              this.serviceRequestList[i].ticketpriority = this.serviceRequestList[i].attributes_data[j].value
            }
          } 
          if(this.serviceRequestList[i].status === "CLOSED"){
            this.serviceRequestList[i].status = "Closed"
          }
          else{
            this.serviceRequestList[i].status = "Open"
          }
          this.serviceRequestList[i].status === "Open"?this.serviceRequestList[i].updated_ts="N/A":this.serviceRequestList[i].updated_ts
        }
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.serviceRequestScreenLoading = true;
        if(this.serviceRequestList.length===0){
          this.dataAvailable = false
          this.paginationActive = false
        }
        else if(this.serviceRequestList.length<10){
          this.dataAvailable = true
          this.paginationActive = false
        }
        else {
          this.dataAvailable = true
          this.paginationActive = true
        }
      },
      (error: any) => {
        this.commonService.setShowContentLoaderValue(false);
        this.dataAvailable = false
        this.paginationActive = false
      });
    }
  }
  nextPrev(screen:any){
    this.isDropdownVisible = false
    this.commonService.setShowContentLoaderValue(true);
    if(screen==='next'){
      this.memberService.getServiceValuesNextPrev(this.nextUrl).then((data: any) => {
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.commonService.setShowContentLoaderValue(false);
        this.serviceRequestList = data.body
        for(let i=0;i<this.serviceRequestList.length;i++){
          for(let j=0;j<3;j++){
            if(this.serviceRequestList[i]?.attributes_data[j]?.attribute === 6){
              let str:any = this.serviceRequestList[i].attributes_data[j].value
              this.serviceRequestList[i].attributes_data[j].value = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
              this.serviceRequestList[i].ticketpriority = this.serviceRequestList[i].attributes_data[j].value
            }
          }
          if(this.serviceRequestList[i].status === "CLOSED"){
            this.serviceRequestList[i].status = "Closed"
          }
          else{
            this.serviceRequestList[i].status = "Open"
          }
          this.serviceRequestList[i].status === "Open"?this.serviceRequestList[i].updated_ts="N/A":this.serviceRequestList[i].updated_ts
        }
        this.serviceRequestScreenLoading = true;
        if(this.serviceRequestList.length===0){
          this.dataAvailable = false
        }
        else{
          this.dataAvailable = true
        }
      },
        (error: any) => {
          this.commonService.setShowContentLoaderValue(false);
          this.dataAvailable = false
          this.paginationActive = false
        });
    }
    else if(screen==='prev'){
      this.memberService.getServiceValuesNextPrev(this.prevUrl).then((data: any) => {
        this.nextUrl = data.headers.get('next')
        this.prevUrl = data.headers.get('prev')
        this.commonService.setShowContentLoaderValue(false);
        this.serviceRequestList = data.body
        for(let i=0;i<this.serviceRequestList.length;i++){
          for(let j=0;j<3;j++){
            if(this.serviceRequestList[i]?.attributes_data[j]?.attribute === 6){
              let str:any = this.serviceRequestList[i].attributes_data[j].value
              this.serviceRequestList[i].attributes_data[j].value = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
              this.serviceRequestList[i].ticketpriority = this.serviceRequestList[i].attributes_data[j].value
            }
          }
          if(this.serviceRequestList[i].status === "CLOSED"){
            this.serviceRequestList[i].status = "Closed"
          }
          else{
            this.serviceRequestList[i].status = "Open"
          }
          this.serviceRequestList[i].status === "Open"?this.serviceRequestList[i].updated_ts="N/A":this.serviceRequestList[i].updated_ts
        }
        this.serviceRequestScreenLoading = true;
        if(this.serviceRequestList.length===0){
          this.dataAvailable = false
        }
        else{
          this.dataAvailable = true
        }
      },
        (error: any) => {
          this.commonService.setShowContentLoaderValue(false);
          this.dataAvailable = false
          this.paginationActive = false
        });
      }
  }

}
