import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/services/authentication.service';
import { EmailOtpSectionComponent } from '../twofa-setup-container/email-otp-section/email-otp-section.component';
import { TwofaSetupContainerComponent } from '../twofa-setup-container/twofa-setup-container.component';
import { TwofaService } from '../twofa.service';

@Component({
  selector: 'app-twofa-splash-screen',
  templateUrl: './twofa-splash-screen.component.html',
  styleUrls: ['./twofa-splash-screen.component.less']
})
export class TwofaSplashScreenComponent implements OnInit {

  twofaOptFlag = true;
  tenantDetails: any;
  currentUser: any;

  constructor(
    public dialogRef: MatDialogRef<TwofaSplashScreenComponent>,
    private dialog: MatDialog,
    private twofaservice: TwofaService,
    private router:Router,
    private authenticationService:AuthenticationService
  ) { 
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;
    this.tenantDetails = localStorage.getItem('tenantInfo');
    this.tenantDetails = JSON.parse(this.tenantDetails);

    if(this.tenantDetails['2fa_status'] == 'OPTIONAL') {
      this.twofaOptFlag = true;
    } else if(this.tenantDetails['2fa_status'] == 'MANDATORY') {
      this.twofaOptFlag = false;
    }
  }

  nextScreen() {
    this.twofaservice.updateScreen(0);
    this.twofaservice.sendVerificationEmail(this.currentUser.id).then((data: any) => {
      localStorage.setItem('for_2fa_only',data["Two-FA-Token"]);
      const dialogRef = this.dialog.open(TwofaSetupContainerComponent, {
        width: '955px',
        panelClass: 'twofa-dialog-container',
        backdropClass: 'backdropBackground',
        data: {
        },
        position: { top: '160px' }
      });
  
      dialogRef.afterClosed().subscribe(result => {   
        this.closePopup();  
      });
    }).catch((error: any) => { 
      console.log(error);
    });
  }

  closePopup() {
    if(this.tenantDetails['2fa_status'] == 'OPTIONAL') {
      this.router.navigate(['lounge-access']);
      location.reload();
    }
    this.dialogRef.close();
  }

}
