import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication.service';
import { CommonService } from './common.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    currentTwofaToken: any;
    constructor(private authenticationService: AuthenticationService, private commonService: CommonService,) { }
    private host = environment.host();
    private host2 = environment.host2();

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && (currentUser.token || currentUser['Two-FA-Token'])) {
            if (request.url.indexOf('validate') > 0) {
                request = request.clone({
                    setHeaders: {
                        'x-api-key': environment.x_api_key,
                    }
                });
                // }
            } else if (request.url.includes('lounges/westjet/')) {
                request = request.clone({
                    setHeaders: {
                        'Ocp-Apim-Subscription-Key': environment.ocp_pim_subscription_key,
                    }
                });
            } else if(request.url.includes('generate-email-otp/') || request.url.includes('verify-email-otp/') || request.url.includes('generate-2fa-qr/') || request.url.includes('setup-2fa/') || request.url.includes('verify-totp/')){
                this.currentTwofaToken = localStorage.getItem('for_2fa_only')
                request = request.clone({
                    headers: new HttpHeaders({
                        'x-api-key': environment['x_api_key'],
                        'Two-FA-Token':this. currentTwofaToken
                    })
                });
            } else if(request.url.includes('tenants/basic-info/')) {
                request = request.clone({
                    setHeaders: {
                        'x-api-key': environment.x_api_key,
                    }
                });
            }
            else {
                let setHeaders: any = {
                    Authorization: 'JWT ' + currentUser.token,
                }
                setHeaders['x-api-key'] = environment.x_api_key,
                // setHeaders['Access-Control-Allow-Origin'] = '*'
                request = request.clone({
                    setHeaders: setHeaders
                });
            }

        }
        else {
            request = request.clone({
                setHeaders: {
                    'x-api-key': environment.x_api_key,
                    // 'Access-Control-Allow-Origin' : '*'
                }
            });
        }

        return next.handle(request);
    }
}