import { Component, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/services/common.service';
import { delay } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'westjet-lounge-portal';
  showContentLoader = false;
  showContentLoaderSubscription: Subscription;

  constructor(
    private commonService: CommonService
  ) {
    this.showContentLoaderSubscription = new Subscription();
  }

  ngOnInit() {
    this.showContentLoaderSubscription = this.commonService.getShowContentLoaderObs().pipe(delay(0)).subscribe(val => {
      this.showContentLoader = val;
    })
  }
}
