<div class="twofa-otp-container">
    <div class="right-container">
        <div class="setup-header">
            Enter the 6 figure confirmation code sent to your associated email in order to proceed with setup:
        </div>

        <form [formGroup]="form">
            <div class="otp-inputs" [ngClass]="{'otp-inputs-error': showError}">
                <div *ngFor="let input of formInput;  index as i">
                    <input size="10" #formRow type="text" formControlName="{{input}}" class="form-control" maxlength="1" (keyup)="keyUpEvent($event, i)">
                </div>
            </div>

            <div class="error-msg" *ngIf="showError">
                {{errorMsg}}
            </div>

            <div class="resend-link" (click)="resendEmailOTP()">
                Didn’t receive the code? Resend verification email.
            </div>
    
            <div class="misc-divider"></div>
            <div class="button-container">
                <button mat-raised-button class="cancel-button" (click)="closePopup()">Cancel</button>
                <button mat-raised-button class="continue-button" [ngClass]="{'disabled': !form.valid}" [disabled]="!form.valid" (click)="onSubmit()">Continue</button>
            </div>
        </form>
    </div>
</div>
