<div class="member-details-page" id="member-details-page">
    <div class="member-details-container">
        <div>
            <mat-tab-group mat-align-tabs="center" class="member-details-tabGroup"
                (selectedTabChange)="selectedTabValueChange($event)" [selectedIndex]="tabSelectedValue">
                <mat-tab label="Members" class="mat-tab-label-content"></mat-tab>
                <mat-tab label="Non-Members" class="mat-tab-label-content"></mat-tab>
            </mat-tab-group>
        </div>
        <div class="member-only-screen" *ngIf="showMemberScreen">
            <form [formGroup]="memberDetailsForm" class="member-details-form">
                <mat-form-field appearance="fill" class="member-details-form-dropdown">
                    <mat-select formControlName="Search_Formate_List"
                        (selectionChange)="searchFormateListChange($event)">
                        <mat-option value="GN"> Guest Name </mat-option>
                        <mat-option value="RID"> Rewards ID </mat-option>
                    </mat-select>
                    <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                </mat-form-field>

                <mat-form-field appearance="fill" class="member-details-form-input" *ngIf="!showRewardIDField">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="First_Name" placeholder="" (keyup)="keyupEvent($event)" />
                </mat-form-field>

                <mat-form-field appearance="fill" class="member-details-form-input" *ngIf="!showRewardIDField" (keyup.enter)="searchMember(memberDetailsForm,'nonScanned')">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="Last_Name" placeholder="" (keyup)="keyupEvent($event)" />
                </mat-form-field>

                <mat-form-field appearance="fill" class="member-details-form-input" *ngIf="!showRewardIDField" (keyup.enter)="searchMember(memberDetailsForm,'nonScanned')">
                    <mat-label>Phone Number (Optional)</mat-label>
                    <input matInput formControlName="Phone_Number" placeholder="" (keyup)="keyupEvent($event)" />
                </mat-form-field>

                <mat-form-field appearance="fill" class="member-details-form-input-RewardID" *ngIf="showRewardIDField" (keyup.enter)="searchMember(memberDetailsForm,'nonScanned')">
                    <mat-label>WestJet Rewards ID</mat-label>
                    <input matInput formControlName="reward_ID" placeholder="" (keyup)="keyupEvent($event)" />
                </mat-form-field>
                <br>
            </form>
            <div class="search-details">
                <button mat-raised-button class="clear-button" (click)="clearMemberDetails(memberDetailsForm)"
                    *ngIf="showClearButton">CLEAR RESULTS</button>
                <button mat-raised-button class="search-button" (click)="searchMember(memberDetailsForm,'nonScanned')"
                    [disabled]="disableSaveButton"
                    [ngStyle]="{'cursor': (disableSaveButton? 'not-allowed':'pointer')}">SEARCH</button>
            </div>
            <div class="search-details-screen" *ngIf="!showMemberDetails">
                <div class="data-label" *ngIf="showTableHeaders">
                    <div class="label-heading tier-column">Tier</div>
                    <div class="label-heading first-name-column">First Name</div>
                    <div class="label-heading last-name-column">Last Name</div>
                    <div class="label-heading reward-id-column">Rewards ID</div>
                    <div class="label-heading phone-number-column">Phone Number</div>
                    <div class="label-heading email-column">Email</div>
                </div>
                <div class="data-screen-scroll" *ngIf="showDataTable">
                    <div class="data-rows" *ngFor="let memberList of totalMemberList">
                        <div *ngFor="let tierData of memberList.tier_data">
                            <div class="data-sub-container" *ngIf="tierData.tier_class_code_id === tierClassCodeID">
                                <div class="tier-image" *ngIf="tierData.tier_code_id==='Gold'"><img
                                        src="../../assets/image/goldtier.png" alt=""></div>
                                <div class="tier-image" *ngIf="tierData.tier_code_id==='Teal'"><img
                                        src="../../assets/image/tealtier.png" alt=""></div>
                                <div class="tier-image" *ngIf="tierData.tier_code_id==='Platinum'"><img
                                        src="../../assets/image/platinumtier.png" alt=""></div>
                                <div class="tier-image" *ngIf="tierData.tier_code_id==='Silver'"><img
                                        src="../../assets/image/silvertier.png" alt=""></div>
                                <div class="data-column tier-column tier-column-data">{{
                                    tierData.tier_code_id }}
                                </div>
                                <div class="data-column first-name-column">{{ memberList.user.first_name }}</div>
                                <div class="data-column last-name-column">{{ memberList.user.last_name }}</div>
                                <div class="data-column reward-id-column reward-id-data" (click)="memberProfileOpenState(memberList,'nonScanned')">{{ memberList.member_id }}</div>
                                <div class="data-column phone-number-column">{{ memberList.mobile!=null&&memberList.mobile!=""?memberList.mobile:"---"}}</div>
                                <div class="data-column email-column">{{ memberList.user.email!=null&&memberList.user.email!=""?memberList.user.email:"---" }}</div>
                            </div>
                        </div>
                        <div class="misc-divider"></div>
                    </div>
                </div>
                <div class="error-message-more-records" *ngIf="showMoreRecordsErrorMessage">
                    Your search has resulted in more than 50 potential member matches.<br />
                    Please refine your search.
                </div>
                <div class="error-message-no-results" *ngIf="showNoResultsErrorMessage">
                    No results found. Please check the name or Reward ID, and try again.
                </div>
                <div class="error-message-no-results" *ngIf="showLessThanDigits">
                    Member ID not found.
                </div>
            </div>
            <div class="member-profile-screen" *ngIf="showMemberDetails">
                <app-member-profile [dataFromMemberDetails]='dataToMemberProfile' [scanedDataMemberDetails]='scannedDataToMemberProfileArray'
                    (dataToMemberDetails)="memberProfileOpenState($event,'')"></app-member-profile>
            </div>
        </div>
        <div class="non-member-screen" *ngIf="showNonMemberScreen">
            <form [formGroup]="nonMemberDetailsForm" class="non-members-details-form">
                <div *ngIf="showNonMemberFields">
                    <div class="non-member-details-fields">
                        <mat-form-field appearance="fill" class="non-member-details-form-dropdown">
                            <mat-label>Member Type</mat-label>
                            <!-- <mat-select formControlName="member_type" (selectionChange)="memberTypeChange($event)">
                                <mat-option value="DL"> DL </mat-option>
                                <mat-option value="non-member"> Non-Member </mat-option>
                            </mat-select> -->
                            <mat-select formControlName="member_type" (selectionChange)="memberTypeChange($event)">
                                <mat-option *ngFor="let memberTypeValues of memberTypes" value="{{memberTypeValues.value}}">{{memberTypeValues.labelName}}</mat-option>
                            </mat-select>
                            <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="non-member-details-form-dropdown">
                            <mat-label>Traveler Type</mat-label>
                            <mat-select formControlName="traveler_type" (selectionChange)="travelerTypeChange($event)">
                                <mat-option *ngFor="let travelerTypeValues of travelerTypes" value="{{travelerTypeValues.value}}">{{travelerTypeValues.labelName}}</mat-option>
                            </mat-select>
                            <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                        </mat-form-field>

                        <div class="non-member-details-form-input-flight-number">
                            <mat-form-field appearance="fill" class="flight-type-selection">
                                <mat-select formControlName="flight_type">
                                    <mat-option *ngFor="let flightTypesValues of flightTypes" value="{{flightTypesValues.value}}">{{flightTypesValues.labelName}}</mat-option>
                                </mat-select>
                                <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                            </mat-form-field>
                            <div class="flight-number">
                                <mat-form-field appearance="fill" class="flight-number-input">
                                    <mat-label>Enter flight number</mat-label>
                                    <input matInput formControlName="flight_number" placeholder="" (keyup)="keyupEventForNonMember($event)" appNumberOnly appNospace/>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="non-member-fields">
                        <mat-form-field appearance="fill" class="non-member-details-form-input">
                            <mat-label>PNR</mat-label>
                            <input matInput formControlName="pnr_number" placeholder="" (keyup)="keyupEventForNonMember($event)" maxlength="10" appNospace/>
                        </mat-form-field>

                        <div class="extra-spacing" *ngIf="!(showVoucherNumber || showNumberOfPassengers)">
                        </div>

                        <mat-form-field appearance="fill" class="non-member-details-form-input"
                            *ngIf="showVoucherNumber">
                            <mat-label>Voucher number</mat-label>
                            <input matInput formControlName="voucher_number" placeholder="" (keyup)="keyupEventForNonMember($event)" appNospace/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="non-member-details-form-dropdown"
                            *ngIf="showNumberOfPassengers">
                            <mat-label>Number of passengers</mat-label>
                            <mat-select formControlName="number_of_passengers" (selectionChange)="numberOfPassengersChange($event)">
                                <mat-option *ngFor="let passengers of numberOfPassengers" value="{{passengers}}"> {{ passengers }} </mat-option>
                            </mat-select>
                            <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                        </mat-form-field>

                        <div class="submit-form">
                            <button mat-raised-button class="submit-button" [disabled]="disableNonMemberSubmitButton" (click)="givingLoungeAccess()"
                                [ngStyle]="{'cursor': (disableNonMemberSubmitButton? 'not-allowed':'pointer')}">AUTHORIZE ACCESS</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="showDlMemberFields">
                    <div class="non-member-details-fields">
                        <mat-form-field appearance="fill" class="dl-member-details-form-dropdown">
                            <mat-label>Member Type</mat-label>
                            <mat-select formControlName="member_type" (selectionChange)="memberTypeChange($event)">
                                <mat-option value="DL"> DL </mat-option>
                                <mat-option value="Non-Member"> Non-Member </mat-option>
                            </mat-select>
                            <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                        </mat-form-field>

                        <div class="dl-member-details-form-input-flight-number">
                            <mat-form-field appearance="fill" class="flight-type-selection">
                                <mat-select formControlName="flight_type">
                                    <mat-option value="WS"> WS </mat-option>
                                    <mat-option value="DL"> DL </mat-option>
                                    <mat-option value="KL"> KL </mat-option>
                                </mat-select>
                                <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                            </mat-form-field>
                            <div class="flight-number">
                                <mat-form-field appearance="fill" class="flight-number-input">
                                    <mat-label>Enter flight number</mat-label>
                                    <input matInput formControlName="flight_number" placeholder="" appNumberOnlyappNospace/>
                                </mat-form-field>
                            </div>
                        </div>

                        <mat-form-field appearance="fill" class="dl-member-details-form-input">
                            <mat-label>PNR</mat-label>
                            <input matInput formControlName="pnr_number" placeholder="" (keyup)="keyupEventForDLMember($event)" maxlength="10" appNospace/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="dl-member-details-form-input">
                            <mat-label>Ticket number</mat-label>
                            <input matInput formControlName="ticket_number" placeholder="" (keyup)="keyupEventForDLMember($event)" appNospace/>
                        </mat-form-field>
                    </div>
                    <div class="non-member-fields">
                        <mat-form-field appearance="fill" class="dl-member-details-form-input">
                            <mat-label>First name</mat-label>
                            <input matInput formControlName="first_name" placeholder="" (keyup)="keyupEventForDLMember($event)"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="dl-member-details-form-input">
                            <mat-label>Last name</mat-label>
                            <input matInput formControlName="last_name" placeholder="" (keyup)="keyupEventForDLMember($event)"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="dl-member-details-form-input">
                            <mat-label>Origin</mat-label>
                            <input matInput formControlName="origin" placeholder="" (keyup)="keyupEventForDLMember($event)"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="dl-member-details-form-input">
                            <mat-label>Destination</mat-label>
                            <input matInput formControlName="destination" placeholder="" (keyup)="keyupEventForDLMember($event)"/>
                        </mat-form-field>
                    </div>
                    <div class="non-member-fields">
                        <mat-form-field appearance="fill" class="dl-member-details-form-dropdown">
                            <mat-label>Operating carrier</mat-label>
                            <mat-select formControlName="operating_carrrier">
                                <mat-option value="AA">AA</mat-option>
                                <mat-option value="AF">AF</mat-option>
                                <mat-option value="DL">DL</mat-option>
                                <mat-option value="KL">KL</mat-option>
                                <mat-option value="QF">QF</mat-option>
                                <mat-option value="VS">VS</mat-option>
                                <mat-option value="WS">WS</mat-option>
                            </mat-select>
                            <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="dl-member-details-form-input">
                            <mat-label>FQTV number</mat-label>
                            <input matInput formControlName="fqtv_number" placeholder="" (keyup)="keyupEventForDLMember($event)" appNospace/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="dl-member-details-form-input">
                            <mat-label>Travel date</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="travel_date"/>
                            <mat-datepicker-toggle matSuffix [for]="picker" (click)="popupState('open')">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker>
                                <mat-datepicker-actions>
                                    <button mat-raised-button matDatepickerApply class="select-button"
                                        (click)="popupState('close')">SELECT</button>
                                    <button mat-raised-button (click)="onPickerClear(picker)"
                                        class="cancel-button">CANCEL</button>
                                </mat-datepicker-actions>
                            </mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="dl-member-details-form-dropdown">
                            <mat-label>Traveler Type</mat-label>
                            <mat-select formControlName="traveler_dl_type">
                                <mat-option value="business-class">Business Class</mat-option>
                                <mat-option value="dlmember">Member</mat-option>
                            </mat-select>
                            <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                        </mat-form-field>
                    </div>
                    <div class="non-member-fields">
                        <mat-form-field appearance="fill" class="dl-member-details-form-dropdown">
                            <mat-label>Cabin Class</mat-label>
                            <mat-select formControlName="cabin_class">
                                <mat-option value="business-class">Business Class</mat-option>
                                <mat-option value="economy">Economy</mat-option>
                                <mat-option value="premium-economy">Premium Economy</mat-option>
                            </mat-select>
                            <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="dl-member-details-form-dropdown" style="width: 13%;">
                            <mat-label>Number of guests</mat-label>
                            <mat-select formControlName="number_of_guests">
                                <mat-option value="0"> 0 </mat-option>
                                <mat-option value="1"> 1 </mat-option>
                            </mat-select>
                            <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="dl-member-details-form-input" style="width: 25%;" *ngIf="hideDlMemberFields">
                            <mat-label>Denial Message</mat-label>
                            <input matInput formControlName="denial_message" placeholder="Invalid Airport" style="color: #B2B5B6;" title="{{this.nonMemberDetailsForm.controls.denial_message.value}}" readonly/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="dl-member-details-form-dropdown-for-override" style="width: 28%;" *ngIf="hideDlMemberFields">
                            <mat-label>Override Reason</mat-label>
                            <mat-select formControlName="override_reason">
                                <mat-option value="name-is-valid"> Name is valid </mat-option>
                                <mat-option value="within-24-hour-travel"> Within 24-hour travel window </mat-option>
                                <mat-option value="valid-same-day"> Valid same-day travel </mat-option>
                                <mat-option value="skyteam-elite-or-partner-elite-member"> Skyteam Elite or Partner Elite Member </mat-option>
                                <mat-option value="at-valid-outbound-airport"> At valid outbound airport </mat-option>
                                <mat-option value="ticket-is-valid"> Ticket is Valid </mat-option>
                            </mat-select>
                            <img class="dropdown-icon" src="../../assets/image/dropdown.png" alt="logo">
                        </mat-form-field>
                    </div>
                    <div class="dl-submit-form">
                        <button mat-raised-button class="submit-button" [disabled]="disableSubmitButton" (click)="dlLocationCheck()"
                            [ngStyle]="{'cursor': (disableSubmitButton? 'not-allowed':'pointer')}">AUTHORIZE ACCESS</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <br>
    <app-footer></app-footer>
</div>
<div class="success-box" *ngIf="showSuccessPopup">
    <div class="success-container">
        <div class="success-heading">
            <div class="success-heading-banner">Access granted</div>
            <img src="../../assets/image/crossIcon.png" alt="" class="close-button" (click)="closePopup()">
        </div>
        <div class="success-description">You have successfuly granted access to the member.</div>
    </div>
</div>
<div class="error-box" *ngIf="showErrorPopup">
    <div class="error-container">
        <div class="error-heading">
            <div class="error-heading-banner">Access denied</div>
            <img src="../../assets/image/crossIcon.png" alt="" class="close-button" (click)="closePopup()">
        </div>
        <div class="error-description">{{errorMessageToBeDisplayed}}</div>
    </div>
</div>