import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WebService } from 'src/services/web.service';

@Injectable({
  providedIn: 'root'
})
export class TwofaService {

  twofaSetupScreen: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  _twofaSetupScreen = this.twofaSetupScreen.asObservable();

  constructor(
    private webService: WebService,
  ) { }

  private host = environment.host();

  updateScreen(screenNumber:any){
    this.twofaSetupScreen.next(screenNumber);
  }

  sendVerificationEmail(sysID: any){
    let requestUrl = this.host +'/systemusers/' + sysID + '/generate-email-otp/';
    return this.webService.postAPI(requestUrl, {}, false); 
  }

  verifyEmailOTP(req: any,sysID: any) {
    let requestUrl = this.host +'/systemusers/' + sysID + '/verify-email-otp/';
    return this.webService.postAPI(requestUrl, req, false); 
  }

  generateQR(sysID: any) {
    let requestUrl = this.host +'/systemusers/' + sysID + '/generate-2fa-qr/';
    return this.webService.postAPI(requestUrl, {}, false); 
  }

  verifyFinalTOTP(req: any,sysID: any) {
    let requestUrl = this.host +'/systemusers/' + sysID + '/setup-2fa/';
    return this.webService.postAPI(requestUrl, req, false); 
  }

  verifyLoginTOTP(req: any,sysID: any) {
    let requestUrl = this.host +'/systemusers/' + sysID + '/verify-totp/';
    return this.webService.postAPI(requestUrl, req, false); 
  }
}
