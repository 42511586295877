import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/services/authentication.service';
import { MemberService } from 'src/services/member.service';

@Component({
  selector: 'app-twofa-success-popup',
  templateUrl: './twofa-success-popup.component.html',
  styleUrls: ['./twofa-success-popup.component.less']
})
export class TwofaSuccessPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TwofaSuccessPopupComponent>,
    private authenticationService:AuthenticationService,
    private router: Router,
    private memberService: MemberService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  closePopup() {
    this.authenticationService.logout();
    this.dialogRef.close();
    this.router.navigate(['login']);
  }

}
