<div class="faq-container">
    <p class="faq-header">Frequently Asked Questions</p>
    <div class="faq-content">
        <p class="faq-content-header">How do I find the member's 9-digit WestJet Rewards ID?</p>
        <p class="faq-content-desc">A member’s 9-digit WestJet Rewards ID will be displayed on the top right-hand side of a printed boarding pass. The member may also have the WestJet app or a WestJet Rewards virtual card on their smartphone. You can also search for members’ WestJet Rewards ID using their first and last name. If the search results return more than one member with the same or similar name, please confirm their identity using their email address.</p>
    </div>
    <div class="faq-content">
        <p class="faq-content-header">What is an immediate family member?</p>
        <p class="faq-content-desc">Immediate family members are defined as a spouse or common-law partner (including same-sex relationships) and dependent children up to the age of 24 and living at the same address as the member. Immediate family members can be traveling on the same-day WestJet flight from the same airport as long as the member is accompanying them into the lounge.</p>
    </div>
    <div class="faq-content">
        <p class="faq-content-header">What do I do if a member presents a paper voucher, but the WestJet lounge portal indicates it has already been used?</p>
        <p class="faq-content-desc">Please let the member know that the voucher is no longer available, was redeemed or has expired. Then check to see if they have any available vouchers that can be used to provide them with complimentary access to the lounge. If no vouchers are available, please offer them the opportunity to pay the WestJet guest walk-in rate to enter the lounge.</p>
    </div>
    <div class="faq-content">
        <p class="faq-content-header">Who do I contact if I need to update my IP address?</p>
        <p class="faq-content-desc">Please email: partnerrewards@westjet.com You can also send a service request marked High priority.</p>
    </div>
    <div class="faq-content">
        <p class="faq-content-header">How do I pull a utilization report?</p>
        <p class="faq-content-desc">Please visit the Reports section of the lounge portal. Select Generate a report, your date range and preferred format.</p>
    </div>
    <div class="faq-content">
        <p class="faq-content-header">What is unlimited member access?</p>
        <p class="faq-content-desc">Members who have earned unlimited lounge access, may visit the lounge as many times as they wish to and need to.</p>
    </div>
    <div class="faq-content">
        <p class="faq-content-header">Who do I contact if I need to reset my user ID or password?</p>
        <p class="faq-content-desc">Please email: partnerrewards@westjet.com You can also send a service request marked High priority.</p>
    </div>
    <div class="faq-content">
        <p class="faq-content-header">What process should I follow if the lounge portal is down?</p>
        <p class="faq-content-desc">Please make note of the following for each guest: first and last name, WestJet Rewards ID, number of vouchers to redeem, flight number(s) and PNR(s). Once the lounge portal is accessible again, use this information to redeem the applicable vouchers in the portal. If member presents a printed voucher, retain it and use for redemption in portal later.</p>
    </div>
    <div class="faq-content">
        <p class="faq-content-header">What do I do when a member insists that they have available vouchers, but has none?</p>
        <p class="faq-content-desc">Please let the member know that WestJet’s lounge portal provides real-time availability and indicates that they don’t have any available lounge vouchers at this time. Ask them to contact WestJet if they have questions or concerns. Please offer them the opportunity to pay the WestJet guest walk-in rate to enter the lounge.</p>
    </div>
    <div class="faq-content">
        <p class="faq-content-header">What is a guest voucher?</p>
        <p class="faq-content-desc">A guest voucher is valid for any guest travelling with the member on the same-day WestJet flight from the same airport as long as the member is accompanying them into the lounge.</p>
    </div>
</div>