import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from "./../environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';
import { WebService } from './web.service';
@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private showContentLoaderSubject = new BehaviorSubject<boolean>(false);
    backClick: boolean = false;

    constructor(
        private http: HttpClient,
        private webService: WebService
    ) { }
    private host = environment.host();
    private gravty_host = environment.gravtyhost();

    scrollTop() {
        let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 5);
    }


    setBackClicked(backclick: any) {
        this.backClick = backclick;
    }
    getShowContentLoaderObs(): Observable<boolean> {
        return this.showContentLoaderSubject.asObservable();
    }

    setShowContentLoaderValue(newValue: boolean): void {
        this.showContentLoaderSubject.next(newValue);
    }
    getBackClicked() {
        return this.backClick;
    }

    getRandomString(length: any) {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for (var i = 0; i < length; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

    dataURItoBlob(dataURI: any) {
        let byteString = atob(dataURI.split(',')[1]);
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        let arrayBuffer = new ArrayBuffer(byteString.length);
        let bytesarray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            bytesarray[i] = byteString.charCodeAt(i);
        }
        let dataView = new DataView(arrayBuffer);
        return new Blob([dataView], { type: mimeString });
    }

}